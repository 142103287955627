import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';

import MenuFuncionario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer';
import api from '../../../services/api';
import {Container} from './styles';
import { useAlert } from 'react-alert';

function AlterarSenha({ dispatch, history, usuario, escola, periodo }) {
  
  const alert = useAlert();
  const [novaSenha, setNovaSenha] = useState(''); 
  const [senhaAntiga, setSenhaAntiga] = useState(''); 
  const [senhaAntigaCorreta, setSenhaAntigaCorreta] = useState(false);

  useEffect(() => {

  }, []);

  async function verificaSenha(){
    setSenhaAntigaCorreta(false);
    const result = await api.post('funcionario/login', {
      codigo: usuario.codigo,
      matric: usuario.matric,
      senha: senhaAntiga,
    });

    if (result.data)
      setSenhaAntigaCorreta(true);
    
  }

  async function gravaSenha(e){
    e.preventDefault();
    const result = await api.post('/funcionario/alterasenha', {
      codigo: usuario.codigo,
      matric: usuario.matric,
      senhanova: novaSenha,
    });


    if (result.data.success){
      alert.success('Senha alterada com sucesso!');
      // history.push('/homefuncionario');
    }else{      
      alert.error(result.data.error);
    }

    setNovaSenha('');
    setSenhaAntigaCorreta(false);
    setSenhaAntiga('');
  }

  return (
    <Container>
      <MenuFuncionario history={history} />
      <Card className="card-main">
        <Card.Header>Troca de Senha</Card.Header>
        <Card.Body>
          <Card.Text>
            Olá, <b>{usuario.nome}</b>!
            <br />
          </Card.Text>
          <form>
            <div className="form-group col-sm-5">
              <label >Informe sua <b>senha antiga</b></label>
              <input value={senhaAntiga} onChange={e => setSenhaAntiga(e.target.value.toUpperCase())} onBlur={verificaSenha} type="password" className={"form-control " + (senhaAntigaCorreta ? ' border-success' : ' border-danger')} aria-describedby="emailHelp"/>
              {senhaAntigaCorreta ? (
                <small className="form-text text-success">A senha confirmada.</small>
              ): (
                <small className="form-text text-danger">A senha não confere com a antiga.</small>
              )}
            </div>
            <div className="form-group col-sm-5">
              <label >Informe sua <b>nova senha</b></label>
              <input value={novaSenha} type="password" onChange={e => setNovaSenha(e.target.value.toUpperCase())} className="form-control"/>
              <small className="form-text text-dark">A senha deve conter no mínimo 3 caracteres.</small>
            </div>
            <div className="form-group col-sm-5">
              <button onClick={gravaSenha} type="submit" className="btn btn-dark" disabled={(!senhaAntigaCorreta || novaSenha.length < 3)}>Alterar</button>
            </div>
          </form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(AlterarSenha)