// import produce from 'immer';

const _escola = localStorage.getItem('maisescola.escola');

const INITIAL_STATE = _escola ? JSON.parse(_escola) : null;

export default function escola(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@ESCOLA/SAVE':
      localStorage.setItem('maisescola.escola', JSON.stringify(action.escola));
      return action.escola;
    default:
      return state;
  };
}
