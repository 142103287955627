import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';
import {TableMobile} from './styles';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';

function Boletim({ history, usuario, parametros, escola, periodo, sistema }) {

  const [notas, setNotas] = useState(null);
  const [avaqua, setAvaQua] = useState([]);
  const [respostas, setRespostas] = useState([]);
  const [headerNotas, setHeaderNotas] = useState(null);
  const [avisoBloqueio, setAvisoBloqueio] = useState(0);
  const [parames, setParames] = useState({});

  useEffect(() => {
   

    async function load() {      
      const consulta_bolqueio = await api.get('/consulta_bolqueio', { 
        params: { 
          codigo: escola.codigo, 
          escola: escola.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: usuario.turma
        }
      });
      console.log(consulta_bolqueio.data);
      setAvisoBloqueio(consulta_bolqueio.data.boletim);

      const parametro = await api.get('/parametros', { params: { codigo: escola.codigo, parametro: 'm_bol'}});     
      setParames(parametro.data);

      if (parametro?.data?.m_bol == 'S')
      {
        const avaqua = await api.get('/notas/avaqua/consulta', {
          params: { 
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano, 
            matric: usuario.matric,
          },
        });

        const avaquares = await api.get('/notas/modavaquares', {
          params: { 
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano, 
            turma: usuario.turma,
          },
        });

        setAvaQua(avaqua.data);
        setRespostas(avaquares.data);

        const result_boletim = await api.get('/boletim', {
          params: { 
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,
            matric: usuario.matric
          },
        });
        
        if (result_boletim.data.header)
          setHeaderNotas(result_boletim.data.header);

        if (result_boletim.data.dados)
          setNotas(result_boletim.data.dados);
      }
    }
    
    if (!localStorage.getItem('alunodebito')) 
      load();
  }, []);

  
  return (
    <>
      <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />

      <Card className="card-main">
        {(localStorage.getItem('alunodebito') && parametros.bloaludeb) ? (
          <Card.Body>
            <div className='alert alert-danger'>
              <h4>Atenção!</h4>
              <p>
              Entre em contato com a secretaria da Instituição.
              </p>
            </div>
          </Card.Body>
        ): (
          <>
          <Card.Header className="text-center h3">{headerNotas && headerNotas.titbol}</Card.Header>
          {avisoBloqueio == 0 ? (
            <Card.Body>
              <div style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                height: 300
              }}>
                <p style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: parames?.msgbloqbol?.replace(/\n/g , "<br \>")}}>            
                </p>
              </div>
            </Card.Body>
          ) : (
          <Card.Body>
            <div style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              {headerNotas && (
                <>
                  {sistema.isMobile ? (
                    <TableMobile className="table table-striped table-bordered table-mobile">
                      <thead>
                        <tr className="active">
                          <th></th>
                          {headerNotas.col1 && (
                            <th className=" text-center" >{headerNotas.col1}</th>
                          )}
                          {headerNotas.col2 && (
                            <th className="text-center" >{headerNotas.col2}</th>
                          )}
                          {headerNotas.col3 && (
                            <th className="text-center" >{headerNotas.col3}</th>
                          )}
                          {headerNotas.col4 && (
                            <th className="text-center" >{headerNotas.col4}</th>
                          )}
                          {headerNotas.col5 && (
                            <th className="text-center" >{headerNotas.col5}</th>
                          )}
                          {headerNotas.col6 && (
                            <th className="text-center" >{headerNotas.col6}</th>
                          )}
                          {headerNotas.col7 && (
                            <th className="text-center" >{headerNotas.col7}</th>
                          )}
                          {headerNotas.col8 && (
                            <th className="text-center" >{headerNotas.col8}</th>
                          )}
                          {headerNotas.col9 && (
                            <th className="text-center" >{headerNotas.col9}</th>
                          )}
                          {headerNotas.col10 && (
                            <th className="text-center" >{headerNotas.col10}</th>
                          )}
                          {headerNotas.col11 && (
                            <th className="text-center" >{headerNotas.col11}</th>
                          )}
                          {headerNotas.col12 && (
                            <th className="text-center" >{headerNotas.col12}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {notas && notas.map((nota, index) => 
                          <tr key={index}>               
                            <td className="text-left">{nota.coddis}</td>
                            {nota.col1 && (
                              <td className="text-center">{nota.col1}</td>                    
                            )}
                            {nota.col2 && (
                              <td className="text-center">{nota.col2}</td>                    
                            )}
                            {nota.col3 && (
                              <td className="text-center">{nota.col3}</td>                    
                            )}
                            {nota.col4 && (
                              <td className="text-center">{nota.col4}</td>                    
                            )}
                            {nota.col5 && (
                              <td className="text-center">{nota.col5}</td>                    
                            )}
                            {nota.col6 && (
                              <td className="text-center">{nota.col6}</td>                    
                            )}
                            {nota.col7 && (
                              <td className="text-center">{nota.col7}</td>                    
                            )}
                            {nota.col8 && (
                              <td className="text-center">{nota.col8}</td>                    
                            )}
                            {nota.col9 && (
                              <td className="text-center">{nota.col9}</td>                    
                            )}
                            {nota.col10 && (
                              <td className="text-center">{nota.col10}</td>                    
                            )}
                            {nota.col11 && (
                              <td className="text-center">{nota.col11}</td>                    
                            )}
                            {nota.col12 && (
                              <td className="text-center">{nota.col12}</td>                    
                            )}
                          </tr>
                        )}
                      </tbody>
                    </TableMobile>
                  ) : (
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr className="active">
                          <th>{headerNotas.titdis}</th>
                          {headerNotas.col1 && (
                            <th className="text-center" >{headerNotas.col1}</th>
                          )}
                          {headerNotas.col2 && (
                            <th className="text-center" >{headerNotas.col2}</th>
                          )}
                          {headerNotas.col3 && (
                            <th className="text-center" >{headerNotas.col3}</th>
                          )}
                          {headerNotas.col4 && (
                            <th className="text-center" >{headerNotas.col4}</th>
                          )}
                          {headerNotas.col5 && (
                            <th className="text-center" >{headerNotas.col5}</th>
                          )}
                          {headerNotas.col6 && (
                            <th className="text-center" >{headerNotas.col6}</th>
                          )}
                          {headerNotas.col7 && (
                            <th className="text-center" >{headerNotas.col7}</th>
                          )}
                          {headerNotas.col8 && (
                            <th className="text-center" >{headerNotas.col8}</th>
                          )}
                          {headerNotas.col9 && (
                            <th className="text-center" >{headerNotas.col9}</th>
                          )}
                          {headerNotas.col10 && (
                            <th className="text-center" >{headerNotas.col10}</th>
                          )}
                          {headerNotas.col11 && (
                            <th className="text-center" >{headerNotas.col11}</th>
                          )}
                          {headerNotas.col12 && (
                            <th className="text-center" >{headerNotas.col12}</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {notas && notas.map((nota, index) => 
                          <tr key={index}>               
                            <td className="text-left">{nota.discip}</td>
                            {nota.col1 && (
                              <td className="text-center">{nota.col1}</td>                    
                            )}
                            {nota.col2 && (
                              <td className="text-center">{nota.col2}</td>                    
                            )}
                            {nota.col3 && (
                              <td className="text-center">{nota.col3}</td>                    
                            )}
                            {nota.col4 && (
                              <td className="text-center">{nota.col4}</td>                    
                            )}
                            {nota.col5 && (
                              <td className="text-center">{nota.col5}</td>                    
                            )}
                            {nota.col6 && (
                              <td className="text-center">{nota.col6}</td>                    
                            )}
                            {nota.col7 && (
                              <td className="text-center">{nota.col7}</td>                    
                            )}
                            {nota.col8 && (
                              <td className="text-center">{nota.col8}</td>                    
                            )}
                            {nota.col9 && (
                              <td className="text-center">{nota.col9}</td>                    
                            )}
                            {nota.col10 && (
                              <td className="text-center">{nota.col10}</td>                    
                            )}
                            {nota.col11 && (
                              <td className="text-center">{nota.col11}</td>                    
                            )}
                            {nota.col12 && (
                              <td className="text-center">{nota.col12}</td>                    
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  )}
                  {!notas && (
                  <div className="text-center">
                    <br />
                      Nenhum dado encontrado.
                    <br />
                  </div>
                  )}
               
                  <br />
                  
                  <div className="text-center">
                    <b className="situacao"> {usuario.aprrep}</b><br />
                    ___________________________<br />
                    Situação
                  </div>
                </>
              )}
              {sistema.isMobile && (
                <>
                  <hr />
                  <div className="w-100">
                    {notas && notas.map((nota, index) => 
                        <div key={index} className=""><strong>{nota.coddis} </strong>{ ' - ' + nota.discip}</div>
                    )}
                  </div>
                </>
              )}

              {avaqua.length > 0 && (
                <>
                  <hr />
                  <h3>Avaliação Qualitativa</h3>
                  <div className='text-left w-100 border p-3 mb-3'>
                    {respostas.map(resp => (                  
                      <div className='text-left w-100' key={resp.res}>{resp.res} - {resp.des}</div>
                    ))}
                  </div>
                  
                  <table className="table table-bordered">
                    <thead className='thead-dark'>
                      <tr>
                        <th >Itens de Avaliação</th>
                        <th className='text-center'>B1</th>
                        <th className='text-center'>B2</th>
                        <th className='text-center'>B3</th>
                        <th className='text-center'>B4</th>
                        <th className='text-center'>B5</th>
                      </tr>
                    </thead>
                    <tbody>
                      {avaqua.map((ava, index) => (
                        <tr key={index} className={ava.cab == 'S' ? 'bg-secondary text-white' : ''}>
                          <th >{ava.item}</th>
                          <th className='text-center'>{ava.b1}</th>
                          <th className='text-center'>{ava.b2}</th>
                          <th className='text-center'>{ava.b3}</th>
                          <th className='text-center'>{ava.b4}</th>
                          <th className='text-center'>{ava.b5}</th>
                        </tr>
                      ))}
                      
                    </tbody>
                  </table>

                  <div className='text-left w-100 p-3 border'>
                    {respostas.map(resp => (                  
                      <div className='text-left w-100' key={resp.res}>{resp.res} - {resp.des}</div>
                    ))}
                  </div>
                </>
              )}

              <hr />

              <div className="text-right p-2 w-100">
                <a target="_blank" href={`https://www.aplicativomaisescola.online/relatorios/boletim.php?codigo=${escola.codigo}&escola=${periodo.escola}&ano=${periodo.ano}&seqano=${periodo.seqano}&matric=${usuario.matric}`} className="btn btn-dark">
                  Imprimir <i className="fas fa-print"></i>
                </a>
              </div>

              
            </div>
          </Card.Body>
          )}
        </>
        )}
      </Card>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(Boletim)
