import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { connect } from 'react-redux';

import FooterAdmin from '../../../componentes/FooterAdmin/index';
import formula from '../../../services/formula';

import api from '../../../services/api';
import MenuAdmin from '../../../componentes/MenuAdmin';

import {Container} from './styles';

function LoginAdmin({history, periodo, sistema}) {
  const params = useParams();

  useEffect(() => {
    if (!params.user){
      history.replace('/404');
    }
  },[])

  const [codigo, setCodigo] = useState('');
  const [escola, setEscola] = useState(null);
  const [parametros, setParametros] = useState(null);
  const [opcoes, setOpcoes] = useState(null);
  const [listaOpcoes, setListaOpcoes] = useState({
    disciplina: false,
    cod_ocorrencia: false,
    banco: false,
    funcionario: false,
    frequencia: false,
    historico: false,
    ocorrencia: false,
    turma: false,
    atv_complementar: false,
    aluno: false,
    boletim: false,
    ficha_financeira: false,
    horario: false,
    calendario: false,
    agenda: false,
    ir_quitacao: false,
  });

  const [avaliacao, setAvaliacao] = useState('B1');
  const [ano, setAno] = useState('2021');
  const [seqano, setSeqAno] = useState('1');

  const carregaEscola = async (e) => {
    const codescola = e.target.value;
    const response = await api.get('escola', {
      params: { codigo: codescola },
    });
    
    const result = await api.get('parametros', {
      params: { codigo: codescola },
    });

    const resultOpcoes = await api.get('opcoes', {
      params: { codigo: codescola },
    });

    setParametros(result.data);
    const esc = response.data.status ? null : response.data;
    setOpcoes(resultOpcoes.data);
    setEscola(esc);
  }

  const gravarOpcoes = async () => {
  }

  const processarNota = async () => {
    const lista = await api.get('/notas/colmeia', {
      params: {
        codigo: escola.codigo,
        ano,
        seqano,
        nava: avaliacao,
      }
    });

    const notas = lista.data.map(item => {
        return formula.calculaNota(item, item);
    });

    const resNotas = await api.post('/notas', {
      codigo: escola.codigo,
      notas
    });
  }

  return (
    <>
     <MenuAdmin history={history} escola={escola} periodo={periodo} sistema={sistema}/>
      <Container className="Container card m-3">
        <div  className="card-header text-center">
          Administração
        </div >  
        <div className="card-body">

          <form>
            <div className="form-row">
              <div className="col-md-3 col-sm-6">
                <input type="text" className="form-control" defaultValue={codigo} onBlur={carregaEscola} placeholder="Código da Instituição"/>
              </div>
              <div className="col">
                <input type="text" className="form-control" readOnly value={escola ? escola.nome : '...'}/>
              </div>
            </div>
          </form>
          
          <hr/>

          {escola ? (
            <>
              <div className="row">
                <div className="col-2 text-center">
                  <img style={{
                    height: 90,
                    objectFit: 'contain',
                  }} src={`https://aplicativomaisescola.com.br/logos/${escola.logo}`}/>
                </div>
                <div className="col p-3">
                  {escola.nome} | {parametros.cnpj} <br />
                  {parametros.subtitulo ? parametros.subtitulo : ''} <br />
                  {parametros.endereco ? parametros.endereco : ''} <br />
                </div>
              </div>

              <hr/>
              
              <div>
                <form>
                  <div className="form-group">
                    <div className="form-group col-md-2">
                      <label>Ano</label>
                      <select className="form-control">
                        <option>2020</option>
                        <option>2021</option>
                      </select>
                    </div>
                    <div className="form-group col-md-2">
                      <label>Período</label>
                      <select className="form-control">
                        <option>1</option>
                        <option>2</option>
                        <option>E</option>
                        <option>F</option>
                      </select>
                    </div>
                  </div>                  
                </form>
              </div>

              <hr/>
  
              <div className="accordion " id="accordionExample">
              <div className="card">
                  <div className="card-header" id="headingTwo" style={{backgroundImage: 'none',}}>
                    <h2 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Processar Notas
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                      
                      <form>                        
                        <div className="form-group">
                          <div className="form-group col-md-2">
                            <label>Avaliação</label>
                            <select className="form-control" onChange={e => setAvaliacao(e.target.value)} value={avaliacao}>
                              <option value="B1">B1</option>
                              <option value="B2">B2</option>
                              <option value="RI">RI</option>
                              <option value="B3">B3</option>
                              <option value="B4">B4</option>
                            </select>
                          </div>
                        </div>
                      </form>

                      <br/>

                      <div className="row">
                        <div className="col-12">
                          <button className="btn btn-primary" onClick={processarNota}>Processar</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              

                <div className="card" >
                  <div className="card-header " id="HeaderExportacao" style={{backgroundImage: 'none',}}>
                    <h2 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#exportacao" aria-expanded="false" aria-controls="exportacao">
                        Exportar Agora
                      </button>
                    </h2>
                  </div>

                  <div id="exportacao" className="collapse" aria-labelledby="HeaderExportacao" data-parent="#accordionExample">
                    <div className="card-body">
                      <div className="row">
                        <div className="col">
                          <ul className="list-group">
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.disciplina} onChange={event => setListaOpcoes({...listaOpcoes, disciplina: event.target.checked})}/>
                              <span>Disciplinas</span>
                            </li>                 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.cod_ocorrencia}  onChange={event => setListaOpcoes({...listaOpcoes, cod_ocorrencia: event.target.checked})}/>
                              <span>Cód. de Ocorrência</span>
                            </li>         
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.banco}  onChange={event => setListaOpcoes({...listaOpcoes, banco: event.target.checked})}/>
                              <span>Bancos</span>
                            </li>  
                            <li className="list-group-item item-exp">
                              <input type="checkbox"  defaultChecked={listaOpcoes.funcionario}  onChange={event => setListaOpcoes({...listaOpcoes, funcionario: event.target.checked})}/>
                              <span>Funcionários</span>
                            </li>               
                            <li className="list-group-item item-exp">
                              <input type="checkbox"  defaultChecked={listaOpcoes.historico}  onChange={event => setListaOpcoes({...listaOpcoes, historico: event.target.checked})}/>
                              <span>Histórico</span>
                            </li>            
                            <li className="list-group-item item-exp">
                              <input type="checkbox"  defaultChecked={listaOpcoes.ocorrencia}  onChange={event => setListaOpcoes({...listaOpcoes, ocorrencia: event.target.checked})}/>
                              <span>Ocorrências</span>
                            </li>              
                            <li className="list-group-item item-exp">
                              <input type="checkbox"  defaultChecked={listaOpcoes.turma}  onChange={event => setListaOpcoes({...listaOpcoes, turma: event.target.checked})}/>
                              <span>Turmas</span>
                            </li>         
                            <li className="list-group-item item-exp">
                              <input type="checkbox"  defaultChecked={listaOpcoes.atv_complementar}  onChange={event => setListaOpcoes({...listaOpcoes, atv_complementar: event.target.checked})}/>
                              <span>Atv. Complementares</span>
                            </li>                                                          
                          </ul>
                        </div>
                                      
                        <div className="col">
                          <ul className="list-group">
                            <li className="list-group-item item-exp">
                              <input type="checkbox"  defaultChecked={listaOpcoes.frequencia}  onChange={event => setListaOpcoes({...listaOpcoes, cod_ocorrencia: event.target.checked})}/>
                              <span>Frequência</span>
                            </li>                 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.aluno} onChange={event => setListaOpcoes({...listaOpcoes, aluno: event.target.checked})}/>
                              <span>Alunos</span>
                            </li>         
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.boletim} onChange={event => setListaOpcoes({...listaOpcoes, boletim: event.target.checked})}/>
                              <span>Boletim</span>
                            </li>  
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.ficha_financeira} onChange={event => setListaOpcoes({...listaOpcoes, ficha_financeira: event.target.checked})}/>
                              <span>Ficha Financeira</span>
                            </li>               
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.horario} onChange={event => setListaOpcoes({...listaOpcoes, horario: event.target.checked})}/>
                              <span>Horários</span>
                            </li>            
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.calendario} onChange={event => setListaOpcoes({...listaOpcoes, calendario: event.target.checked})}/>
                              <span>Calendário</span>
                            </li>              
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.agenda} onChange={event => setListaOpcoes({...listaOpcoes, agenda: event.target.checked})}/>
                              <span>Agenda / Conteúdo</span>
                            </li>         
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={listaOpcoes.ir_quitacao} onChange={event => setListaOpcoes({...listaOpcoes, ir_quitacao: event.target.checked})}/>
                              <span>IR / Dec. Quitação</span>
                            </li>                                                          
                          </ul>
                        </div>
                      </div>
                      
                      <br/>

                      <div className="row">
                        <div className="col-12 text-right">
                          <button className="btn btn-dark mr-1">Selecionar Todos</button>
                          <button className="btn btn-primary">Processar</button>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>

                <div className="card" >
                  <div className="card-header " id="HeaderConfiguracoes" style={{backgroundImage: 'none',}}>
                    <h2 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#configuracoes" aria-expanded="true" aria-controls="configuracoes">
                        Configurações de Exportação
                      </button>
                    </h2>
                  </div>

                  <div id="configuracoes" className="collapse" aria-labelledby="HeaderConfiguracoes" data-parent="#accordionExample">
                    <div className="card-body">
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </div>
                  </div>
                </div>
                
                <div className="card">
                  <div className="card-header" id="headingTwo" style={{backgroundImage: 'none',}}>
                    <h2 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Opções do Menu (Aplicativo / Área do Aluno)
                      </button>
                    </h2>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                    <div className="card-body">
                      {opcoes && (
                        <>
                          <ul className="list-group">
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_age} onChange={event => setOpcoes({...opcoes, m_age: event.target.checked ? 'S' : ''})}/>
                              <span>Agenda</span>
                            </li>                 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_atv}  onChange={event => setOpcoes({...opcoes, m_atv: event.target.checked ? 'S' : ''})}/>
                              <span>Atv. Complementar</span>
                            </li> 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_bib}  onChange={event => setOpcoes({...opcoes, m_bib: event.target.checked ? 'S' : ''})}/>
                              <span>Biblioteca</span>
                            </li>  
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_bol}  onChange={event => setOpcoes({...opcoes, m_bol: event.target.checked ? 'S' : ''})}/>
                              <span>Boletim</span>
                            </li> 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_chat}  onChange={event => setOpcoes({...opcoes, m_chat: event.target.checked ? 'S' : ''})}/>
                              <span>Chat</span>
                            </li> 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_fal}  onChange={event => setOpcoes({...opcoes, m_fal: event.target.checked ? 'S' : ''})}/>
                              <span>Faltas</span>
                            </li> 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_fin}  onChange={event => setOpcoes({...opcoes, m_fin: event.target.checked ? 'S' : ''})}/>
                              <span>Ficha Financeira</span>
                            </li> 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_fre}  onChange={event => setOpcoes({...opcoes, m_fre: event.target.checked ? 'S' : ''})}/>
                              <span>Frequência (Entrada/Saída)</span>
                            </li> 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_his}  onChange={event => setOpcoes({...opcoes, m_his: event.target.checked ? 'S' : ''})}/>
                              <span>Histórico</span>
                            </li> 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_ia}  onChange={event => setOpcoes({...opcoes, m_ia: event.target.checked ? 'S' : ''})}/>
                              <span>Instrumentos de Avaliação</span>
                            </li> 
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_oco}  onChange={event => setOpcoes({...opcoes, m_oco: event.target.checked ? 'S' : ''})}/>
                              <span>Reg. de Ocorrência</span>
                            </li>  
                            <li className="list-group-item item-exp">
                              <input type="checkbox" defaultChecked={opcoes.m_req}  onChange={event => setOpcoes({...opcoes, m_req: event.target.checked ? 'S' : ''})}/>
                              <span>Requerimento</span>
                            </li>                                                                                      
                          </ul>
                          <br/>

                          <div className="row">
                            <div className="col-12 text-right">
                              <button className="btn btn-dark mr-1">Selecionar Todos</button>
                              <button className="btn btn-primary" onClick={gravarOpcoes}>Gravar</button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
          
            </>
          ) : (
            <div className="p-3 text-center">Informe o código da instituição</div>
          )}
        </div>
      </Container>
      <FooterAdmin />
    </>
  );
}


export default connect(state => ({
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
}))(LoginAdmin)
