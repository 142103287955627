const variaveis = {
    meses: [
        { mes: 1, mes_: '01', des: 'Janeiro' },
        { mes: 2, mes_: '02', des: 'Fevereiro' },
        { mes: 3, mes_: '03', des: 'Março' },
        { mes: 4, mes_: '04', des: 'Abril' },
        { mes: 5, mes_: '05', des: 'Maio' },
        { mes: 6, mes_: '06', des: 'Junho' },
        { mes: 7, mes_: '07', des: 'Julho' },
        { mes: 8, mes_: '08', des: 'Agosto' },
        { mes: 9, mes_: '09', des: 'Setembro' },
        { mes: 10, mes_: '10', des: 'Outubro' },
        { mes: 11, mes_: '11', des: 'Novembro' },
        { mes: 12, mes_: '12', des: 'Dezembro' },
    ],
    areas: [
        {id_area: 0, descricao: 'Topo' },
        {id_area: 1, descricao: 'Sobre Empresa' },
        {id_area: 2, descricao: 'Background Serviços' },
        {id_area: 3, descricao: 'Destaque' },
        {id_area: 4, descricao: 'Mini Banner' },
    ]
}

export default variaveis;