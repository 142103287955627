import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';

import {format, startOfDay, isBefore} from 'date-fns';

import api from '../../../services/api';
import { Container, TableNota, Botoes, Formula } from './styles';

import {
  JsonToCsv,
  useJsonToCsv
} from 'react-json-csv';

import variaveis from '../../../services/variaveis';
import { useAlert } from 'react-alert';
import { Link } from 'react-router-dom';


function ControleLancamentos({ history, sistema, escola, usuario, periodo }) {

  const alert = useAlert();
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [professores, setProfessores] = useState([]);
  const [lista, setLista] = useState([]);
  const [dados, setDados] = useState({
    tipo: 'NOTA',
    mes: '',
    nava: '',
    guerra: '',
    situacao: '',
    semnotas: false,
  });

  

  const { saveAsCsv } = useJsonToCsv();

  async function loadProfessores() {
    let url = '/grade/professores/coord';
    if (usuario.tipo === 'ADMIN')
      url = '/grade/professores/admin';

    const result = await api.get(url, {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        guerra: usuario.guerra,
      },
    });
    
    setProfessores(result.data);
  }
  
  async function loadAvaliacoes(){ // avaliações

    const result = await api.get('avaliacoes/diarioweb', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
      },
    });
    setAvaliacoes(result.data);

    

    loadProfessores();

  }
  // eslint-disable-next-line
  useEffect(() => {loadAvaliacoes()}, [periodo]);

  useEffect(() => {
    if (dados.tipo === 'NOTA'){
      setDados({...dados, mes: ''});
    }else{
      setDados({...dados, nava: ''});
    }
  }, [dados.tipo]);

  async function pesquisar(e){
    e.preventDefault();
    
    if (dados.tipo === 'NOTA'){
      if (dados.nava == ''){
        alert.error('Informe o campo Avaliação');
      }else{
        let url = '/notas/transferencia/professor';
        if (usuario.tipo === 'ADMIN')
          url = '/notas/transferencia/admin';

        const result = await api.get(url, {
          params: {
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,
            guerra: dados.guerra,
            coord: usuario.guerra,
            nava: dados.nava,
            situacao: dados.situacao,
          }
        });
        setLista(result.data);

      }
    }

    if (dados.tipo === 'FREQDIA')
    {
      if (dados.mes == ''){
        alert.error('Informe o campo Mês');
      }else{
        let url = '/faltasdia/coord';
        if (usuario.tipo === 'ADMIN')
          url = '/faltasdia/admin';
        
        const result = await api.get(url, {
          params: {
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,
            prof: dados.guerra,
            mes: dados.mes,
          }
        });
        setLista(result.data);
      }
    }

    if (dados.tipo === 'FREQMES')
    {
      if (dados.mes == ''){
        alert.error('Informe o campo Mês');
      }else{
        let url = '/faltasmes/coord';
        if (usuario.tipo === 'ADMIN')
          url = '/faltasmes/admin';
        
        const result = await api.get(url, {
          params: {
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,
            prof: dados.guerra,
            mes: dados.mes,
          }
        });
        setLista(result.data);
      }
    }
  }

  function exportar (e){
    e.preventDefault();

  }
  
  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Controle de Lançamentos</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
            <div className="form-row">
              <div className="form-group col-md-3">
                <label>Tipo</label>
                <select className="form-control" name="tipo" defaultValue={dados.tipo} onChange={(e) => setDados({...dados, tipo: e.target.value })}>
                  <option value="FREQDIA">Faltas Dia</option>
                  <option value="FREQMES">Faltas Mês</option>
                  <option value="NOTA">Notas</option>
                </select>
              </div>
              <div className="form-group col-md-3">
                <label>Avaliação</label>
                <select className="form-control" disabled={dados.tipo != 'NOTA'} name="nava" value={dados.nava} onChange={(e) => setDados({...dados, nava: e.target.value })}>
                  <option value="">...</option>
                  {avaliacoes.map(item => 
                    <option key={item.id} value={item.nava}>{item.ava}</option>
                  )}                
                </select>
              </div>
              <div className="form-group col-md-3">
                <label>Mês</label>
                <select className="form-control" disabled={dados.tipo === 'NOTA'} name="mes" value={dados.mes} onChange={(e) => setDados({...dados, mes: e.target.value })}>
                  <option value="">...</option>
                  {variaveis.meses.map(item => 
                    <option key={item.mes} value={item.mes}>{item.des}</option>
                  )}                
                </select>
              </div>             
            </div>

            <div className="form-row">
              <div className="form-group col-md-8">
                <label>Professor(a)</label>
                <select className="form-control" name="mes" defaultValue={dados.guerra} onChange={(e) => setDados({...dados, guerra: e.target.value })}>
                  <option value="">...</option>
                  {professores.map(item => 
                    <option key={item.prof} value={item.prof}>{item.prof}</option>
                  )}  
                </select>
              </div>

              <div className="form-group col-md-4">
                <label>Situação</label>
                <select className="form-control" defaultValue={dados.situacao} onChange={(e) => setDados({...dados, situacao: e.target.value })}>
                  <option value="">Todas</option>                                 
                  <option value="NL">Nota Lançada</option>                                 
                  <option value="NLNTRA">Nota Lançada / Não Transferida</option>                                 
                  <option value="TRA">Transferida</option>                                 
                  <option value="TRANIMP">Transferida / Não Importada</option>                                 
                  <option value="IMP">Importada</option>                                 
                  <option value="SL">Sem Lançamento</option>                                 
                </select>
              </div> 

            </div>
            <button className="form-control btn btn-dark col-md-3" onClick={pesquisar}>Pesquisar</button>

            <hr />
            <TableNota className="table table-hover">
              <thead className="thead-dark">                
                <tr>
                  <th>Professor(a)</th>
                  <th>Turma</th>
                  <th>Disciplina</th>
                  <th>Matéria</th>
                  <th>Ult.Gravação</th>
                  {dados.tipo === 'NOTA' && (
                    <>
                      <th>Transf.</th>
                      <th>Ult.Transf.</th>
                    </>
                  )}
                  <th>Import..</th>
                  <th>Ult.Import.</th>
                </tr>
              </thead>
              <tbody>  
                {lista.length > 0 && lista.map(item => (
                  <tr key={item.id}>
                    <td>{item.prof}</td>
                    <td>{item.destur}</td>
                    <td>{item.discip} - {item.desdis}</td>
                    <td>{item.materia}</td>
                    
                    <td className="text-center">{item.data_ultimagravacao && format(new Date(item.data_ultimagravacao + ' 00:00:00'), 'dd/MM/yyyy')}</td>
                    {dados.tipo === 'NOTA' && (
                      <>
                        <td className="text-center">{item.transferido}</td>
                        <td className="text-center">{item.data_ultimatransferencia && format(new Date(item.data_ultimatransferencia + ' 00:00:00'), 'dd/MM/yyyy')}</td>
                      </>
                    )}
                    <td className="text-center">{item.importado}</td>
                    <td className="text-center">{item.data_ultimaimportacao && format(new Date(item.data_ultimaimportacao + ' 00:00:00'), 'dd/MM/yyyy')}</td>
                  </tr>
                ))}              
                {lista.length === 0 && (
                  <tr key={1}>
                    <td className="linha-sem-dados" colSpan={9}>Nenhuma informação</td>
                  </tr>
                )}
              </tbody>
            </TableNota>
            <a className="btn btn-success" 
            href={
              `https://www.aplicativomaisescola.com.br/relatorios/excel.php?codigo=${escola.codigo}&escola=${periodo.escola}&ano=${periodo.ano}&seqano=${periodo.seqano}&nava=${dados.nava}&situacao=${dados.situacao}&guerra=${dados.guerra}`
            } target="_blank">Exportar para Excel</a>            
          </form>
          <br />

        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(ControleLancamentos);