import React from 'react';
import { Provider } from 'react-redux';
import store from './store';

import { ToastContainer } from 'react-toastify';

import 'bootstrap';

import Routes from './routes.js';
import GlobalStyle from './styles/global';

import 'react-day-picker/lib/style.css';
import 'react-image-lightbox/style.css';
import "react-datepicker/dist/react-datepicker.css";

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic';
import ReactTooltip from 'react-tooltip';

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 3000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

function App() {

  return (
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
      <Routes />      
      <GlobalStyle />
      </AlertProvider>
      <ToastContainer autoClose={3000} />
      <ReactTooltip />
    </Provider>
  );
}

export default App;
