import styled from 'styled-components';

export const Container = styled.div`
  .descricao-turma {      
    margin-bottom: 5px;
    padding: 5px;

    
  }

  .icon-turma{ margin-left: 10px; }

  button {
      margin-bottom: 5px;
  }
`;
