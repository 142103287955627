import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Footer = styled.div`

    margin-top: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

`;

export const Item = styled.div`

    display: flex;
    flex: 1;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #CCC;
    margin-bottom: 3px;
    align-items: center;

    :hover {
        background-color: rgba(230,230,250, 0.9);
    }

    .agenda-nome {
        padding: 8px;
        display: flex;
        flex: 1;
    }

`;