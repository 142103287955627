import axios from "axios";
import { getToken } from "./auth";
import config from './config';

const api = axios.create({
  // baseURL: "http://192.168.100.72:8001/",
  baseURL: config.baseURL,
  auth: {
    username: 'aplicativo',
    password: 'e632553ebda3bca60620bfd0aef58b23'
  },
});

api.interceptors.request.use(function (config) {
  if (config.url != '/chat/send/alunos' || config.url != '/notificacoes/mural/send')
  {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    var loading = document.getElementById('loadingIncicator');
    loading.classList.add('loading-indicator');
    loading.style.display = 'block';
  }
  return config
}, function (error) {
  return Promise.reject(error);
});

api.interceptors.response.use(function (response) {

  // spinning hide
  // UPDATE: Add this code to hide global loading indicator
  var loading = document.getElementById('loadingIncicator');
  loading.classList.remove('loading-indicator');
  loading.style.display = 'none';


  return response;
}, function (error) {
  var loading = document.getElementById('loadingIncicator');
  loading.classList.remove('loading-indicator');
  loading.style.display = 'none';
  return Promise.reject(error);
});

export default api;
