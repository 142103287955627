import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';
import {TableMobile} from './styles';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';

function BoletimInstrumento({ history, usuario, parametros, escola, periodo, sistema }) {
  const [dados, setDados] = useState({
    codigo: usuario.codigo,
    escola: periodo.escola,
    ano: periodo.ano,
    seqano: periodo.seqano,
    matric: usuario.matric,
    turma: usuario.turma
  });
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [modavaqua, setModavaqua] = useState([]);
  const [modavaquaobs, setModavaquaobs] = useState([]);
  const [modavaquares, setModavaquares] = useState([]);
  const [notas, setNotas] = useState(null);

  useEffect(() => {
    async function load() {
      const result = await api.get('/parametros', {
        params: { codigo: escola.codigo },
      });

      const modavaqua_ = await api.get('/notas/modavaqua', {
        params: { codigo: usuario.codigo,
                  escola: periodo.escola,
                  ano: periodo.ano,
                  seqano: periodo.seqano,
                  turma: usuario.turma },
      });
      setModavaqua(modavaqua_.data);

      const modavaquares_ = await api.get('/notas/modavaquares', {
        params: { codigo: usuario.codigo,
                  escola: periodo.escola,
                  ano: periodo.ano,
                  seqano: periodo.seqano,
                  turma: usuario.turma },
      });
      setModavaquares(modavaquares_.data);

      const notas_ = await api.get('/notas/avaqua', {
        params: dados,
      });
      
      setNotas(notas_.data);
      
      const modavaquaobs_ = await api.get('/notas/avaquaobs', {
        params: { codigo: usuario.codigo,
                  escola: periodo.escola,
                  ano: periodo.ano,
                  seqano: periodo.seqano,
                  matric: usuario.matric },
      });
      setModavaquaobs(modavaquaobs_.data);
    
      
    }

    load();
  }, []);

  
  return (
    <>
      <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />

      <Card className="card-main">
        <Card.Header className="text-center h3">Avaliação Qualitativa</Card.Header>
        <Card.Body>
        <div className="alert alert-primary justify-content-start" role="alert">
            <p> <strong> LEGENDAS: </strong></p>
            <ul className="list-inline">
              {modavaquares && modavaquares.map((modavaquares1, index) =>                
                <li className="list-inline-item" key={index}>{modavaquares1.res} = {modavaquares1.des} </li>                
              )}
            </ul>
            </div>
        
          <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            
            
            
          <table className="table table-striped table-bordered">
              <thead>
                
                  <tr className="active">
                    <th className="col-sm-7">{modavaqua.titite}</th>                    
                    {modavaqua.titava1 !=='' && <th className="text-center col-sm-1" > {modavaqua.titava1} </th> }
                    {modavaqua.titava2 !=='' && <th className="text-center col-sm-1" > {modavaqua.titava2} </th> }
                    {modavaqua.titava3 !=='' && <th className="text-center col-sm-1" > {modavaqua.titava3} </th> }
                    {modavaqua.titava4 !=='' && <th className="text-center col-sm-1" > {modavaqua.titava4} </th> }
                    {modavaqua.titava5 !=='' && <th className="text-center col-sm-1" > {modavaqua.titava5} </th> }                    
                  </tr>
                  </thead>
              <tbody>
                {notas && notas.map((nota, index) => 
                  nota.cab == "S"  ?
                  <tr key= {index} > 
                    <td className="text-center table-dark col-sm-1" colSpan="6" > {nota.item} </td> 
                  </tr>
                  : 
                  <tr key= {index}>
                    <td className="text-left col-md-1">{nota.item}</td>
                    {modavaqua.titava1 !=='' && <td className="text-center col-sm-1"> {nota.b1} </td>}
                    {modavaqua.titava2 !=='' && <td className="text-center col-sm-1"> {nota.b2} </td>}
                    {modavaqua.titava3 !=='' && <td className="text-center col-sm-1"> {nota.b3} </td>}
                    {modavaqua.titava4 !=='' && <td className="text-center col-sm-1"> {nota.b4} </td>}                
                    {modavaqua.titava5 !=='' && <td className="text-center col-sm-1"> {nota.b5} </td>}
                  </tr>
                  )}
                  <tr  > 
                    <td className="text-center table-dark col-sm-1" colSpan="6" > RELATÓRIO DESCRITIVO </td>                       
                  </tr>
                  <tr>
                  {modavaquaobs.obs !=='' && <td className="text-center col-sm-1 " colSpan="6"> {modavaquaobs.obs} </td>}
                  </tr>
                
              </tbody>
            </table>                
            <div className="text-right p-2 w-100">
              <a target="_blank" href={`http://192.168.100.12:3333/qualitativa.php?codigo=${escola.codigo}&escola=${periodo.escola}&ano=${periodo.ano}&seqano=${periodo.seqano}&matric=${usuario.matric}`} className="btn btn-dark">
                Imprimir <i className="fas fa-print"></i>
              </a>
            </div>

          </div>
        </Card.Body>
      </Card>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(BoletimInstrumento)
