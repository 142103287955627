import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';
import {TableMobile} from './styles';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';

function FaltasMes({ history, usuario, parametros, escola, periodo, sistema }) {

  const [notas, setNotas] = useState([]);
  const [headerNotas, setHeaderNotas] = useState([]);

  useEffect(() => {
    async function load() {

      const result = await api.get('/parametros', {
        params: { codigo: escola.codigo },
      });

      const result_boletim = await api.get('/consulta_frequencia', {
        params: { 
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          matric: usuario.matric
        },
      });

      if (result_boletim.data.header)
        setHeaderNotas(result_boletim.data.header);

      if (result_boletim.data.dados)
        setNotas(result_boletim.data.dados);
    }

    load();
  }, []);

  
  return (
    <>
      <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />

      <Card className="card-main">
        <Card.Header className="text-center h3">{headerNotas && headerNotas.titbol}</Card.Header>
        <Card.Body>
          <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            {headerNotas && (
              <>
                {sistema.isMobile ? (
                  <TableMobile className="table table-striped table-bordered table-mobile">
                    <thead>
                      <tr className="active">
                        <th></th>
                        {headerNotas.col1 && (
                          <th className=" text-center" >{headerNotas.col1}</th>
                        )}
                        {headerNotas.col2 && (
                          <th className="text-center" >{headerNotas.col2}</th>
                        )}
                        {headerNotas.col3 && (
                          <th className="text-center" >{headerNotas.col3}</th>
                        )}
                        {headerNotas.col4 && (
                          <th className="text-center" >{headerNotas.col4}</th>
                        )}
                        {headerNotas.col5 && (
                          <th className="text-center" >{headerNotas.col5}</th>
                        )}
                        {headerNotas.col6 && (
                          <th className="text-center" >{headerNotas.col6}</th>
                        )}
                        {headerNotas.col7 && (
                          <th className="text-center" >{headerNotas.col7}</th>
                        )}
                        {headerNotas.col8 && (
                          <th className="text-center" >{headerNotas.col8}</th>
                        )}
                        {headerNotas.col9 && (
                          <th className="text-center" >{headerNotas.col9}</th>
                        )}
                        {headerNotas.col10 && (
                          <th className="text-center" >{headerNotas.col10}</th>
                        )}
                        {headerNotas.col11 && (
                          <th className="text-center" >{headerNotas.col11}</th>
                        )}
                        {headerNotas.col12 && (
                          <th className="text-center" >{headerNotas.col12}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {notas && notas.map((nota, index) => 
                        <tr key={index}>               
                          <td className="text-left">{nota.coddis}</td>
                          {nota.col1 && (
                            <td className="text-center">{nota.col1}</td>                    
                          )}
                          {nota.col2 && (
                            <td className="text-center">{nota.col2}</td>                    
                          )}
                          {nota.col3 && (
                            <td className="text-center">{nota.col3}</td>                    
                          )}
                          {nota.col4 && (
                            <td className="text-center">{nota.col4}</td>                    
                          )}
                          {nota.col5 && (
                            <td className="text-center">{nota.col5}</td>                    
                          )}
                          {nota.col6 && (
                            <td className="text-center">{nota.col6}</td>                    
                          )}
                          {nota.col7 && (
                            <td className="text-center">{nota.col7}</td>                    
                          )}
                          {nota.col8 && (
                            <td className="text-center">{nota.col8}</td>                    
                          )}
                          {nota.col9 && (
                            <td className="text-center">{nota.col9}</td>                    
                          )}
                          {nota.col10 && (
                            <td className="text-center">{nota.col10}</td>                    
                          )}
                          {nota.col11 && (
                            <td className="text-center">{nota.col11}</td>                    
                          )}
                          {nota.col12 && (
                            <td className="text-center">{nota.col12}</td>                    
                          )}
                        </tr>
                      )}
                    </tbody>
                  </TableMobile>
                ) : (
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr className="active">
                        <th>{headerNotas.titdis}</th>
                        <th className="text-center" >{'JAN'}</th>
                        <th className="text-center" >{'FEV'}</th>
                        <th className="text-center" >{'MAR'}</th>
                        <th className="text-center" >{'ABR'}</th>
                        <th className="text-center" >{'MAI'}</th>
                        <th className="text-center" >{'JUN'}</th>
                        <th className="text-center" >{'JUL'}</th>
                        <th className="text-center" >{'AGO'}</th>
                        <th className="text-center" >{'SET'}</th>
                        <th className="text-center" >{'OUT'}</th>
                        <th className="text-center" >{'NOV'}</th>
                        <th className="text-center" >{'DEZ'}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notas.map((nota, index) => 
                        <tr key={index}>               
                          <td className="text-left">{nota.discip}</td>
                          <td className="text-center">{nota.falta1}</td>                    
                          <td className="text-center">{nota.falta2}</td>                    
                          <td className="text-center">{nota.falta3}</td>                    
                          <td className="text-center">{nota.falta4}</td>                    
                          <td className="text-center">{nota.falta5}</td>                    
                          <td className="text-center">{nota.falta6}</td>                    
                          <td className="text-center">{nota.falta7}</td>                    
                          <td className="text-center">{nota.falta8}</td>                    
                          <td className="text-center">{nota.falta9}</td>                    
                          <td className="text-center">{nota.falta10}</td>                    
                          <td className="text-center">{nota.falta11}</td>                    
                          <td className="text-center">{nota.falta12}</td>                    
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
                {!notas && (
                <div className="text-center">
                  <br />
                    Nenhum dado encontrado.
                  <br />
                </div>
                )}
              </>
            )}
            <br />
            <div className="text-right p-2 w-100">
              <a target="_blank" href={`https://www.aplicativomaisescola.com.br/relatorios/boletim.php?codigo=${escola.codigo}&escola=${periodo.escola}&ano=${periodo.ano}&seqano=${periodo.seqano}&matric=${usuario.matric}`} className="btn btn-dark">
                Imprimir <i className="fas fa-print"></i>
              </a>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(FaltasMes)
