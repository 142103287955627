import styled from 'styled-components';

export const Container = styled.div`
  .descricao-turma {      
    margin-bottom: 5px;
    padding: 5px;

    
  }

  .icon-turma{ margin-left: 10px; }

  .card-body {
    padding: 4px!important;
  }
`;

export const TableNota = styled.table`
  th {
    padding: 4px!important;    
  }

  .column-ord {
    width: 38px;
    vertical-align: middle;
    text-align: center;
  }

  .column-matric {
    width: 70px;
    vertical-align: middle;
    text-align: center;
  }
  
  .column-nome {
    vertical-align: middle;
  }
  
  .column-ia, .column-nota, .column-faltas{
    width: 45px;
    text-align: center;
    vertical-align: middle;
  }

  .input-ia-header{
    width: 45px!important;
    text-align: center;
    font-size: 10px;
    height: 25px;
  }

  .input-ia{
    width: 45px!important;
    text-align: center;
  }

  .column-ia-mob, .column-nota-mob, .column-faltas-mob{
    width: 40px;
    text-align: center;
    vertical-align: middle;
    border: none;
  }

  .input-ia-header-mob{
    width: 40px!important;
    text-align: center;
    font-size: 8px;
    height: 25px;
  }

  .input-ia-mob{
    width: 40px!important;
    text-align: center;
  }

  tbody tr:focus-within {
    background: lightskyblue !important;
    color: #000;
  }

  .col {
    padding: 2px!important;
  }

  .row{
    margin-right: 0px!important;
    margin-left: 0px!important;
  }

  .onfocus {
    height: 56px;
  }

  .notafinal {
    color: blue;
    font-weight: bold;
  }
`;

export const Botoes = styled.div`
    margin-top: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    .alert-info {
      margin-right: 10px;
      margin-bottom: 0px;
      display: flex;
      flex: 1;
    }

`;

export const Formula = styled.div`
  padding: 6px;
`;

