import styled from 'styled-components';

export const Container = styled.div`

    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 100%;

    .card-periodo{
        margin-left: auto;
        margin-right: auto;
        width: 60%!important;
        @media screen and (max-width: 480px){
            width: 96%!important;
        }
    }  

    .input-periodos {
        width: 50%!important;

        @media screen and (max-width: 480px){
            width: 96%!important;
        }
    }
`;
