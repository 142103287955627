import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MenuFuncionario from '../../../componentes/MenuFuncinario';

import api from '../../../services/api';
import { Container, Footer, Item, Cabecalho } from './styles';
import { useAlert } from 'react-alert';
import apiNotificacao from '../../../services/apiNotificacao';

function AgendaCadastroAlunoLancamento({ history, escola, usuario, periodo, location}) {
    
    const alert = useAlert();
    
    // eslint-disable-next-line
    const [lista, setLista] = useState([]);
    // eslint-disable-next-line
    const [dataFormatada, setDataFormatada] = useState(format(location.state.dataLancamento, 'yyyy-MM-dd'));
    const [aluno, ] = useState(location.state.aluno);
    
    useEffect(() => {
        async function load(){
            setLista([]);
            const resp = await api.get('/agenda/detalhe', {
                params: {
                    codigo: usuario.codigo,
                    escola: periodo.escola,
                    ano: periodo.ano,
                    matric: aluno.matric,
                    seqano: periodo.seqano,
                    data: format(location.state.dataLancamento, 'dd/MM/yyyy')
                }
            });

            if (resp.data.length > 0)
                setLista(resp.data);
            else{
                const resp = await api.get('/agenda/modelo', {
                    params: {
                        codigo: usuario.codigo,
                        escola: periodo.escola,
                        ano: periodo.ano,
                        matric: aluno.matric,
                        seqano: periodo.seqano,
                        codmod: location.state.turma.codmod,
                        data: format(location.state.dataLancamento, 'dd/MM/yyyy')
                    }
                });
                setLista(resp.data);
            }            

        }
        load();
    }, [periodo, usuario, dataFormatada, aluno, location.state]);


    function handleChange(e){

        const result = lista.map(item => {

            if (e.target.name === item.id)
                item.resposta = e.target.value;

            return item;
        });

        setLista(result);
    }

    function handleCheckChange(e){

        const result = lista.map(item => {

            if (e.target.name === item.id)
            {
                item.checked = e.target.checked;
                item.resposta = e.target.checked ? 'X' : '';
            }
            return item;
        });

        setLista(result);
    }

    async function handleSave() {
        try {
            const dados = {
                lista,
                codigo: usuario.codigo,
                escola: escola.escola,
                matric: aluno.matric,
                data: format(location.state.dataLancamento, 'yyyy-MM-dd')
            }

            const res = await api.post('/agendaweb/save', dados);
            
            const tokens = [];
            res.data.forEach(i => {
              tokens.push(i.token);
            })
      
            apiNotificacao.post('send/conteudo', {        
                list: tokens,
                title: 'Atenção',
                body: 'Novo conteúdo cadastrado na agenda.' 
            });

            alert.success('Dados gravados com sucesso!');
            
            history.push('/agendacadastroaluno', { turma: location.state.turma, dataLancamento: location.state.dataLancamento});

        }catch(error) {
            alert.error(error);
        }
    }
   
    return (
        <Container>
            <MenuFuncionario history={history} />
            <Card className="card-main">
                <Card.Body>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page">
                                <Link to="/agenda">Agenda</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Cadastro por Aluno
                            </li>
                        </ol>
                    </nav>
                    <form >
                        <div className="form-group">
                            <label >Aluno: </label>
                            <label className="form-control">{aluno.nome}</label>
                        </div>
                        <div className="form-group">
                            <label >Data: </label>
                            <label className="form-control">{format(location.state.dataLancamento, 'dd/MM/yyyy')}</label>
                        </div>                        
                        <hr/>
                        {lista.map((item, index) => (
                            <Item key={index}>
                                {item.tipo === 'CABEÇALHO' && (
                                    <Cabecalho>{item.item}</Cabecalho>
                                )}                                
                                
                                {item.tipo === 'TEXTO' && (
                                    <div className="form-group">
                                        <label>{item.item}</label>
                                        <textarea className="form-control" rows="4" name={item.id} defaultValue={item.resposta} onChange={handleChange}></textarea >
                                    </div>
                                )}
                                
                                {item.tipo === 'MARCAÇÃO' && (
                                    <div className="form-group form-group-switch">
                                        <label htmlFor={item.id} className="switch ">
                                            <input id={item.id} type="checkbox" defaultChecked={item.resposta} className="primary" name={item.id} onChange={handleCheckChange}/>
                                            <span className="slider round"></span>
                                        </label>
                                        <label className="switch-label">{item.item}</label>
                                    </div>
                                )}
                                
                                {item.tipo === 'NOTA' && (
                                    <div className="form-group">
                                        <label>{item.item}</label>
                                        <div className="range-div">
                                            <input type="range" min="1" max="5" name={item.id} onChange={handleChange} defaultValue={item.resposta} className="range-slider"></input>
                                            <label className="range-nota">
                                                {item.resposta === '1' && 'NÃO ATENDE'}
                                                {item.resposta === '2' && 'IINSUFICIENTE'}
                                                {item.resposta === '3' && 'REGULAR'}
                                                {item.resposta === '4' && 'BOM'}
                                                {item.resposta === '5' && 'EXCELENTE'}
                                            </label>
                                        </div>
                                    </div>
                                )}

                                {item.tipo === 'OPÇÃO' && (
                                    <div className="form-group">                                        
                                        <label>{item.item}</label>
                                        <select className="form-control form-control-lg" onChange={handleChange} name={item.id} defaultValue={item.resposta}>
                                            <option value="S">SIM</option>
                                            <option value="N">NÃO</option>
                                            <option value="A">ÀS VEZES</option>
                                        </select>
                                    </div>
                                )}
                            </Item>
                        ))}

                        <Footer>                    
                            <button type="button" className="btn btn-success" onClick={() => handleSave()}>Gravar</button>
                            <button type="button" className="btn btn-dark" onClick={()=> 
                                history.push('/agendacadastroaluno', 
                                    { turma: location.state.turma, dataLancamento: location.state.dataLancamento}
                                    )
                                }>Voltar</button>
                        </Footer>
                    </form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default connect(state => ({
    sistema: state.sistema,
    escola: state.escola,
    usuario: state.usuario,
    periodo: state.periodo,
}))(AgendaCadastroAlunoLancamento);