import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';
import config from '../../../services/config';
import imgAulas from '../../../assets/proximasaulas.png';

import { addDays, subDays, format } from 'date-fns';

function HomeAluno({ dispatch, history, usuario, sistema, escola, periodo }) {

  const [parametros, setParametros] = useState([]);
  const [data1, setData1] = useState(new Date());
  const [data1Aulas, setData1Aulas] = useState([]);
  const [data2, setData2] = useState(addDays(data1, 1));
  const [data2Aulas, setData2Aulas] = useState([]);
  
  useEffect(() => {
    async function load(){
      const result = await api.get('/parametros', {
        params: { codigo: escola.codigo },
      });
      localStorage.setItem('maisescola.parametros', JSON.stringify(result.data));
      dispatch({ type: '@PARAMETROS/SAVE', parametros: result.data}); 

      const result_aulas = await api.get('/aulas/dia', {
        params: { 
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          data: format(data1, 'yyyy-MM-dd'),
          turma: usuario.turma,
        },
      });

      const result_aulas2 = await api.get('/aulas/dia', {
        params: { 
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          data: format(data2, 'yyyy-MM-dd'),
          turma: usuario.turma,
        },
      });

      const result_ficha = await api.get('/fichafinanceira', {
        params: { 
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          matric: usuario.matric
        },
      });

      if (result.data.bloaludeb)
      {
        const financeiro = result_ficha.data.filter(i => !i.datpag);
        const financeiro_deb = financeiro.filter(i => (
          new Date(i.datven.substring(6), i.datven.substring(3, 5), i.datven.substring(0, 2)) < (new Date())
        ))      
        if (financeiro_deb.length > 0)
          localStorage.setItem('alunodebito', financeiro_deb.length);
        else
          localStorage.removeItem('alunodebito');
      }else{
        localStorage.removeItem('alunodebito');
      }
      setData1Aulas(result_aulas.data);
      setData2Aulas(result_aulas2.data);
      setParametros(result.data);
    }
    load();
  }, []);

  return (
    <>
      {parametros && (
        <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />
      )}
      <Card className="card-main">
        <Card.Header>Home</Card.Header>
        <Card.Body>
          <Card.Text>
            Bem vindo, <b>{usuario.nome}</b>!<br />
          </Card.Text>
          {parametros.aulas && (
            <div className="row">
              {!config.isMobile ? (
                <div className="col-md-3">
                  <img src={imgAulas} className="img-fluid"/>
                </div>
              ):(
              <div className="col-md-12" style={{
                  flexDirection: 'column',
                  display: 'flex',
                  textAlign: 'center',
                  alignItems: 'center'
              }}>
                <i className="fa fa-child fa-5x" aria-hidden="true"></i>
                <b style={{fontSize: 34}}>Próximas <br />Aulas</b>
              </div>
              )}
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item disabled" aria-disabled="true">
                    <i className="fa fa-calendar fa-1x mr-2"></i>{format(data1, 'dd/M/yyyy')}
                  </li>
                  {data1Aulas && data1Aulas.map((item, index) => (
                    <li key={index} className="list-group-item">
                      <i className="fa fa-clock fa-1x mr-2"></i>{item.hora_inicio} - {item.desdis}
                    </li>
                  ))}
                  
                </ul>              
              </div>
              <div className="col">
                <ul className="list-group">
                  <li className="list-group-item disabled" aria-disabled="true">
                    <i className="fa fa-calendar fa-1x mr-2"></i>{format(data2, 'dd/M/yyyy')}
                  </li>
                  {data2Aulas && data2Aulas.map((item, index) => (
                    <li key={index} className="list-group-item">
                      <i className="fa fa-clock fa-1x mr-2"></i>{item.hora_inicio} - {item.desdis}
                    </li>
                  ))}
                </ul>              
              </div>
            </div>
          )}
          <br/>
          <div className="row">
            {parametros.m_bol == 'S' && (  
            <div className="col-md-6">
              <a href="/boletim" className="btn btn-lg btn-dark btn-block p-5">
                <i className="fas fa-list-alt mr-2"></i>
                Boletim
              </a>
            </div>   
            )}
            {parametros.m_fin == 'S' && (     
              (usuario.matric == '2000028' && usuario.codigo == '20035') ? (
                <span></span>
              ) : (
                <div className={'col-md-6 ' + (sistema.isMobile && 'mt-4')}>
                  <a href="/fichafinanceira" className="btn btn-lg btn-dark btn-block p-5">
                    <i className="fas fa-hand-holding-usd mr-2"></i>
                    2ª Via de Boletos
                  </a>
                </div>
              )
            )}
          </div>
        </Card.Body>
      </Card>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros
}))(HomeAluno)
