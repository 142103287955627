class formula {
    calculaNota(item, turma){
        if (item.ia1 === null && item.ia2 === null && item.ia3 === null && item.ia4 === null && item.ia5 === null && item.ia6 === null)
        {
          item.nota = '';
        }else{
            let nota = null;
            const notamax = Number(turma.notamax);
            
            let ia1 = 0;
            let ia2 = 0;
            let ia3 = 0;
            let ia4 = 0;
            let ia5 = 0;
            let ia6 = 0;

            if (item.ia1)
                ia1 = item.ia1.length > 0 ? Number(item.ia1) : 0;

            if (item.ia2)
                ia2 = item.ia2.length > 0 ? Number(item.ia2) : 0;

            if (item.ia3)
                ia3 = item.ia3.length > 0 ? Number(item.ia3) : 0;

            if (item.ia4)
                ia4 = item.ia4.length > 0 ? Number(item.ia4) : 0;

            if (item.ia5)
                ia5 = item.ia5.length > 0 ? Number(item.ia5) : 0;

            if (item.ia6)
                ia6 = item.ia6.length > 0 ? Number(item.ia6) : 0;

            let qtdnotas = 0;
            qtdnotas += item.ia1 ? (item.ia1.length > 0 ? 1 : 0) : 0;
            qtdnotas += item.ia2 ? (item.ia2.length > 0 ? 1 : 0) : 0;
            qtdnotas += item.ia3 ? (item.ia3.length > 0 ? 1 : 0) : 0;
            qtdnotas += item.ia4 ? (item.ia4.length > 0 ? 1 : 0) : 0;
            qtdnotas += item.ia5 ? (item.ia5.length > 0 ? 1 : 0) : 0;
            qtdnotas += item.ia6 ? (item.ia6.length > 0 ? 1 : 0) : 0;

            let soma = ia1 + ia2 + ia3 + ia4 + ia5 + ia6;

            if (turma.formula === '(IA1+IA2+IA3+IA4+IA5)/2=NB,NB+REC/2')
            {
                let soma = (ia1 + ia2 + ia3 + ia4 + ia5) / 2;
                nota = soma ;                                

                if (ia6 > 0)
                {
                    const soma2 = soma + ia6;
                    nota = soma2 / 2;
                }
            }
            
            if (turma.formula === 'SOMA/2')
            {
                let soma = (ia1 + ia2 + ia3 + ia4 + ia5) / 2;
                nota = soma ;                                

                if (ia6 > 0)
                {
                    const soma2 = soma + ia6;
                    nota = soma2 / 2;
                }
            }

            if (turma.formula === 'SOMA')
            {
                nota = soma ;
            }

            if (turma.formula === 'MEDIA')
            {
                nota = soma / qtdnotas ;
            }
            
            if (turma.formula === 'SOMANPI')
            {
                let recup = 0;
                let media = ia1 + ia2 + ia3 + ia4 + ia5;
                nota = media;
                if ((media < 7) && (ia6 > 0))
                {
                    recup = (media + ia6) / 2;
                    if (recup > 7)
                        recup = 7;
                    else if (recup < media)
                        recup = media;
                }   

                if (recup > 0)
                    nota = recup;
            }

            if (turma.formula === '(IA1+IA2+IA3)/2')
            {
                let recup = 0;
                let media = (ia1 + ia2 + ia3) / 2;
                nota = media;
                if ((media < 7) && (ia6 > 0))
                {
                    recup = (media + ia6) / 2;
                    if (recup > 7)
                        recup = 7;
                    else if (recup < media)
                        recup = media;
                }   

                if (recup > 0)
                    nota = recup;
            }

            if (turma.formula === '(IA1+IA2+IA3+IA4)/3')
            {
                let recup = 0;
                let media = (ia1 + ia2 + ia3 + ia4) / 3;
                nota = media;
                if ((media < 7) && (ia6 > 0))
                {
                    recup = (media + ia6) / 2;
                    if (recup > 7)
                        recup = 7;
                    else if (recup < media)
                        recup = media;
                }   

                if (recup > 0)
                    nota = recup;
            }

            if (turma.formula === '(IA1+IA2+IA3+IA4)/2')
            {
                let recup = 0;
                let media = (ia1 + ia2 + ia3 + ia4) / 2;                
                nota = media;

                if ((media < 7) && (ia6 > 0))
                {                    
                    recup = (media + ia6) / 2;
                    if (recup > 7)
                        recup = 7;
                    else if (recup < media)
                        recup = media;
                }   

                if (recup > 0)
                    nota = recup;

            }

            if (turma.formula === '(IA1+IA2+IA3+IA4+IA5)/3')
            {
                let recup = 0;
                let media = (ia1 + ia2 + ia3 + ia4 + ia5) / 3;
                nota = media;
                if ((media < 7) && (ia6 > 0))
                {
                    recup = (media + ia6) / 2;
                    if (recup > 7)
                        recup = 7;
                    else if (recup < media)
                        recup = media;
                }   

                if (recup > 0)
                    nota = recup;
            }

            if (turma.formula === '(IA1+IA2+IA3)/3')
            {
                let recup = 0;
                let media = (ia1 + ia2 + ia3) / 3;
                nota = media;
                if ((media < 7) && (ia6 > 0))
                {
                    recup = (media + ia6) / 2;
                    if (recup > 7)
                        recup = 7;
                    else if (recup < media)
                        recup = media;
                }   

                if (recup > 0)
                    nota = recup;
            }

            if (turma.formula === '(IA1+IA2)/2')
            {
                let recup = 0;
                let media = (ia1 + ia2) / 2;
                nota = media;
                if ((media < 7) && (ia6 > 0))
                {
                    recup = (media + ia6) / 2;
                    if (recup > 7)
                        recup = 7;
                    else if (recup < media)
                        recup = media;
                }   

                if (recup > 0)
                    nota = recup;
            }

            if (turma.formula === 'NOTA+RECUP')
            {
                let recup = 0;
                let media = (ia1 + ia2 + ia3 + ia4 + ia5)/2;
                
                nota = media;

                if ((media < 7) && (ia6 > 0))
                {
                    recup = (media + ia6) / 2;
                    if (recup > 7)
                        recup = 7;
                    else if (recup < media)
                        recup = media;
                }   

                if (recup > 0)
                    nota = recup;
                
            }

            if (turma.formula === '(SOMA+RECUP)/2')
            {
                let recup = 0;
                let media = ia1 + ia2 + ia3 + ia4 + ia5;
                
                nota = media;

                if ((media < 7) && (ia6 > 0))
                {
                    recup = (media + ia6) / 2;
                    if (recup > 7)
                        recup = 7;
                    else if (recup < media)
                        recup = media;
                }   

                if (recup > 0)
                    nota = recup;
                
            }

            if (turma.formula === '((IA1+IA2+IA3)/2)+IA4')
            {
                nota = ((ia1 + ia2 + ia3) / 2) + ia4;
            }

            if (turma.formula === '(IA1+IA2)/2+IA3')
            {
                nota = ((ia1 + ia2) / 2) + ia3;
            }

            if (turma.formula === 'SOMA+RECUP')
            {
                let media = ia1 + ia2 + ia3 + ia4 + ia5;
                nota = media;
                if (ia6 > 0)
                {
                    if (ia6 > media)
                        nota = ia6;                    
                }
            }

            if (nota > notamax)
                nota = notamax;
            
            if (qtdnotas > 0)
                item.nota = nota ? nota.toFixed(1) : '';
            else
                item.nota = '';

        }

        return item;
    }
}

export default new formula();