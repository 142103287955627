import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Modal, Button, Card } from 'react-bootstrap';

import MenuFuncionario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer';
import imgConstrucao from '../../../assets/em_construcao.png';
import api from '../../../services/api';

function HomeFuncionario({ dispatch, history, usuario, escola, periodo }) {

  const [show, setShow] = useState(false); // alterar para true
  const [parametros, setParametros] = useState(null); // alterar para true
  const [mensagem, setMensagem] = useState(null); // alterar para true
  const [turmas, setTurmas] = useState(null); // alterar para true

  function closeModal() {
    setShow(false);
  }

  if (usuario?.setor == 'REQUERIMENTO')
        window.location.replace('requerimentos');

  useEffect(() => {
    async function load(){

      const result = await api.get('/parametros', {
        params: { codigo: escola.codigo },
      });
      const params =result.data;
      localStorage.setItem('maisescola.parametros', JSON.stringify(result.data));
      setParametros(result.data);
      dispatch({ type: '@PARAMETROS/SAVE', parametros: result.data });  

      if (params.msgdw)
      {  
        setShow(true);
        setMensagem(params.msgdw);
      }

      // carregando as turmas
      let url = '/grade/turmas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/turmas/diario/admin';

      if (usuario.tipo === 'COORD')
        url = '/grade/turmas/diario/coord';

      const dados = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });
      setTurmas(dados.data);

      
    }

    load();
  }, []);

  return (
    <>
      {parametros && (
        <MenuFuncionario history={history} />
      )}
      <Card className="card-main">
        <Card.Header>Home</Card.Header>
        <Card.Body>
          <Card.Text>
            Bem vindo, <b>{usuario.nome}</b>!
                <br />
          </Card.Text>
          <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <table className="table">
              <thead>
              <tr>
                <th>Turma</th>
                <th className="text-center">QTD Alunos</th>
              </tr>
              </thead>
              <tbody>
                {turmas ? (
                  turmas.map(item => 
                    <tr key={item.turma}>
                        <td className="text-left">{item.turma} - {item.destur}</td>
                        <td className="text-center">{item.qtd}</td>
                    </tr>
                  )
                ): (
                  <tr>
                    <td className="text-center" colSpan={2}>Nenhum registro encontrado.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Card.Body>
      </Card>

      <Modal show={show} dialogClassName="modal-aviso">
        <Modal.Header >
          <Modal.Title>Atenção</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {mensagem && (
          <p style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{
            __html: mensagem.replace(/\n/g , "<br \>")
          }}></p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Fechar</Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(HomeFuncionario)