import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';

import api from '../../../services/api';
import { Container, TableNota, Botoes, } from './styles';

import { useAlert } from 'react-alert'

function AvaQualitativa({ history, escola, usuario, periodo }) {
  
  const alert = useAlert();

  const [modavaqua, setModAvaQua] = useState(null);
  const [modavaquares, setModAvaQuaRes] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState(null);
  const [turmaDetalhe, setTurmaDetalhe] = useState([]);
  const [alunos, setAlunos] = useState([]);
  const [notas, setNotas] = useState([]);
  const [dados, setDados] = useState({
    turma: '',
    matric: '',
  });
  const [obs, setObs] = useState(null);

  // carrega turmas
  useEffect(() => { 
    async function load(){
      let url = '/grade/turmas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/turmas/diario/admin';

      if (usuario.tipo === 'COORD')
        url = '/grade/turmas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });      
      const tur = result.data.filter(item => item.modava.length > 0);
      setTurmas(tur);
    }

    load();
  }, [usuario, escola, periodo])

  // carrega lista de alunos
  useEffect(() => {

    async function load() {
      // SELECIONA os detalhes da ava/turma/discip (diario_grade)
      const result = await api.get('/turma/detalhe', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
        },
      });

      setTurmaDetalhe(result.data);

      // SELECIONA os alunos (diario_notas)
      const resultNotas = await api.get('/aluno/lista', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
        },
      });

      setAlunos(resultNotas.data);      
    }

    if (dados.turma.length)
      load();
    else
    {
      setAlunos([]);
      setTurmaDetalhe([]);
    }

  }, [dados]);

  async function changeTurma(e) {   
    const turma_ = e.target.value; 
    const reg = turmas.find(t => t.turma === e.target.value)
    setTurma(reg);
    setDados({...dados, turma: e.target.value });

    const modavaqua_ = await api.get('/notas/modavaquares', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        turma: turma_,
      },
    });
    setModAvaQuaRes(modavaqua_.data);
  }

  function handleChangeNota(e){
    const numite = e.target.name;
    const ia = e.target.id;
    let value = e.target.value;    

    const result = notas.map(item => {
      if (numite === item.numite)
      {

        switch (ia) {
          case 'b1':
            item.b1 = value;
            break;

          case 'b2':
            item.b2 = value;
            break;

          case 'b3':
            item.b3 = value;
            break;

          case 'b4':
            item.b4 = value;
            break;

          case 'b5':
            item.b5 = value;
            break;

          default:
            break;
        }  
      }   
      
      return item;
    });
    
    setNotas(result);
  }

  async function handleSave(){
    try
    {      
      let dados_ = dados;
      dados_.notas = notas;
      dados_.codigo= escola.codigo;
      dados_.escola= periodo.escola;
      dados_.ano= periodo.ano;
      dados_.seqano= periodo.seqano;
      dados_.modava = turma.modava;
      dados_.obs= obs.obs;

      const resultdetalhe = await api.post('/notas/avaqua', {
        codigo: escola.codigo,
        dados: dados_,
        obs: obs.obs
      });
      

      const notas_ = await api.get('/notas/avaqua', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          matric: dados.matric,
          turma: turma.turma,
          modava: turma.modava,
        },
      });
      setNotas(notas_.data);

      alert.success('Dados gravados com sucesso!');
    }catch(error){
      alert.error(error);
    }
  }

  async function carregaAvaliacao(e) {
    const matric = e.target.value;
    setDados({...dados, matric: e.target.value});

    const modavaqua_ = await api.get('/notas/modavaqua', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        turma: dados.turma,
      },
    });
    setModAvaQua(modavaqua_.data);

    const obs_ = await api.get('/notas/avaquaobs', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        matric: matric,
      },
    });
    setObs(obs_.data);

    const notas_ = await api.get('/notas/avaqua', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        matric: matric,
        turma: turma.turma,
      },
    });
    setNotas(notas_.data);  
  }

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Avaliação Qualitativa</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
            <div className="form-row">
              <div className="form-group col-md-8">
                <label>Turma</label>
                <select className="form-control" name="turma" defaultValue={dados.turma} onChange={changeTurma} >
                  <option value="">...</option>
                  {turmas.map(item => 
                    <option key={item.turma} value={item.turma}>{item.turma} - {item.destur}</option>
                  )}                
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-9">
                <label>Aluno(a)</label>
                <select className="form-control" value={dados.matric} name="matric" onChange={carregaAvaliacao}>
                  <option value="">...</option>
                  {alunos.map(item => 
                    <option key={item.matric} value={item.matric}>{item.matric} - {item.nome}</option>
                  )}                
                </select>
              </div>                    
            </div>
            {modavaquares.length > 0 && (
              <>
                <hr />
                <div className="alert alert-primary" role="alert">
                  <b>LEGENDAS: </b><br />
                  {modavaquares.map((res, index) => 
                    index < 1 ? (res.res + ' - ' + res.des) : (' | ' + res.res + ' - ' + res.des)
                  )}
                </div>
              </>
            )}
            <hr />
           
            <TableNota className="table">
              {modavaqua && ( 
                <thead className="thead-dark">                  
                  <tr>
                    <th className="column-nome header-sort">
                      {modavaqua.titite}
                    </th>
                    {modavaqua.titava1 !=="" && (
                      <th className="column-aq">{modavaqua.titava1}</th>
                    )}
                    {modavaqua.titava2 !=="" && (
                      <th className="column-aq">{modavaqua.titava2}</th>
                    )}
                    {modavaqua.titava3 !=="" && (
                      <th className="column-aq">{modavaqua.titava3}</th>
                    )}
                    {modavaqua.titava4 !=="" && (
                      <th className="column-aq">{modavaqua.titava4}</th>
                    )}
                    {modavaqua.titava5 !=="" && (
                      <th className="column-aq">{modavaqua.titava5}</th>
                    )}
                  </tr>                  
                </thead>
              )}
              {notas && (
                <tbody>
                  {notas.map((nota, index) => (
                    nota.cab == 'S' ? (
                      <tr key={index} style={{backgroundColor: 'rgba(220,220,220, 0.5)'}}>
                        <td style={{paddingLeft: 10}}><b>{nota.item}</b></td>
                        {modavaqua.titava1 !=="" && (
                          <td className="column-aq">-</td>
                        )}
                        {modavaqua.titava2 !=="" && (
                          <td className="column-aq">-</td>
                        )}
                        {modavaqua.titava3 !=="" && (
                          <td className="column-aq">-</td>
                        )}
                        {modavaqua.titava4 !=="" && (
                          <td className="column-aq">-</td>
                        )}
                        {modavaqua.titava5 !=="" && (
                          <td className="column-aq">-</td>
                        )}
                      </tr>  
                    ) : (
                      <tr key={index} >
                        <td>{nota.item}</td>
                        {modavaqua.titava1 !=="" && (
                          <td className="column-aq">
                            <select value={nota.b1} onChange={handleChangeNota} id={'b1'} name={nota.numite}>
                              <option key={'_1'} value="">...</option>
                              {modavaquares.map((res, index) => (
                                <option key={index} value={res.res}>{res.res}</option>
                              ))}
                            </select>
                          </td>
                        )}
                        {modavaqua.titava2 !=="" && (
                          <td className="column-aq">
                             <select value={nota.b2} onChange={handleChangeNota} id={'b2'} name={nota.numite}>
                              <option key={'_1'} value="">...</option>
                              {modavaquares.map((res, index) => (
                                <option key={index} value={res.res}>{res.res}</option>
                              ))}
                            </select>
                          </td>
                        )}
                        {modavaqua.titava3 !=="" && (
                          <td className="column-aq">
                             <select value={nota.b3} onChange={handleChangeNota} id={'b3'} name={nota.numite}>
                              <option key={'_1'} value="">...</option>
                              {modavaquares.map((res, index) => (
                                <option key={index} value={res.res}>{res.res}</option>
                              ))}
                            </select>
                          </td>
                        )}
                        {modavaqua.titava4 !=="" && (
                          <td className="column-aq">
                             <select  value={nota.b4} onChange={handleChangeNota} id={'b4'} name={nota.numite}>
                              <option key={'_1'} value="">...</option>
                              {modavaquares.map((res, index) => (
                                <option key={index} value={res.res}>{res.res}</option>
                              ))}
                            </select>
                          </td>
                        )}
                        {modavaqua.titava5 !=="" && (
                          <td className="column-aq">
                            <select value={nota.b5} onChange={handleChangeNota} id={'b5'} name={nota.numite}>
                              <option key={'_1'} value="">...</option>
                              {modavaquares.map((res, index) => (
                                <option key={index} value={res.res}>{res.res}</option>
                              ))}
                            </select>
                          </td>
                        )}
                      </tr>  
                    )   
                  ))}
                
                  {notas.length === 0 && (
                    <tr key={1}>
                      <td className="linha-sem-dados" colSpan={11}>Nenhuma informação</td>
                    </tr>
                  )}
                </tbody>

              )}
            </TableNota>
            
            {notas.length > 0 && (
              <>
                <hr />

                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label style={{fontWeight: 'bold'}}>RELATÓRIO DESCRITIVO</label>
                    <textarea rows="6" className="form-control" name="obs" value={obs ? obs.obs : ''} onChange={(e) => setObs({...obs, obs: e.target.value})} >
                    </textarea>
                  </div>
                </div>
              </>
            )}
          </form>
          
          <hr />

          <Botoes>    
            {(notas.length > 0) && (
              <button type="button" className="btn btn-success" onClick={() => handleSave()}>Gravar</button>            
            )}
          </Botoes>
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(AvaQualitativa);