import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import $ from 'jquery';
import './style.css';
import { useAlert } from 'react-alert';
import {GrCheckboxSelected, GrCheckbox} from 'react-icons/gr';
import apiNotificacao from '../../../services/apiNotificacao';

function Mensagem({history}) {
  const [show, setShow] = useState(false); // alterar para true
  const [parametros, setParametros] = useState(null); // alterar para true
  const [file, setFile] = useState(null); 
  const [mensagem, setMensagem] = useState(''); // alterar para true
  const [turmaSel, setTurmaSel] = useState(''); // alterar para true
  const [funcionarioSel, setFuncionarioSel] = useState(null); // alterar para true
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [todosMarcados, setTodosMarcados] = useState(false);

  const [turmas, setTurmas] = useState([]); // alterar para true
  const [funcionarios, setFuncionarios] = useState([]); // alterar para true
  const [alunos, setAlunos] = useState([]); // alterar para true
  const {codigo, escola, ano, seqano} = useParams();
  const alert = useAlert();

  function closeModal() {
    setShow(false);
  }

  document.title = 'Envio de Mensagem';

  useEffect(() => {

    async function load(){
      const result = await api.get('/parametros', {
        params: { codigo: codigo },
      });
      setParametros(result.data);

      const alunos_ = await api.get('/aluno/lista/todos', {
        params: { codigo, escola, ano, seqano },
      });
      setAlunos(alunos_.data);

      const funcionarios_ = await api.get('/funcionario/lista', {
        params: { codigo },
      });
      setFuncionarios(funcionarios_.data);

      let url = '/grade/turmas/geral';

      const turmas_ = await api.get(url, {
        params: {
          codigo: codigo,
          escola: escola,
          ano: ano,
          seqano: seqano,
        },
      });
      const turmas = turmas_.data.map(t => {
        t.sel = '';
        return t;
      });
      console.log(turmas);
      setTurmas(turmas);
    }

    load();
  }, []);

  const handleSel = (e) => {
    e.preventDefault();
    const codtur = e.target.id;
    setTurmaSel(codtur);

    if (e.target.value == 'P')
    {
      const result = turmas.map(item => {
        if (item.turma == codtur)
          item.sel = e.target.value;
  
        return item;
      })
      setTurmas(result);
      $('#modalAlunos').modal('show');
    }
    else if (e.target.value == 'T'){
      const result = alunos.map(item => {
        if (item.turma == codtur)
          item.sel = e.target.value == 'T' ? 'S' : '';
  
        return item;
      })
  
      setAlunos(result);

      const turmas_ = turmas.map(item => {
        if (item.turma == codtur)
          item.sel = e.target.value;
  
        return item;
      })
      setTurmas(turmas_);
    }else{
      const result = alunos.map(item => {
        if (item.turma == codtur)
          item.sel = '';
  
        return item;
      })
  
      setAlunos(result);

      const turmas_ = turmas.map(item => {
        if (item.turma == codtur)
          item.sel = '';
  
        return item;
      })
      setTurmas(turmas_);
    }
  }

  function handleChangeCheck(e){
    const result = alunos.map(item => {
      if (item.matric === e.target.id)
        item.sel = e.target.checked ? 'S' : 'N';

      return item;
    })

    setAlunos(result);
  }

  const fileChange = async (e) => {
    if (e.target.files)
      setFile(e.target.files[0]);
    else
      setFile(null);

  }
  
  const funcionarioChange = async (e) => {
    console.log(e.target.value);
    setFuncionarioSel(funcionarios.find(f => f.matric == e.target.value));
  }

  const submit = async(e) => {
    e.preventDefault();
    if (alunos.filter(a => a.sel == 'S').length < 1)
    {
      alert.error('Nenhum aluno/turma selecionado');
      return false;
    }

    if (!funcionarioSel)
    {
      alert.error('Selecione um funcionário');
      return false;
    }
        
    if (file)
      if (Math.round((file.size / 1024) / 1024, 2) > 20) {
        alert.error('O tamanho do arquivo não pode ser maior que 20mb.');
        return false;
      }

    let fileUploaded = {
      status: 'success',
    };

    if (file)
    {
      let postdata = new FormData();
      postdata.append('file', file);
      postdata.append('codigo', codigo); 
      const resp = await api.post('/file/upload2', postdata);       
      fileUploaded = resp.data;
    }

    if (fileUploaded.status === 'success')
    {          
      const postdata = {
        codigo: codigo,
        from: 'funcionario',
        alunos: alunos.filter(a => a.sel == 'S'),
        nome: funcionarioSel.nome,
        funcionario: funcionarioSel.matric,
        mensagem: mensagem,
        file_uri: fileUploaded.uri || '',
      };
      // console.log(postdata);
      const res = await api.post('/chatusuario/send/alunos', postdata);

      const tokens = [];
      res.data.forEach(i => {
        tokens.push(i.token);
      })

      // console.log('tokens', tokens)

      const resNotify = await apiNotificacao.post('send/toaluno', {
        from: funcionarioSel.matric,
        title: funcionarioSel.nome,
        body: mensagem,
        list: tokens
      });
    }

    alert.success('Mensagem enviada com sucesso!', { timeout: 0});
    setMensagem('');
    setTodosMarcados(false);
    setFuncionarioSel(null);
    setTurmaSel('');
    setFile(null);
  }

  useEffect(() => {
    const result = turmas.map(item => {
        item.sel = todosMarcados ? 'T' : '';
      return item;
    })

    const alunos_ = alunos.map(item => {
        item.sel = todosMarcados ? 'S' : '';
      return item;
    })

    setTurmas(result);
    setAlunos(alunos_);
  }, [todosMarcados])
  
  return (
    <div className="card" style={{borderRadius: 0,}}>
      <div className="card-body" style={{padding: 0,}}>
        <div style={{maxHeight: 230, overflowY: 'scroll', borderWidth: 1, border: 'solid'}}>
          <table className="table table-mensagem">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Turma</th>
                <th scope="col">Descrição</th>
                <th style={{width: 65, textAlign: 'center'}}>Qtd Alunos</th>
                <th style={{width: 100, textAlign: 'center'}}>Selecione</th>
                <th style={{width: 65, textAlign: 'center'}}>Qtd Sel.</th>
              </tr>
            </thead>
            <tbody>
              
              {turmas.filter(i => i.qtd > 0).map(turma => (
                <tr key={turma.turma}>
                  <td>{turma.turma}</td>
                  <td >{turma.destur}</td>
                  <td style={{textAlign: 'center'}}>{turma.qtd}</td>
                  <td style={{textAlign: 'center'}}>
                    <select onChange={handleSel} id={turma.turma} value={turma.sel}>
                      <option value=""></option>
                      <option value="T">Todos</option>
                      <option value="P">Parcial</option>
                    </select>
                  </td>
                  <td style={{textAlign: 'center', fontWeight: 'bold', fontSize: 16}}>
                    {alunos.filter(a=> a.turma == turma.turma && a.sel == 'S' ).length}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'end', paddingTop: 3}}>
          <div style={{width: 72, textAlign: 'center'}}>
          {!todosMarcados ? <div>Marcar Todos</div> : <div>Desmarcar Todos</div> }
          {!todosMarcados ? 
            (<GrCheckbox size={14} onClick={() => setTodosMarcados(!todosMarcados)}/>)
          : 
            (<GrCheckboxSelected size={14} onClick={() => setTodosMarcados(!todosMarcados)}/>)
          }
          </div>
          <div style={{padding: '0.4rem 0.5rem', width: 100, textAlign: 'center'}}>{alunos.filter(a=> a.sel == 'S' ).length}</div>
        </div>

        <form className='form-mensagem' onSubmit={submit}>

          <div className="form-row">
            {/* <div className="form-group col-8">
              <label >Título</label>
              <input type="text" className="form-control form-control-sm" />
            </div> */}

            <div className="form-group col-4">
              <label >Funcionário</label>
              <select onChange={funcionarioChange} className="form-control form-control-sm" value={funcionarioSel ? funcionarioSel.matric : ''}>
                <option value=""></option>
                {funcionarios.map((f, index) => (
                  <option key={index} value={f.matric}>{f.guerra}</option>
                ))}
              </select>
            </div>

            <div className="form-group col-6">
              <label >PDF / Imagem</label>
              <div className="custom-file">
                <input type="file" className="custom-file-input" 
                  onChange={fileChange}
                  accept="image/*,.pdf"/>
                <label className="custom-file-label custom-file-label-sm" htmlFor="customFileLangHTML" data-browse="Selecionar">{file?.name}</label>
              </div>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-12">
              <label htmlFor="validationTextarea">Mensagem</label>
              <textarea className="form-control form-control-sm norezise" value={mensagem} rows={5} onChange={e => setMensagem(e.target.value)}></textarea>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-12" style={{display: 'flex', justifyContent: 'space-between'}}>
              <button type="submit" className="btn btn-success btn-sm">
                Enviar
              </button>
              <button className="btn btn-danger btn-sm" onClick={() => history.replace(`/mensagem/${codigo}/${escola}/${ano}/${seqano}`)}>
                Voltar
              </button>
            </div>
          </div>
        </form>

        {/* MODAL DE ALUNOS */}
        <div className="modal fade" id="modalAlunos" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{maxWidth: '100vw'}}>
            <div className="modal-content" style={{width: '98vw', height: '98vh'}}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Lista de Alunos</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Matrícula</th>
                      <th scope="col">Nome</th>
                      <th style={{width: 80, textAlign: 'center'}}>Selecione</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alunos.filter(i => i.turma == turmaSel).map(alu => (
                      <tr key={alu.matric}>
                        <th>{alu.matric}</th>
                        <td>{alu.nome}</td>
                        <td style={{width: 80, textAlign: 'center'}}>
                          <input className="form-check-input" type="checkbox" id={alu.matric} checked={alu.sel == 'S' ? true : false} onChange={handleChangeCheck}/>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(Mensagem)