import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container, Row, Col, Form, Carousel, Button, Collapse,
} from 'react-bootstrap';

import { connect } from 'react-redux';
import api from '../../services/api';
import { Section } from './styles';
import { useAlert } from 'react-alert';

import Page404 from '../Page404';
import axios from 'axios';
import AdSense from 'react-adsense';
import config from '../../services/config';

function Login({
  dispatch, history, sistema,
}) {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [matricula, setMatricula] = useState('');
  const [senha, setSenha] = useState('');
  const [escola, setEscola] = useState(null);
  const [parametros, setParametros] = useState({});
  const { id } = useParams();

  function verificaCookies(){
    if (!localStorage.getItem("MaisEscolaCookies")) {
      document.querySelector(".box-cookies").classList.remove('hide');
    }
    
    const btnCookies = document.querySelector(".btn-cookies");
    
    btnCookies.addEventListener('click', acceptCookies);
  }

  async function acceptCookies() {
    if (window.location.hostname !== 'localhost')
    {
      const resp = await axios.get('https://api.ipify.org?format=json');
      const response = await api.post('/gravaip', { codigo: id, ip: resp.data.ip, matric:'' });
    }else{
      const ip = '192.168.0.1';
      const response = await api.post('/gravaip', { codigo: id, ip, matric:'' });
    }

    document.querySelector(".box-cookies").classList.add('hide');
    localStorage.setItem("MaisEscolaCookies", "accept");
  };
  

  useEffect(() => {
    localStorage.removeItem('maisescola.usuario');
    async function load() {

      const response = await api.get('escola', {
        params: { codigo: id },
      });
      console.log(response.data);

      const result = await api.get('/parametros', {
        params: { codigo: id },
      });

      setParametros(result.data);
      localStorage.setItem('maisescola.escola', JSON.stringify(response.data));
      setEscola(response.data);
      dispatch({ type: '@ESCOLA/SAVE', escola: response.data });
    }
    if (id)
    {
        load();
        verificaCookies();
    }
    else
      history.push('/demo');

  }, [dispatch, id]);

  function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  async function logarAluno(evento) {
    evento.preventDefault();
    setLoading(true);
    if (!localStorage.getItem("MaisEscolaCookies")) {
      setLoading(false);
      alert.error('Você só terá acesso após aceitar nossas políticas de privacidade.');
    }else{
      let tipo_usuario = 'funcionario';
      let response;

      const eMatricula = isNumeric(matricula);

      // if (matricula.substr(0, 2) === '85') {
      if (eMatricula)
      {
        if (matricula.substring(0, 2) == '85')
        {
          response = await api.post('funcionario/login', {
            codigo: escola.codigo,
            matric: matricula,
            senha,
          });
          // alert.info('Sistema em manutenção');
          // setLoading(false);
          // return false;
        }else {
          tipo_usuario = 'aluno';
          response = await api.post('aluno/login', {
            codigo: escola.codigo,
            matric: matricula,
            senha,
          });
        }
      }
      else {
        response = await api.post('funcionario/login/guerra', {
          codigo: escola.codigo,
          matric: matricula,
          senha,
        });

        // alert.info('Sistema em manutenção');
        // setLoading(false);
        // return false;
      }

      if (response.data) {
      {
        dispatch({
          type: '@USUARIO/AUTENTICACAO_SUCESSO',
          usuario: { ...response.data, tipo_usuario },
        });

        await verificaAvaliacaoPendente(response.data);
        
      }
      }else{
        alert.info('Dados incorretos');
        setLoading(false);
      }
    }
  }
    
  async function verificaAvaliacaoPendente(usuario){

    if (escola.cnpj == 'immes')
    {      
      const url = usuario.matric.substring(0, 2) == '85' ? (usuario.setor == 'PROFESSOR' ? '/avaliacao/lista/professor' : '/avaliacao/lista/funcionario') :  '/avaliacao/lista/aluno';
      
      const avaliacao = await api.get(url, { params: {codigo: escola.codigo, matric: usuario.matric,}});
      console.log(avaliacao);

      if (avaliacao.data.length > 0){
        window.location.replace(`https://${escola.cnpj}.netlify.app/${escola.codigo}/${usuario.matric}`);        
      }else{
        history.push('/periodos');
      }
    }else{      
      history.push('/periodos');
    } 
  }


  if (id)
  {

    return (
      <>
        <Section>
          <Container>
            <Row>
              <Col className="login-sec">
                <h2 className="text-center">
                  {sistema.nome}
                </h2>

                <Form className="login-form" onSubmit={logarAluno}>
                  <Form.Group>
                    <Form.Label>Instituição</Form.Label>
                    <Form.Control type="text" name="ctrl-escola" defaultValue={escola ? escola.nome : '' } disabled />                    
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Login</Form.Label>
                    <Form.Control type="text" name="matric" defaultValue={matricula} onChange={(e) => setMatricula(e.target.value)} />                    
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Senha</Form.Label>
                    <Form.Control type="password" name="senha" defaultValue={senha} onChange={(e) => setSenha(e.target.value.toUpperCase())} />
                  </Form.Group>


                  <Form.Group>
                    <Button type="submit" className="button-login" variant="success" disabled={loading}>
                      Entrar 
                      {loading && (
                      <div className="spinner-border spinner-border-sm" style={{marginLeft: 5}}  role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                      )}
                    </Button>
                  </Form.Group>
                </Form>
                
                <div className="copy-text">
                  Versão {sistema.versao}.
                </div>
              </Col>
              <Collapse in={!sistema.isMobile}>
                <Col className="banner-sec">

                  <Carousel onSelect={0} controls={false} slide={false} indicators={false} className="carousel-caption d-none d-md-block">
                    <Carousel.Item>
                      <div className="banner-text">
                        <h3>MaisEscola</h3>
                        <p>
                          O Sistema permite que o(a) professor(a) efetue o lançamento de notas, frequência, conteúdo programático, etc.
                          E Permite ao(a) aluno(a) consultar várias informações acadêmicas como boletim, conteúdo,
                          calendário, faltas, ocorrências, requerimentos e 2ª via de boletos.

                        </p>
                      </div>
                    </Carousel.Item>
                  </Carousel>

                </Col>
              </Collapse>
            </Row>
            
          </Container>
         
          <div className="box-cookies hide">
            <p className="text-white">
            Sobre o uso de cookie neste site: usamos cookies e outras tecnologias semelhantes para melhorar a sua experiência em nossos serviços.
            Ao continuar navegando, você concorda com a nossa <a href="https://maisescola.app/privacidade.html" target="_blank">Política de Privacidade</a>.
            </p>
            <button className="btn btn-success btn-cookies">Aceitar</button>
        </div>
        </Section>
        
      </>
    );
  }else{
    return <Page404 history={history} escola={escola}/>
  }
}

export default connect((state) => ({
  sistema: state.sistema,
  escola: state.escola,
}))(Login);
