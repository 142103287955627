import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore} from 'date-fns';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';
import api from '../../../services/api';
import { Container, TableNota, Botoes } from './styles';

import { useAlert } from 'react-alert';
import {FaTrashAlt, FaCloudDownloadAlt} from 'react-icons/fa';


function Arquivos({ history, escola, usuario, periodo, sistema }) {

  const alert = useAlert();
  const date = startOfDay(new Date());
  const [file, setFile] = useState(null);
  const [dataIni, setDataIni] = useState(date);
  const [dataPickIni, setDataPickIni] = useState(date);
  const [dataFin, setDataFin] = useState(date);
  const [dataPickFin, setDataPickFin] = useState(date);
  const [erros, setErros] = useState(null);

  const [lista, setLista] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState(null);
  const [turmaDetalhe, setTurmaDetalhe] = useState(null);
  const [disciplinas, setDisciplinas] = useState([]);  
  const [disciplina, setDisciplina] = useState(null);  
  const [materias, setMaterias] = useState([]); 
  const [materia, setMateria] = useState(null); 
  const [dados, setDados] = useState({
    turma: '',
    disciplina: '',
    materia: '',
    dataini: format(dataIni, 'yyyy-MM-dd'),
    datafin: format(dataFin, 'yyyy-MM-dd'),
    tipo: '',
    file: null,
    titulo: '',
    detalhes: '',
  });

  // eslint-disable-next-line
  const [dataPick, setDataPick] = useState(startOfDay(date));
  
  const CustomDatePciker = React.forwardRef((props, ref) => (
      <button type="button" className="form-control" onClick={props.onClick} >
          {props.value}
      </button>
  ));  


  // carrega turmas
  useEffect(() => { 
    async function load(){
      let url = '/grade/turmas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/turmas/diario/admin';

      if (usuario.tipo === 'COORD')
        url = '/grade/turmas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });      
      setTurmas(result.data);

      const lista_ = await api.get('/file/lista', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          dataini: dados.dataini,
          datafin: dados.datafin,
          tipo: dados.tipo,
        },
      });
      setLista(lista_.data);
    }

    load();
  }, [usuario, escola, periodo])

  // carrega disciplinas
  useEffect(() => {     
    async function load(){
      let url = '/grade/disciplinas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/disciplinas/diario/todas';

      if (usuario.tipo === 'COORD')
        url = '/grade/disciplinas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: turma.turma,
        },
      }); 

      setDisciplinas(result.data);

      
    }

    if (turma)
      load();

  }, [usuario, escola, periodo, turma])

  // carrega lista
  async function pesquisar(e) {
    e.preventDefault();
    if (dados.dataini && dados.datafin)
    {
      const lista_ = await api.get('/file/lista', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          dataini: dados.dataini,
          datafin: dados.datafin,
          tipo: dados.tipo,
        },
      });
      setLista(lista_.data);

    }
    else
    {
      alert.error('Informe os campos obrigatórios.');
      setLista([]);
    }
  };


  function inputChange(e){
    if (e.target.name === 'nava')
      setDados({...dados, nava: e.target.value});

    if (e.target.name === 'turma'){
      const reg = turmas.find(t => t.turma === e.target.value)
      setTurma(reg);
      setDados({...dados, turma: e.target.value, disciplina: ''});
      setDisciplina(null);
    }

    if (e.target.name === 'disciplina')
      setDados({...dados, disciplina: e.target.value});
    
    if (e.target.name === 'aulas')
      setDados({...dados, aulas: e.target.value});

    if (e.target.name === 'tipo')
      setDados({...dados, tipo: e.target.value});
    
  }

  function inputChangeDataFin(e){
    setDataPickFin(e);
    setDataFin(e);
    setDados({...dados, datafin: format(e, 'yyyy-MM-dd')});

  }

  function inputChangeDataIni(e){
    setDataPickIni(e);
    setDataIni(e);
    setDados({...dados, dataini: format(e, 'yyyy-MM-dd')});

  }

  async function deletar(){
    const delete_ = await api.post('/file/delete', {      
        codigo: escola.codigo,
        file: file.url,
        id: file.id
    });

    if (dados.dataini && dados.datafin)
    {
      const lista_ = await api.get('/file/lista', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          dataini: dados.dataini,
          datafin: dados.datafin,
          tipo: dados.tipo,
        },
      });
      setLista(lista_.data);
    }
    else
    {
      alert.error('Informe os campos obrigatórios.');
      setLista([]);
    }
  }
  
  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body className="p-1">
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Arquivos / Links - Consulta</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
            <div className="form-row">
              <div className="form-group col-md-12 text-center">
                <h3>Filtros</h3>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-9">
                <label>Turma</label>
                <select className="form-control" name="turma" defaultValue={dados.turma} onChange={inputChange} >
                  <option value="">...</option>
                  {turmas.map(item => 
                    <option key={item.turma} value={item.turma}>{item.turma} - {item.destur}</option>
                  )}                
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-9 ">
                <label>Disciplina</label>
                <select className="form-control" value={dados.disciplina} name="disciplina" onChange={inputChange}>
                  <option value="">...</option>
                  {disciplinas.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              {materias.length > 0 && (
              <div className="form-group col-md-3">
                <label>Matéria</label>
                <select className="form-control" value={dados.materia} name="materia" onChange={inputChange}>
                  <option value="">...</option>
                  {materias.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              )}              
            </div>  

            <div className="form-row">
                <div className="form-group col-md-3">
                  <label className="required">Data Inicial</label>
                  <DatePicker
                    name="data"
                    dateFormat="dd/MM/yyyy"
                    selected={dataIni}
                    onChange={inputChangeDataIni}
                    customInput={<CustomDatePciker />}
                    locale={pt} />
                </div>    

                <div className="form-group col-md-3">
                  <label className="required">Data Final</label>
                  <DatePicker
                    name="data"
                    dateFormat="dd/MM/yyyy"
                    selected={dataFin}
                    onChange={inputChangeDataFin}
                    customInput={<CustomDatePciker />}
                    locale={pt} />
                </div>  

                <div className="form-group col-md-3">
                    <label>Tipo</label>
                    <select className="form-control" value={dados.tipo} name="tipo" onChange={inputChange}>
                        <option value="">Todos</option>
                        <option value="LK">Links</option>
                        <option value="OU">Arquivos</option>
                    </select>
                </div>  
                  
            </div>     
            <div className="form-row">                   
                <div className="form-group col-md-3">
                  <button className="btn btn-dark col-md-12" onClick={pesquisar}>Pesquisar</button>            
                </div>
                <div className="form-group col-md-6">                  
                </div>
                <div className="form-group col-md-3">
                    <a href="/arquivoscadastro" className="btn btn-success col-md-12">Novo</a>            
                </div>
            </div>           
            
            <hr />
            {sistema.isMobile ? (
              <TableNota className="table"  style={{fontSize: 9,}}>
                <thead className="thead-dark">             
                  <tr>
                    <th className="column-turma">Turma / Disciplina</th>
                    <th className="column-file">Arquivo</th>
                    <th className="column-data text-center">Data<br />Hora</th>
                    <th className="column-delete"></th>
                  </tr>
                </thead>
                <tbody>         
                    {lista ? (
                      lista.map((item, index) => (
                        <tr key={index}>
                          <td >
                            {item.turma} - {item.destur}<br />
                            {item.discip} - {item.desdis}
                          </td> 
                          <td><a href={item.url} target="_blank">{item.titulo}</a></td>
                          <td className="text-center">
                            {format(new Date(item.data + 'T00:00:00'), 'dd/MM/yyyy')}<br />
                            {item.hora}
                          </td>
                          <td><FaTrashAlt onClick={() => setFile(item)} data-toggle="modal" data-target="#myModal" className="btn-delete"/></td>
                        </tr>  
                      ))                    
                    ) : (
                      <tr key={1}>
                        <td className="linha-sem-dados" colSpan={4}>Nenhuma informação</td>
                      </tr>
                    )}                            
                </tbody>
              </TableNota>
            ) : (
              <TableNota className="table">
                <thead className="thead-dark">             
                  <tr>
                    <th className="column-turma">Turma</th>
                    <th className="column-discip">Disciplina</th>
                    <th className="column-file">Titulo</th>
                    <th className="column-data">Data</th>
                    <th className="column-hora">Hora</th>
                    <th className="column-delete"></th>
                  </tr>
                </thead>
                <tbody>         
                    {lista ? (
                      lista.map((item, index) => (
                        <tr key={index}>
                          <td>{item.turma} - {item.destur}</td>
                          <td>{item.discip} - {item.desdis}</td> 
                          <td><a href={item.url} target="_blank">{item.titulo}</a></td>
                          <td>{format(new Date(item.data + 'T00:00:00'), 'dd/MM/yyyy')}</td>
                          <td>{item.hora}</td>
                          <td>
                            <FaTrashAlt onClick={() => setFile(item)} data-toggle="modal" data-target="#myModal" className="btn-delete"/>
                            <a href={item.url} target="_blank"><FaCloudDownloadAlt size={18} onClick={() => setFile(item)} className="ml-1 btn-delete"/></a>
                          </td>
                        </tr>  
                      ))                    
                    ) : (
                      <tr key={1}>
                        <td className="linha-sem-dados" colSpan={4}>Nenhuma informação</td>
                      </tr>
                    )}                            
                </tbody>
              </TableNota>
            )}
          </form>
                    
        </Card.Body>
      </Card>

      <div className="modal fade" id="myModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Atenção</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Deseja deletar o registro ?</p>
              <p><strong>{file && file.titulo}</strong></p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={deletar}>Sim</button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(Arquivos);