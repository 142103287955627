import styled from 'styled-components';

export const Container = styled.div`
  .descricao-turma {      
    margin-bottom: 5px;
    padding: 5px;

    
  }

  .react-datepicker-wrapper {
    display: block!important;
  }
  

  .icon-turma{ margin-left: 10px; }

  
`;

export const TableNota = styled.table`
  th {
    padding: 4px!important;    
  }

  .column-ord {
    width: 38px;
    vertical-align: middle;
    text-align: center;
  }

  .column-matric {
    width: 70px;
    vertical-align: middle;
    text-align: center;
  }
  
  .column-nome {
    vertical-align: middle;
  }
  
  .column-ia, .column-nota, .column-faltas{
    width: 45px;
    text-align: center;
    vertical-align: middle;
  }

  .input-ia-header{
    width: 45px!important;
    text-align: center;
    font-size: 10px;
    height: 25px;
  }

  .input-ia{
    width: 45px!important;
    text-align: center;
  }
`;

export const Botoes = styled.div`
    margin-top: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    .alert-info {
      margin-right: 10px;
      margin-bottom: 0px;
      display: flex;
      flex: 1;
    }

`;
