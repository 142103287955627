import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card, Modal, Button } from 'react-bootstrap';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';
import Pagination from '../../../componentes/Pagination';
import * as $ from 'jquery';
import config from '../../../services/config';
import { Link } from 'react-router-dom';

function ConsultaAcervo({ history, usuario, parametros, escola, periodo }) {

  // PAGINAÇÃO
  const [listaPagina, setListaPagina] = useState([]);
  const [perPage, setPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [update, setUpdate] = useState(false);
  
  function paginate(event, selectedPage) {
    event.preventDefault();
    setCurrentPage(selectedPage);
  };
  const [lista, setLista] = useState([]);
  const [listaFiltro, setListaFiltro] = useState([]);
  // PAGINAÇÃO
  const [obra, setObra] = useState(null);
  const [exemplares, setExemplares] = useState([]);
  const [reservas, setReservas] = useState([]);

  const [filtro, setFiltro] = useState({
    tipoobra: '',
    campo: '',
    assunto: '',
  });

  async function pesquisar(e) {
    e.preventDefault();
    const lista_ = await api.get('/biblioteca', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        tipoobra: filtro.tipoobra,
        campo: filtro.campo,
        assunto: filtro.assunto,
      },
    });

    setLista(lista_.data);
    setCurrentPage(1);
  };

  useEffect(() => {
    
    const indexOfLast = currentPage * perPage;
    const indexOfFirst = indexOfLast - perPage;
    
    let result = [...lista];
    
    setListaFiltro(result);
    
    if(result.length > 0)
      result = result.slice(indexOfFirst, indexOfLast);

    setListaPagina(result);

  }, [lista, currentPage]);


  async function open(e, linha){
    e.preventDefault();
    setObra(linha);
    const exemp = await api.get('/biblioteca/acervo', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        numobr: linha.numobr,
        tipobr: linha.tipobr,
      },
    });

    const reser = await api.get('/biblioteca/reserva', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        numobr: linha.numobr,
        tipobr: linha.tipobr,
      },
    });
    setExemplares(exemp.data);
    setReservas(reser.data);
    $('#myModal').modal('show');
  }

  return (
    <>
      <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />
      <Card className="card-main">
        <Card.Header className="text-center h3">Acervo da Biblioteca - Consulta</Card.Header>
        <Card.Body> 
          <form className="form">

            <div className="form-row">
              <div className="form-group col-md-3">
                <label>Tipo de Obra:</label>
                <select className="form-control" value={filtro.tipoobra} onChange={e => setFiltro({...filtro, tipoobra: e.target.value})}>
                  <option value="">TODOS</option>
                  <option value="1">LIVROS</option>
                  <option value="2">PER&Oacute;DICOS</option>
                  <option value="3">MULTIMEIOS</option>
                  <option value="4">MONOGRAFIAS</option>
                  <option value="5">PRODUÇÃO CIENTÍFICA</option>
                  <option value="6">HEMEROTECA</option>
                  <option value="7">ANALÍTICA DE PER&Oacute;DICOS</option>
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-3">
                <label>Pesquisar no Campo:</label>
                <select className="form-control" value={filtro.campo}  onChange={e => setFiltro({...filtro, campo: e.target.value})}>
                  <option value="">TODOS</option>
                  <option value="titulo">T&Iacute;TULO</option>
                  <option value="autor">AUTOR</option>
                  <option value="palavra">ASSUNTO</option>
                  <option value="subtit">SUBTITULO</option>
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Assunto / Palavra&minus;Chave:</label>
                <input type="text" maxLength={80} value={filtro.assunto} className="form-control" onChange={e => setFiltro({...filtro, assunto: e.target.value})}/>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <button className="btn btn-dark" onClick={pesquisar}>Consultar</button>
              </div>
            </div>	
            
          </form>		

          <hr />

          <ul className="list-group">	
          {listaFiltro.map((linha, index) => 
            <li key={index} className="list-group-item text-left">
              <a href="#" onClick={e => open(e, linha)} data-toggle="modal" data-target="#myModal">{linha.titulo}</a><br />
                {linha.autor}.  {linha.titulo}. {linha.edicao}. {linha.locpub}: {linha.editora}, {linha.locub}. {linha.pag}. {linha.isbn} <br/>
              <Link className="btn btn-sm mt-2" style={{backgroundColor: '#D2691E', color: 'white'}} to={`/reserva/${linha.numobr}/${linha.tipobr}`}>Reservar</Link>
            </li>
          )}
          </ul>
          <br />
          <Pagination perPage={perPage} total={listaFiltro.length} paginate={paginate}/>

          <hr />

        </Card.Body>

        </Card>
       
        {/* <!-- Modal --> */}
        {obra && (
        <div className="modal fade" id="myModal" tabIndex={-1} aria-hidden={true}>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="myModal">
                Detalhes da Obra de nº: {obra.tipobr} - {obra.numobr}/{obra.qtdexe}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

                <table width="100%" cellPadding="2" cellSpacing="1">			
                  <tbody>
                    <tr > 
                      <td width="150" align="right" height="10"> 
                        <b>Nº de Chamada: &nbsp;</b>
                      </td>
                      <td colSpan="3" > 
                          {obra.classif}
                      </td>
                    </tr >
                    <tr > 
                      <td width="150" align="right" height="10"> 
                        <b>Cutter: &nbsp;</b>
                      </td>
                      <td colSpan="3" > 
                          {obra.cutter}
                      </td>
                    </tr >
                    <tr > 
                      <td width="150" align="right" height="10">
                        <b>Código: &nbsp;</b>
                      </td>
                      <td width="150">
                        {obra.tipobr} - {obra.numobr}/{obra.qtdexe}
                      </td>
                      <td width="150" align="right" height="10">
                        <b>ISBN: &nbsp;</b>
                      </td>
                      <td >
                        {obra.isbn}
                      </td>
                    </tr>
                    <tr > 
                      <td width="150" align="right" height="10"><b>Título: &nbsp;</b></td>
                      <td colSpan="3" >{obra.titulo}</td>
                    </tr>
                    <tr > 
                      <td width="150" align="right" height="10"><b>Autor: &nbsp;</b></td>
                      <td colSpan="3" >{obra.autor}</td>
                    </tr>
                    <tr > 
                      <td width="150" align="right" height="10"><b>Publicação: &nbsp;</b></td>
                      <td colSpan="3" >
                      {obra.locpub},{obra.editora}, {obra.edicao} {obra.anopub}
                      </td>
                    </tr>
                    <tr > 
                      <td width="150" align="right" height="10" valign="top"><b>Assuntos: &nbsp;</b></td>
                      <td colSpan="3"> 
                        <p>{obra.palavras}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>								
                <hr />
                
                <h4>Exemplares</h4> 
                <table className={"table table-bordered w-100 " + (config.isMobile && ' tablemobile')} >
                  <thead>
                    <tr > 
                        <th align="center">Id Tombo</th>
                        <th align="center">Vol.</th>
                        {!config.isMobile && (
                          <th align="center">Ex.</th>				                  
                        )}
                        <th align="center">Sit.</th>
                        <th align="center">Só Consulta</th>
                        {!config.isMobile && (
                          <th align="center">Desc. Física</th>
                        )}
                        <th align="center">Data Emp.</th>
                        <th align="center">Prev. Dev.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exemplares.length > 0 ? exemplares.map((ex, index) => 
                      <tr key={index}>
                        <td align="center">{ex.idtombo}</td>                        
                        <td align="center">{ex.vol}</td>
                        {!config.isMobile && (
                        <td align="center">{ex.ex}</td>					                   	   	       
                        )}
                        
                        {ex.sit === 'D' && (
                          <td align="center">Dispon&iacute;vel</td>
                        )}
                        {ex.sit === 'E' && (
                          <td align="center">Emprestado</td>
                        )}
                        {ex.sit === 'B' && (
                          <td align="center">Baixado</td>
                        )}
                        
                        {ex.socon === '0' ? (
                          <td align="center">NÃO</td>
                        ) : (
                          <td align="center">SIM</td>
                        )}
                        {!config.isMobile && (
                        <td align="center">{ex.pag}</td>
                        )}
                        <td align="center">{ex.dataemprest}</td>
                        <td align="center">{ex.dataprevista}</td>
                      </tr>
                    ) : (
                      <tr>
                          <td colSpan="7" align="center"><center>Nenhum Exemplar encontrado.</center></td>
                      </tr>
                    )}
                  </tbody>
                </table>									   
                <hr />
                <h4>Reservas</h4> 
                <table className={"table table-bordered w-100 " + (config.isMobile && ' tablemobile')} >
                  <thead>
                    <tr > 
                      <th align="center">Reservado para</th>												    
                      <th align="right">Matrícula</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reservas.length > 0 ? reservas.map((r, index) =>
                      <tr key={index}>
                        <td align="center">{r.datareserva}</td>
                        <td align="center">{r.matricula}</td>
                      </tr>
                    ) : (
                      <tr>
                          <td colSpan="2" align="center"><center>Nenhuma reserva encontrada.</center></td>
                      </tr>
                    )}				
                  </tbody>					   
                </table>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          </div>
        </div>
        )}
      <Footer />
    </>
  );
}


export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros
}))(ConsultaAcervo);