import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Card } from 'react-bootstrap';

import api from '../../services/api';

import { Container } from './styles';
import { useAlert } from 'react-alert';

function Periodos({ history, usuario, escola, sistema, dispatch }) {
  const alert = useAlert();

  const [periodos, setPeriodos] = useState([]);
  const [periodo, setPeriodo] = useState(null);

  useEffect(() => {
    async function load() {
      if (usuario.tipo_usuario == 'funcionario')
      {
        await api.get('funcionario/periodos', {
          params: { codigo: escola.codigo }
        }).then(response => {
          setPeriodos(response.data.dados);
        }).catch(err => {
          console.log(err);
        });
        
      }else{
        const response = await api.get('/aluno/periodos', {
          params: { codigo: escola.codigo, matric: usuario.matric }
        }).then(response => {
          console.log(response.data.dados);
          setPeriodos(response.data.dados);
        }).catch(err => {
          console.log(err);
        });
      }
    }

    load();
  }, [escola]);

  function confirmar(e) {
    e.preventDefault();

    const periodo_selecionado = periodos.find(per => per.id === periodo);
    if (periodo_selecionado) {      
      dispatch({ type: '@PERIODO/SETA_PERIODO', periodo: periodo_selecionado })
      if (usuario.tipo_usuario == 'aluno')
        usuario.escola = periodo_selecionado.escola;
        usuario.ano = periodo_selecionado.ano;
        usuario.seqano = periodo_selecionado.seqano;
        dispatch({
          type: '@USUARIO/AUTENTICACAO_SUCESSO',
          usuario: { tipo_usuario: usuario.tipo_usuario, ...usuario },
        });

      if (usuario.tipo_usuario === 'funcionario')
        history.push('/homefuncionario');
      else
        history.push('/homealuno');
    } else {
      alert.warning('Antenção, Selecione um período');
    }
  }

  return (
    <Container style={{ height: '100vh' }}>
      <Card className="card-periodo">
        <Card.Body style={{ textAlign: 'center' }}>
          <img
            height="150"
            src={`https://maisescola.netlify.app/logos/${escola.codigo}.png?t=${new Date().getTime()}`}
            alt="Logo"
          />
          <Form>
            <Form.Group style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 100,
              marginBottom: 0,
            }}>
              <Form.Control
                as="select"
                defaultValue={periodo}
                onChange={e => setPeriodo(e.target.value)}
                className="input-periodos"
              >
                <option value={0}>Selecione o Período</option>
                {periodos.map(per => (
                  <option key={per.id} value={per.id}>{per.nome_escola} - {per.des}</option>
                ))}
              </Form.Control>
              <Button
                onClick={confirmar}
                type="submit"
                className="float-left button-login"
                variant="info"
                style={{ marginTop: 14 }}
              >
                Confirmar
                </Button>
            </Form.Group>
          </Form>

        </Card.Body>
        <Card.Footer style={{ textAlign: 'center', fontSize: 12 }}>
          <div className="copy-text">Criado por Path Informática (v {sistema.versao})</div>
        </Card.Footer>
      </Card>
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario
}))(Periodos);
