import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore, endOfDay, getMonth} from 'date-fns';

import * as yup from 'yup';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';
import api from '../../../services/api';
import { Container, TableNota, Botoes } from './styles';
import { useAlert } from 'react-alert';

function FrequenciaLancamentoDia({ history, sistema, escola, usuario, periodo }) {

  const alert = useAlert();
  const schema = yup.object({
    turma: yup.string().min(1).required(),
    disciplina: yup.string().min(1).required(),
    materia: yup.string(),
    data: yup.string().min(9).required(),
    aulas: yup.string().min(1).required(),
  });

  const date = startOfDay(new Date());
  const [datalimite, setDataLimite] = useState('');
  const [data, setData] = useState(date);
  const [passouDataLimite, setPassouDataLimite] = useState(false);
  
  const [grade, setGrade] = useState([]);
  const [lista, setLista] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState(null);
  const [turmaDetalhe, setTurmaDetalhe] = useState(null);
  const [disciplinas, setDisciplinas] = useState([]);  
  const [disciplina, setDisciplina] = useState(null);  
  const [materias, setMaterias] = useState([]); 
  const [temmateria, setTemMateria] = useState(''); 
  const [erro, setErro] = useState(''); 
  const [dados, setDados] = useState({
    turma: '',
    disciplina: '',
    materia: '',
    data: format(date, 'yyyy-MM-dd'),
    date: date,
    aulas: 1,
  });

  // eslint-disable-next-line
  const [dataPick, setDataPick] = useState(startOfDay(date));
  
  const CustomDatePciker = React.forwardRef((props, ref) => (
      <button type="button" className="form-control" onClick={props.onClick} >
          {props.value}
      </button>
  ));  

  function getDataLimite(detalhe) {
    let datalimite = null;    
    switch (getMonth(data)+1) {
      case 1:
        if (detalhe.dat01)
          datalimite = new Date(detalhe.dat01.substring(0,4), detalhe.dat01.substring(5,7)-1, detalhe.dat01.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        break;
    
      case 2:
        if (detalhe.dat02)
        datalimite = new Date(detalhe.dat02.substring(0,4), detalhe.dat02.substring(5,7)-1, detalhe.dat02.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        break;

      case 3:
        if (detalhe.dat03)
        datalimite = new Date(detalhe.dat03.substring(0,4), detalhe.dat03.substring(5,7)-1, detalhe.dat03.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        break;
      
      case 4:
        if (detalhe.dat04)
        datalimite = new Date(detalhe.dat04.substring(0,4), detalhe.dat04.substring(5,7)-1, detalhe.dat04.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        break;
      
      case 5:
        if (detalhe.dat05)
          datalimite = new Date(detalhe.dat05.substring(0,4), detalhe.dat05.substring(5,7)-1, detalhe.dat05.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        break;

      case 6:
        if (detalhe.dat06)
        datalimite = new Date(detalhe.dat06.substring(0,4), detalhe.dat06.substring(5,7)-1, detalhe.dat06.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        break;
    
      case 7:
        if (detalhe.dat07)
          datalimite = new Date(detalhe.dat07.substring(0,4), detalhe.dat07.substring(5,7)-1, detalhe.dat07.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        break;
        
      case 8:
        if (detalhe.dat08)
          datalimite = new Date(detalhe.dat08.substring(0,4), detalhe.dat08.substring(5,7)-1, detalhe.dat08.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        
          break;
      
      case 9:
        if (detalhe.dat09)  
          datalimite = new Date(detalhe.dat09.substring(0,4), detalhe.dat09.substring(5,7)-1, detalhe.dat09.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        break;

      case 10:
        if (detalhe.dat10)
        datalimite = new Date(detalhe.dat10.substring(0,4), detalhe.dat10.substring(5,7)-1, detalhe.dat10.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        break;

      case 11:
        if (detalhe.dat11)
          datalimite = new Date(detalhe.dat11.substring(0,4), detalhe.dat11.substring(5,7)-1, detalhe.dat11.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');
        
        break;

      case 12:
        if (detalhe.dat12)
          datalimite = new Date(detalhe.dat12.substring(0,4), detalhe.dat12.substring(5,7)-1, detalhe.dat12.substring(8,10), 12, 0, 0);
        else
          alert.error('Data limite não cadastrada');

        break;

      default:
        break;      
    }
    return datalimite || null;
  }

  // carrega grade
  useEffect(() => { 
    async function load(){
      let url = '/frequenciadia/grade_professor';
      if (usuario.tipo === 'ADMIN')
        url = '/frequenciadia/grade_admin';

      if (usuario.tipo === 'COORD')
        url = '/frequenciadia/turmas/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });      
      console.log(result.data);
      setGrade(result.data);
    }
    load();
  }, [usuario, escola, periodo])

  // seta turmas
  useEffect(() => {
    if (grade && grade?.length > 0)
    {
      let turmas_ = [];
      grade.forEach(e => {
        if (!turmas_.find(t => t.turma === e.turma))
          turmas_.push({turma: e.turma, destur: e.destur});
      });
      setTurmas(turmas_);
    }
  }, [grade])

  // seta disciplinas
  useEffect(() => {     
    if (turma)
    {
      let disciplinas_ = [];
      grade.forEach(e => {
        if (e.turma == turma.turma)
          if (!disciplinas_.find(d => d.discip === e.discip))
            disciplinas_.push({discip: e.discip, desdis: e.desdis});
      });      
      setDisciplinas(disciplinas_);
    }
  }, [turma])

  const carregaMateria = (discip) => {
    if (discip.length > 0)
    {
      let materias_ = [];
      grade.forEach(e => {
        if (e.turma == turma.turma && e.discip == discip && e.materia.length > 0)
          if (!materias_.find(d => d.materia === e.materia))
            materias_.push({materia: e.materia, desdis: e.materia});
      });
      setMaterias(materias_);
      // setTemMateria(materias_.length > 0 ? 'S' : 'N');
      setDados({...dados, disciplina: discip});
      console.log('materias_',materias_);

    }else{
      setMaterias([]);
      setDados({...dados, materia: '', disciplina: ''});
      // setTemMateria('N');
    }

  };

  // carrega lista de alunos
  useEffect(() => {
    setLista([]);
    setErro('');
    async function load() {
      console.log(dados);
      
      try
      {
        const detalhe = grade.find(g => 
          g.turma == dados.turma && 
          g.discip == dados.disciplina &&
          g.materia == dados.materia
        );
        
        const datalimite =  getDataLimite(detalhe);
        
        if (datalimite)
        {
          const testeDataLimite = isBefore(datalimite, date);
          setPassouDataLimite(testeDataLimite);
          setDataLimite(format(datalimite, 'dd/MM/yyyy'));      
        }else{
          setErro('Data limite não cadastrada');
        }

        setTurmaDetalhe(detalhe);

        const frequencia_ = await api.get('/frequenciadia/listaalunos', {
          params: {
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,
            turma: dados.turma,
            discip: dados.disciplina,
            materia: dados.materia,
            data: dados.data,
          },
        });
        setLista(frequencia_.data);
        if (frequencia_.data.length > 0)
        {
          setDados({...dados, aulas: frequencia_.data[0].qtdaulas});
        }
        
      }catch(err){
        console.log(err);
        setErro(err);
      }
    }

    if (dados.turma.length > 0 && dados.disciplina.length > 0 && validaMateria())
    {
      load();
    }else
    {
      setLista([]);
      setTurmaDetalhe([]);
      setDataLimite('');
      setPassouDataLimite(false);
    }

  }, [dados.disciplina, dados.data, dados.turma, dados.materia, data]);

  const validaMateria = () => {
    if (materias.length > 0)
      if (dados.materia.length > 0)
        return true;
      else
        return false;
    else{
      return true;
    }
  }

  function inputChange(e){
    if (e.target.name === 'turma'){
      const reg = turmas.find(t => t.turma === e.target.value)
      setTurma(reg);
      setDados({...dados, turma: e.target.value, disciplina: '', materia: ''});
      setMaterias([]);
      setDisciplina(null);
    }

    if (e.target.name === 'disciplina')
    {
      console.log('disciplina', e.target.value)
      setDados({...dados, disciplina: e.target.value});
      // setDisciplina(e.target.value);
      carregaMateria(e.target.value);
    }

    if (e.target.name === 'materia')
      setDados({...dados, materia: e.target.value});
    
    if (e.target.name === 'aulas')
      setDados({...dados, aulas: e.target.value});
    
  }

  function inputDateChange(e){
    setDataPick(e);
    setData(e);
    setDados({...dados, data: format(e, 'yyyy-MM-dd')});
  }

  function handleInputFaltaLostFocus(e){
    
    let {id, value} = e.target;
    let lista_ = lista;
    if (value > dados.aulas)
    {
      lista_[id].freq = '';
      document.getElementById(id).value = '';
      alert.error('Quantidade de faltas maior que a quantidade de aulas.');
    }else{
      lista_[id].freq = value;
    }    
    
    setLista(lista_);
  }

  async function gravar(){
    if (!schema.isValid())
    {
      alert.error('Preencha os campos obrigatórios');
    }else{

      let dados_ = dados;
      dados_.lista = lista;
      dados_.codigo= escola.codigo;
      dados_.escola= periodo.escola; 
      dados_.ano= periodo.ano;
      dados_.seqano= periodo.seqano;
      dados_.guerra= usuario.guerra;
      // console.log(dados_);
      // return;
      const result = await api.post('/frequenciadia/gravar', dados_);
      const log = await api.post('/frequenciadia/gravar/log', dados_);
      
      if (result.data.success)
      {
        alert.success('Dados gravados com sucesso!');
      }
    }

  }

  function handleInputFalta(e){
    const {id, value} = e.target;
    let lista_ = lista?.map((item, index) => {
      if (index === id)
        lista_.freq = value;

      return item;
    });
    setLista(lista_);
  }

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Lançamento de Faltas / Dia</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
          
            <div className="form-row">
              <div className="form-group col-md-8">
                <label>Turma</label>
                <select className="form-control" name="turma" defaultValue={dados.turma} onChange={inputChange} >
                  <option value="">...</option>
                  {turmas && turmas.map(item => 
                    <option key={item.turma} value={item.turma}>{item.turma} - {item.destur}</option>
                  )}                
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-9">
                <label>Disciplina</label>
                <select className="form-control" value={dados.disciplina} name="disciplina" onChange={inputChange}>
                  <option value="">...</option>
                  {disciplinas && disciplinas.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              {materias.length > 0 && (
              <div className="form-group col-md-3">
                <label>Matéria</label>
                <select className="form-control" name="materia" onChange={inputChange}>
                  <option value="">...</option>
                  {materias.map(item => 
                    <option key={item.materia} value={item.materia}>{item.desdis}</option>
                  )}                
                </select>
              </div>
              )}              
            </div>  

            <div className="form-row">
                <div className="form-group col-md-3">
                  <label>Data</label>
                  <DatePicker
                    name="data"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    selected={dataPick}
                    onChange={inputDateChange}
                    customInput={<CustomDatePciker />}
                    locale={pt} />
                </div>
                <div className="form-group col-md-1">
                  <label>Aulas</label>
                  <input type="text" maxLength={1} className="form-control" name="aulas" onChange={inputChange} value={dados.aulas}/>
                </div>
            </div>          

            <hr />
            {passouDataLimite ? (
              <div className="alert alert-danger" role="alert">
                Prazo para lançamento das faltas expirou em <strong>({datalimite})</strong>.
              </div>
            ): (
              datalimite && (
                <div className="alert alert-success" role="alert">
                  Prazo para lançamento das faltas expira em <strong>({datalimite})</strong>.
                </div>
              )
            )}
          {erro != '' && (
            <div className="alert alert-danger" role="alert">
              {erro}
            </div>
          )}

          {erro == '' && (
            <>
              {sistema.isMobile ? (
                <TableNota className="table">
                  <thead className="thead-dark">             
                    <tr>
                      {/* <th className="column-ord header-sort">Ord.</th> */}
                      {/* <th className="column-matric header-sort">Matrícula</th> */}
                      <th className="column-nome header-sort">Nome</th>
                      <th className="column-faltas">Qtd.</th>
                    </tr>
                  </thead>
                  <tbody>  
                    {lista.length > 0 ? (
                      lista.map((item, index) => 
                        <tr key={index} className="line-focus">
                          {/* <td>{item.matric}</td> */}
                          <td>{item.nome}</td>
                          <td><input type="text" maxLength={1} id={index} defaultValue={item.freq} onChange={handleInputFalta} onBlur={handleInputFaltaLostFocus} className="input-ia" disabled={passouDataLimite}/></td>
                        </tr>
                      )
                    ) : (
                      <tr key={1}>
                        <td className="linha-sem-dados" colSpan={4}>Nenhuma informação</td>
                      </tr>
                      )}                                   
                  </tbody>
                </TableNota>
              ) : (
                <TableNota className="table">
                  <thead className="thead-dark">             
                    <tr>
                      {/* <th className="column-ord header-sort">Ord.</th> */}
                      <th className="column-matric header-sort">Matrícula</th>
                      <th className="column-nome header-sort">Nome</th>
                      <th className="column-faltas">Qtd.</th>
                    </tr>
                  </thead>
                  <tbody>  
                    {lista.length > 0 ? (
                      lista.map((item, index) => 
                        <tr key={index} className="line-focus">
                          <td>{item.matric}</td>
                          <td>{item.nome}</td>
                          <td><input type="text" maxLength={1} id={index} defaultValue={item.freq} onChange={handleInputFalta} onBlur={handleInputFaltaLostFocus} className="input-ia" disabled={passouDataLimite}/></td>
                        </tr>
                      )
                    ) : (
                      <tr key={1}>
                        <td className="linha-sem-dados" colSpan={4}>Nenhuma informação</td>
                      </tr>
                      )}                                   
                  </tbody>
                </TableNota>              
              )}
            </>
          )}
          </form>
          
          <hr />
          {(lista.length > 0 && !passouDataLimite)&& (
            <Botoes>   
              {erro == '' && ( 
                <button type="button" className="btn btn-success" onClick={gravar}>Gravar</button>            
              )}
            </Botoes>
          )}
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(FrequenciaLancamentoDia);