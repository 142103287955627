
const _usuario = localStorage.getItem('maisescola.usuario');

const INITIAL_STATE = _usuario ? JSON.parse(_usuario) : null;

export default function usuario(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@USUARIO/AUTENTICACAO_SUCESSO':
      localStorage.setItem('maisescola.usuario', JSON.stringify(action.usuario));
      return action.usuario;
    default:
      return state;
  };
};
