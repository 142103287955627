import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';

function BoletimInstrumento({ history, usuario, parametros, escola, periodo, sistema }) {
  const [dados, setDados] = useState({
    codigo: usuario.codigo,
    escola: periodo.escola,
    ano: periodo.ano,
    seqano: periodo.seqano,
    nava: '',
    matric: usuario.matric
  });
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [notas, setNotas] = useState(null);
  const [avisoBloqueio, setAvisoBloqueio] = useState(0);
  const [parames, setParames] = useState({});

  useEffect(() => {
    async function load() {
      const consulta_bolqueio = await api.get('/consulta_bolqueio', { 
        params: { 
          codigo: escola.codigo, 
          escola: escola.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: usuario.turma
        }
      });     
      setAvisoBloqueio(consulta_bolqueio.data.instrumento);

      const parametro = await api.get('/parametros', { params: { codigo: escola.codigo, parametro: 'm_bol'}});     
      setParames(parametro.data);

      const avaliacoes_ = await api.get('/avaliacoes/diarioweb', {
        params: {
          codigo: usuario.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
        },
      });
      setAvaliacoes(avaliacoes_.data);

    }

    load();
  }, []);

  useEffect(() => {
    async function load() {

      const notas_ = await api.get('/instrumentos', {
        params: dados,
      });
      
      setNotas(notas_.data);
    }
    if (!localStorage.getItem('alunodebito'))
      load();
  }, [dados.nava]);
  
  return (
    <>
      <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />

      <Card className="card-main">       
        {avisoBloqueio == 0 ? (
          <Card.Body>
            <div style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              height: 300
            }}>
              <p style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: parames?.msgbloqbol?.replace(/\n/g , "<br \>").replace('Boletim', 'Informação').replace('Documento', 'Dados')}}>            
              </p>
            </div>
          </Card.Body>
        ) : (

          <>
            {(localStorage.getItem('alunodebito') && parametros.bloaludeb) ? (
              <Card.Body>
                <div className='alert alert-danger'>
                  <h4>Atenção!</h4>
                  <p>
                  Entre em contato com a secretaria da Instituição.
                  </p>
                </div>
              </Card.Body>
            ): (
              <>
                <Card.Header className="text-center h3">Instrumentos</Card.Header>
                <Card.Body>
                <form>
                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <label>Avaliação</label>
                        <select className="form-control" defaultValue={dados.nava} onChange={e => setDados({...dados, nava: e.target.value})}>
                          <option value="">...</option>
                          {avaliacoes.map(item => 
                            <option key={item.id} value={item.nava}>{item.ava}</option>
                          )}                
                        </select>
                      </div>
                    </div>
                  </form>
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}>
                    {notas && notas.map((nota, index) => 
                      <table key={index} className="table table-striped table-bordered">
                        <thead>
                          <tr className="active">
                            <th>Disciplina</th>
                            <th className="text-center" >{nota.cab1}</th>
                            <th className="text-center" >{nota.cab2}</th>
                            <th className="text-center" >{nota.cab3}</th>
                            <th className="text-center" >{nota.cab4}</th>
                            <th className="text-center" >{nota.cab5}</th>
                            <th className="text-center" >{nota.cab6}</th>
                            <th className="text-center" >Nota</th>
                            <th className="text-center" >Faltas</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>               
                            <td className="text-left">{nota.dis}</td>
                            <td className="text-center col-sm-1">{nota.ia1}</td>                    
                            <td className="text-center col-sm-1">{nota.ia2}</td>                    
                            <td className="text-center col-sm-1">{nota.ia3}</td>                    
                            <td className="text-center col-sm-1">{nota.ia4}</td>                    
                            <td className="text-center col-sm-1">{nota.ia5}</td>                    
                            <td className="text-center col-sm-1">{nota.ia6}</td>                    
                            <td className="text-center col-sm-1">{nota.nota}</td>                    
                            <td className="text-center col-sm-1">{nota.faltas}</td>                    
                          </tr>
                        </tbody>
                      </table>                
                    )}
                    <br />

                  </div>
                </Card.Body>
              </>
            )}
          </>
        )}
      </Card>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(BoletimInstrumento)
