import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';

import {format, startOfDay, isBefore} from 'date-fns';

import api from '../../../services/api';
import { Container, TableNota, Botoes, Formula } from './styles';

import CurrencyInput from 'react-currency-input';

// import {FaRegEdit, FaUser, FaUsers} from 'react-icons/fa';

import formula from '../../../services/formula';
import { useAlert } from 'react-alert';

function NotasConsulta({ history, sistema, escola, usuario, periodo }) {

  const alert = useAlert();
  const date = startOfDay(new Date());
  const [datalimite, setDataLimite] = useState('');
  const [data, setData] = useState(format(date, 'yyyy-MM-dd'));
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turmaDetalhe, setTurmaDetalhe] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [notas, setNotas] = useState([]);
  const [dados, setDados] = useState({
    nava: '',
    turma: '',
    disciplina: '',
    materia: '',
    semnotas: false,
  });

  const [turma, setTurma] = useState(null);  
  const [disciplina, setDisciplina] = useState(null);  
  const [materia, setMateria] = useState(null);  
  const [ia1, setIa1] = useState(false);
  const [ia2, setIa2] = useState(false);
  const [ia3, setIa3] = useState(false);
  const [ia4, setIa4] = useState(false);
  const [ia5, setIa5] = useState(false);
  const [ia6, setIa6] = useState(false);
  const [falta, setFalta] = useState(false);
  const [passouDataLimite, setPassouDataLimite] = useState(false);
  const [notaTransferida, setNotaTransferida] = useState(false);
  const [semnotas, setSemNotas] = useState(false);

  async function loadAvaliacoes(){ // avaliações

    const result = await api.get('avaliacoes/diarioweb', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
      },
    });
    setAvaliacoes(result.data);
  }
  // eslint-disable-next-line
  useEffect(() => {loadAvaliacoes()}, [periodo]);

  // carrega turmas
  useEffect(() => { 
    async function load(){
      let url = '/grade/turmas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/turmas/diario/admin';

      if (usuario.tipo === 'COORD')
        url = '/grade/turmas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });      
      setTurmas(result.data);
      // setListaTurmas(result.data);
    }

    load();
  }, [usuario, escola, periodo])

  // carrega disciplinas
  useEffect(() => {     
    async function load(){
      let url = '/grade/disciplinas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/disciplinas/diario/todas';

      if (usuario.tipo === 'COORD')
        url = '/grade/disciplinas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: turma.turma,
        },
      }); 

      setDisciplinas(result.data);
    }

    if (turma)
      load();

  }, [usuario, escola, periodo, turma])

  useEffect(() => {
    async function carregaMaterias(){
      const resultMateria = await api.get('/grade/materias', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: dados.turma,
          discip: dados.disciplina,
          nava: dados.nava,
        },
      }); 
      setMaterias(resultMateria.data);
      setMateria(resultMateria.data.length > 0 ? 'S' : 'N');
  
    }

    carregaMaterias();
  }, [dados.disciplina]);

  // carrega lista de alunos
  useEffect(() => {
    setIa1(false);
    setIa2(false);
    setIa3(false);
    setIa4(false);
    setIa5(false);
    setIa6(false);
    setFalta(false);

    async function load() {

      const resultMateria = await api.get('/grade/materias', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: dados.turma,
          discip: dados.disciplina,
          nava: dados.nava,
        },
      }); 


      const result = await api.get('grade/turma/detalhe/diario', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          materia: dados.materia,
          nava: dados.nava,
        },
      });

      if (result.data){

        const datalimite =  new Date(result.data.datalimite);
        const testeDataLimite = isBefore(datalimite, date);

        setPassouDataLimite(testeDataLimite);
        setDataLimite(format(datalimite, 'dd/MM/yyyy'));
        setTurmaDetalhe(result.data);
        
        const transferido = result.data.transferido === 'S';
        setNotaTransferida(transferido);

        const resultNotas = await api.get('/aluno/lista/diario', {
          params: {
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,
            turma: dados.turma,
            discip: dados.disciplina,
            materia: dados.materia,
            nava: dados.nava,
          },
        });
  
        const rest = resultNotas.data.map(item => {
          
          item.ia1 = (item.ia1 === '') ? null : item.ia1;
          item.ia2 = (item.ia2 === '') ? null : item.ia2;
          item.ia3 = (item.ia3 === '') ? null : item.ia3;
          item.ia4 = (item.ia4 === '') ? null : item.ia4;
          item.ia5 = (item.ia5 === '') ? null : item.ia5;
          item.ia6 = (item.ia6 === '') ? null : item.ia6;
          return item;
        });

        setNotas(rest);
      }else{
        alert.info('Data Limite não cadastrada.');
        setNotas([]);
        setTurmaDetalhe([]);
        setDataLimite('');
        setPassouDataLimite(false);
        setNotaTransferida(false);
      }
    }

    if (dados.nava && dados.turma && dados.disciplina && (materia === 'N' || dados.materia.length > 0))
      load();
    else
    {
      setNotas([]);
      setTurmaDetalhe([]);
      setDataLimite('');
      setPassouDataLimite(false);
      setNotaTransferida(false);
    }

  }, [dados]);

  function changeTurma(e) {    
    const reg = turmas.find(t => t.turma === e.target.value)
    setTurma(reg);
    setDados({...dados, turma: e.target.value, disciplina: ''});
    setDisciplina(null);
  }

  function handleChange(e){

    if (e.target.name === 'nava')
      setDados({...dados, nava: e.target.value});

    if (e.target.name === 'turma')
      setDados({...dados, turma: e.target.value});

    if (e.target.name === 'disciplina')
      setDados({...dados, disciplina: e.target.value});

    if (e.target.name === 'semnotas')
      setSemNotas(e.target.checked);
  }

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Consulta de Notas</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label>Avaliação</label>
                <select className="form-control" name="nava" defaultValue={dados.nava} onChange={handleChange}>
                  <option value="">...</option>
                  {avaliacoes.map(item => 
                    <option key={item.id} value={item.nava}>{item.ava}</option>
                  )}                
                </select>
              </div>
              <div className="form-group col-md-8">
                <label>Turma</label>
                <select className="form-control" name="turma" defaultValue={dados.turma} onChange={changeTurma} >
                  <option value="">...</option>
                  {turmas.map(item => 
                    <option key={item.turma} value={item.turma}>{item.destur}</option>
                  )}                
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-9">
                <label>Disciplina</label>
                <select className="form-control" value={dados.disciplina} name="disciplina" onChange={handleChange}>
                  <option value="">...</option>
                  {disciplinas.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              {materias.length > 0 && (
              <div className="form-group col-md-3">
                <label>Matéria</label>
                <select className="form-control" name="materia" onChange={handleChange}>
                  <option value="">...</option>
                  {materias.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              )}              
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="gridCheck" name="semnotas" onChange={handleChange} defaultValue={semnotas} />
                  <label className="form-check-label" htmlFor="gridCheck">
                    Mostrar somente alunos sem nota.
                  </label>
                </div>
              </div>
            </div>

            <hr />
            {notaTransferida && (
              <div className="alert alert-danger" role="alert">
                Notas transferidas em <strong>({ turmaDetalhe.ultima_transferencia && format(new Date(turmaDetalhe.ultima_transferencia), 'dd/MM/yyyy')})</strong>.
              </div>
            )}
            {passouDataLimite ? (
              <div className="alert alert-danger" role="alert">
                Prazo para lançamento das notas expirou em <strong>({datalimite})</strong>.
              </div>
            ): (
              datalimite && (
                <div className="alert alert-success" role="alert">
                  Prazo para lançamento das notas expira em <strong>({datalimite})</strong>.
                </div>
              )
            )}
            <TableNota className="table">
              <thead className="thead-dark">                
                <tr>
                  <th className="column-ord header-sort">Ord.</th>
                  <th className="column-matric header-sort">Matrícula</th>
                  <th className="column-nome header-sort">Nome</th>
                  <th className="column-ia"><input disabled name="cab1" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab1: e.target.value})} className="input-ia-header" type="text" defaultValue={turmaDetalhe.cab1 && turmaDetalhe.cab1}/></th>
                  <th className="column-ia"><input disabled name="cab2" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab2: e.target.value})} className="input-ia-header" type="text" defaultValue={turmaDetalhe.cab2 && turmaDetalhe.cab2}/></th>
                  <th className="column-ia"><input disabled name="cab3" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab3: e.target.value})} className="input-ia-header" type="text" defaultValue={turmaDetalhe.cab3 && turmaDetalhe.cab3}/></th>
                  <th className="column-ia"><input disabled name="cab4" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab4: e.target.value})} className="input-ia-header" type="text" defaultValue={turmaDetalhe.cab4 && turmaDetalhe.cab4}/></th>
                  <th className="column-ia"><input disabled name="cab5" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab5: e.target.value})} className="input-ia-header" type="text" defaultValue={turmaDetalhe.cab5 && turmaDetalhe.cab5}/></th>
                  <th className="column-ia"><input disabled name="cab6" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab6: e.target.value})} className="input-ia-header" type="text" defaultValue={turmaDetalhe.cab6 && turmaDetalhe.cab6}/></th>
                  <th className="column-nota">Nota</th>
                  <th className="column-faltas">{turma && (turma.tiplan === '0' && 'Faltas')}</th>
                </tr>
              </thead>
              <tbody>
                {semnotas ? (
                  notas.map(nota => (
                    nota.nota === '' && (
                      <tr key={nota.id}>
                        <td className="column-ord">{nota.ord}</td>
                        <td className="column-matric">{nota.matric}</td>
                        <td>{nota.nome}</td>
                        <td className="column-ia">
                          <CurrencyInput disabled selectAllOnFocus={true} id={'ia1'} name={nota.id} className="input-ia" value={nota.ia1} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled selectAllOnFocus={true} id={'ia2'} name={nota.id} className="input-ia" value={nota.ia2} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled selectAllOnFocus={true} id={'ia3'} name={nota.id} className="input-ia" value={nota.ia3} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled selectAllOnFocus={true} id={'ia4'} name={nota.id} className="input-ia" value={nota.ia4} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled selectAllOnFocus={true} id={'ia5'} name={nota.id} className="input-ia" value={nota.ia5} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled selectAllOnFocus={true} id={'ia6'} name={nota.id} className="input-ia" value={nota.ia6} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">{nota.nota}</td>
                        <td className="column-ia">{turma && (turma.tiplan === '0' && <input disabled={!falta} className="input-ia" type="text"/>)}</td>                    
                      </tr>           
                    )
                  ))
                ) : (
                  notas.map(nota => (
                    <tr key={nota.id}>
                      <td className="column-ord">{nota.ord}</td>
                      <td className="column-matric">{nota.matric}</td>
                      <td>{nota.nome}</td>
                      <td className="column-ia">
                        <CurrencyInput disabled id={'ia1'} name={nota.id} className="input-ia" value={nota.ia1} thousandSeparator="," precision="1" allowEmpty={true}/>
                      </td>
                      <td className="column-ia">
                        <CurrencyInput disabled id={'ia2'} name={nota.id} className="input-ia" value={nota.ia2} thousandSeparator="," precision="1" allowEmpty={true}/>
                      </td>
                      <td className="column-ia">
                        <CurrencyInput disabled id={'ia3'} name={nota.id} className="input-ia" value={nota.ia3} thousandSeparator="," precision="1" allowEmpty={true}/>
                      </td>
                      <td className="column-ia">
                        <CurrencyInput disabled id={'ia4'} name={nota.id} className="input-ia" value={nota.ia4} thousandSeparator="," precision="1" allowEmpty={true}/>
                      </td>
                      <td className="column-ia">
                        <CurrencyInput disabled id={'ia5'} name={nota.id} className="input-ia" value={nota.ia5} thousandSeparator="," precision="1" allowEmpty={true}/>
                      </td>
                      <td className="column-ia">
                        <CurrencyInput disabled id={'ia6'} name={nota.id} className="input-ia" value={nota.ia6} thousandSeparator="," precision="1" allowEmpty={true}/>
                      </td>
                      <td className="column-ia">{nota.nota}</td>
                      <td className="column-ia">{turma && (turma.tiplan === '0' && <input disabled={!falta} className="input-ia" type="text"/>)}</td>                    
                    </tr>           
                  ))
                )} 

                {notas.length === 0 && (
                  <tr key={1}>
                    <td className="linha-sem-dados" colSpan={11}>Nenhuma informação</td>
                  </tr>
                )}
              </tbody>
            </TableNota>
          </form>
          <br />

        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(NotasConsulta);