import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: 0;
  }

  *:focus{
    outline: 0;
  }

  html, body, #root {
    background: #DCDCDC;
  }

  .breadcrumb {
    background-color: #DE6262;
    color: #FFF!important;

    a {
      color: #FFF;
    }

    .active {
      color: #FFF;
    }

    .breadcrumb-item 
    {
      ::before {
        color: #FFF;
      }
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 14px 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  .button-icon {
    cursor: pointer;
  }

  #carregando{
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
  }

  .loading-indicator {
    background: rgba(0,0,0,0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;

    div {
      position: relative;
      left: 50%;
      top: 50%;
      margin-right: -50%;
    }
  }

  
  .container {
    background-color: '#FFF';
  }

  .container-fluid {
    background-color: white!important;
    width: 98%;
    border-radius: 6px;
    box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1);
  }

  .card-main {
    margin-top: 20px;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 90px;

    @media screen and (max-width: 480px){
      width: 98%!important;
    }
  }

  .card-header {
    font-weight: bold!important;
  }


  .table td {
    padding: 3px;
  }

  .bt-action {
    padding: 3px;
  }

  .bt-action:hover{
    cursor: pointer;
  }

  .bold {
    font-weight: bold;
  }

  .borderBottom{
    border-bottom: solid 0.5px rgba(220,220,220, 0.9);
    padding-bottom: 10px;
  }

  .label-text {
    padding: .375rem .75rem!important;
  }

  .modal-header {
    background-image: linear-gradient(to right, #DE6262  0%, #DE6262cc  100%);
    padding: 5px 10px;
    align-items: center;
    color: white;
  }

  .card-header {
    background-image: linear-gradient(to right, #DE6262  0%, #DE6262cc  100%);
    padding: 5px 10px;
    align-items: center;
    color: white;
    text-align: center;
  }

  .modal-content {
    border: 1px solid #FFF;
  }

  .modal-header .close {
    color: white;
    padding: 0px;
    margin: 0px;
  }

  .modal-aviso{
    width: 80%!important;
    max-width: 80%!important;
    left: 10%;
    top: 20%;    
    position:absolute;

  }

  .tem-registro {
    background-color: rgba(240,255,240)!important;
  }


  /* The switch - the box around the slider */
  .form-group-switch {
    margin-bottom: 0px;
    align-items: center;
    display: flex;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 26px;
    margin: 0px;
    /* float: right; */
  }

  .switch-label {
    font-size: 16px;
    margin: 0px;
    margin-left: 10px;
    margin-top: 2px;
  }

  /* Hide default HTML checkbox */
  .switch input {display:none;}

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
  }

  input.default:checked + .slider {
      background-color: #444;
  }
  input.primary:checked + .slider {
      background-color: #2196F3;
  }
  input.success:checked + .slider {
      background-color: #8bc34a;
  }
  input.info:checked + .slider {
      background-color: #3de0f5;
  }
  input.warning:checked + .slider {
      background-color: #FFC107;
  }
  input.danger:checked + .slider {
      background-color: #f44336;
  }

  input:focus + .slider {
      box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
      border-radius: 24px;
  }

  .slider.round:before {
      border-radius: 50%;
  }

  .range-slider {
    display: block;
    -webkit-appearance: none;    
    width: 300px;
    height: 15px;
    border-radius: 5px;  
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;

    @media screen and (max-width: 520px){
      width: 98%!important;
    }
  }

  .range-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%; 
    background: #4CAF50;
    cursor: pointer;
  }

  .range-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4CAF50;
    cursor: pointer;
  }

  .range-div{
    display: flex;
    flex: 1;
    align-items: center;

  }

  .range-nota {
    margin: 0px;
    margin-left: 15px;
    font-size: 12px;
    font-weight: bold;
    padding: 6px;
    background: rgba(255,240,245);
    border-radius: 6px;
    width: 100px;
    height: 34px;
    text-align: center;
    padding-top: 11px;
  }

  .form-check-label {
    margin-bottom: 0;
    margin-top: 4px;
  }

  .linha-sem-dados {
    text-align: center;
    padding: 20px 0px!important;
    border: 1px solid #FFE4E1;
  }

  .header-sort{
    cursor: pointer;    
  }

  .btn-cancelar {
    margin-left: 10px;
  }

  .info-required {
    background-color: #FBEFF2;
    color: red;
  }

  .required {
    color: red;
  }

  .react-datepicker-wrapper {
    display: block!important;
  }

  .disabled{
    cursor: not-allowed!important;
    background: #ccc;
  }

  .custom-file{
    position: sticky!important;
  }

  .btn-icon{
    cursor: pointer;    
  }
  
  .switchToggle {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
  .switchToggle label {cursor: pointer; text-indent: -9999px; width: 70px; max-width: 70px; height: 30px; background: #FF0000; display: block; border-radius: 100px; position: relative; }
  .switchToggle label:after {content: ''; position: absolute; top: 2px; left: 2px; width: 26px; height: 26px; background: #fff; border-radius: 90px; transition: 0.3s; }
  .switchToggle input:checked + label, .switchToggle input:checked + input + label  {background: #3CB371; }
  .switchToggle input + label:before, .switchToggle input + input + label:before {content: 'Não'; position: absolute; top: 8px; left: 35px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
  .switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {content: 'Sim'; position: absolute; top: 8px; left: 10px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
  .switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); }
  .switchToggle label:active:after {width: 60px; } 
  .toggle-switchArea { margin: 10px 0 10px 0; }

  table td, .table th {
    vertical-align: middle!important;
  }

  .tablemobile {
    font-size: 0.7em!important;
  }

  .navbar-light .navbar-nav .nav-link {
    color: rgba(0,0,0,1)!important;
    font-size: 1rem!important;
  }
`;
