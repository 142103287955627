import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';

function MinhasReservas({ history, usuario, parametros, escola, periodo }) {

  const [reservas, setReservas] = useState([]);

  useEffect(() => {

    async function load() {
    
      const reservas_ = await api.get('/biblioteca/reservas', {
        params: {
          codigo: escola.codigo,
          matric: usuario.matric,
        },
      });
      setReservas(reservas_.data);
    };

    load();
  }, [])

  return (
    <>
    <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />
    <Card className="card-main">
      <Card.Header className="text-center h3">Acervo da Biblioteca - Minhas Reservas</Card.Header>
      <Card.Body> 
        <table className="table table-bordered" >
          <thead>
            <tr key="0"> 
              <th>Livro</th>
              <th className="text-center">Reservado para</th>
            </tr>
          </thead>
          <tbody>

          {reservas ? reservas.map((item, index) => (
            <tr key={index}>
              <td className="text-left">{item.titulo} {item.sibtit}</td>
              <td align="center">{item.datareserva}</td>
            </tr>
          )) : (
            <tr key="0">
              <td className="text-center">Nenhum Exemplar encontrado.</td>
            </tr>	
          ) }
          </tbody>
        </table>
      </Card.Body>
    </Card>
    <Footer />

    </>
  );
}


export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros
}))(MinhasReservas);