import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';
import { addDays } from 'date-fns/esm';
import { format } from 'date-fns';
import { useAlert } from 'react-alert';

function Emprestimos({ history, usuario, parametros, escola, periodo }) {

  const [emprestimos, setEmprestimos] = useState([]);
  const alert = useAlert();

  useEffect(() => {

    async function load() {
    
      const emprestimos_ = await api.get('/biblioteca/emprestimos', {
        params: {
          codigo: escola.codigo,
          matric: usuario.matric,
          escola: usuario.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
        },
      });
      setEmprestimos(emprestimos_.data);
    };

    load();
  }, [])

  function projetaData(e, item) {

    const novadata = addDays(new Date(item.devpre), parseInt(parametros.aluren));
    
    const emp_ = emprestimos.map(i => {
      if (item.id === i.id)
      {
        i.sel = i.sel == 'N' ? 'S' : 'N';
        i.datpreren = i.sel == 'S' ? novadata : null;
        i.datprerenf = i.sel == 'S' ? format(novadata, 'dd/MM/yyyy'): '';
      }

      return i;
    });


    setEmprestimos(emp_);
  }

  async function gravar(e) {
    e.preventDefault();

    const registros = emprestimos.filter(i => i.sel == 'S');
    if (registros.length > 0)
    {
        const result = await api.post('/biblioteca/renovacao', 
        {
          emprestimos: registros, 
          codigo: usuario.codigo,
          escola: usuario.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
        });
    }else{
      alert.info('Seleione um registro para renovar.');
    }

    const emprestimos_ = await api.get('/biblioteca/emprestimos', {
      params: {
        codigo: escola.codigo,
        matric: usuario.matric,
        escola: usuario.escola,
      },
    });
    setEmprestimos(emprestimos_.data);
  }

  return (
    <>
    <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />
    <Card className="card-main">
      <Card.Header className="text-center h3">Renovação</Card.Header>
      <Card.Body> 
        <h4 className="text-center">Empréstimos a Devolver</h4>
        <table className="table table-bordered" >
          <thead>
            <tr key="0"> 
              <th className="text-center">Id Tombo</th>
              <th className="text-center">Exemplar</th>
              <th className="text-center">Rec. Empréstimo</th>
              <th className="text-center">Data Empréstimo</th>
              <th className="text-center">Previsão Dev.</th>
              <th>Título</th>
              <th className="text-center">Renovar ?</th>
              <th className="text-center" style={{width: 90,}}>Renover até</th>
            </tr>
          </thead>
          <tbody>

          {emprestimos.length > 0 ? emprestimos.map((item, index) => (
            <tr key={index}>
              <td className="text-center">{item.idtombo}</td>
              <td className="text-center">{item.ex}</td>
              <td className="text-center">{item.nrecemp}</td>
              <td className="text-center">{item.data_emp}</td>
              <td className="text-center">{item.prev_dev}</td>
              <td >{item.titulo} {item.subtit}</td>
              <td className="text-center">
                {(item.poderenovar == 'S' && (item.qtdren < parametros.qtdren)) && (
                  <input onChange={e => projetaData(e, item)} checked={item.sel == 'S'} type="checkbox"/>
                )}
              </td>
              <td className="text-center"><b>{item.datprerenf}</b></td>
            </tr>
          )) : (
            <tr key="0">
              <td className="text-center" colSpan="8">Nenhum registro encontrado.</td>
            </tr>	
          ) }
          </tbody>
        </table>
        <div className="text-right">
        <button type="submit" className="btn btn-dark " onClick={gravar}>Renovar</button>
        </div>
      </Card.Body>
    </Card>
    <Footer />

    </>
  );
}


export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros
}))(Emprestimos);