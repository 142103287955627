import React from 'react';

// import { Container } from './styles';

function Pagination({perPage, total, paginate}) {

    const pageNumbers = [];

    for(let i=1; i <= Math.ceil(total / perPage); i++)
    {
        pageNumbers.push(i);
    }


  return (
    <ul className="pagination" style={{justifyContent: 'center'}}>
    {pageNumbers.map(page =>(
        <li key={page} className="page-item"><a onClick={(e) => paginate(e, page)} className="page-link" href="#">{page}</a></li>               
    ))}
    </ul>
  );
}

export default Pagination;