import styled from 'styled-components';

export const Container = styled.div`

  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #FFF;
  padding: 8;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  flex-direction: column;
  flex: 1;

  .text-small{
    font-size: 12px!important;
  }

  .footer-logo-admin{
    z-index: 999999;
    bottom: 0;
    background-color: #FFF;
    padding: 8;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  `;

export const Logo = styled.img`
  height: 70px;
  object-fit: contain; 
`;
