import React, { useState, useEffect, useCallback,useRef } from 'react';
import { connect } from 'react-redux';
import ReactDOM from "react-dom";


import { Card } from 'react-bootstrap';
import {TableMobile} from './styles';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import $ from 'jquery';
import * as yup from 'yup';
import { useAlert } from 'react-alert';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';

function Cadastrorequerimento({ history, usuario, parametros, escola, periodo, sistema }) {
  const [dados, setDados] = useState({
    codigo: usuario.codigo,
    escola: periodo.escola,
    ano: periodo.ano,
    seqano: periodo.seqano,
    matric: usuario.matric,
    turma: usuario.turma,
    file: null,
    url: ''
  });

  const alert = useAlert();
  const schema = yup.object().shape({ 
    predis:yup.bool(),
    prefile:yup.bool(), 
    dataoco:yup.string(),       
    codoco: yup.string().required(),
    valor:  yup.string(),    
    hist:   yup.string(),
    discip: yup.string().when('predis', {
      is: true, // alternatively: (val) => val == true
      then: yup.string().required()
    }),
    file: yup.string().when('prefile', {
      is: true, // alternatively: (val) => val == true
      then: yup.string().required()
    })
    
  });
 

  const [disciplinas, setDisciplinas] = useState([]);
  const [modavaqua, setModavaqua] = useState([]);
  const [modavaquaobs, setModavaquaobs] = useState([]);
  const [modavaquares, setModavaquares] = useState([]);
  const [notas, setNotas] = useState(null);
  const [codoco, setCodoco] = useState([]);
  const dataoco = new Date().toLocaleDateString(); 
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [mask, setMask] = useState("");
  const [file, setFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [erros, setErros] = useState(null);
  const inputRef = useRef(null);

  

  const [selecionados, setSelecionados] = useState ({
    dataoco: new Date().toLocaleDateString(),
    predis:0,
    prefile:0,   
    codoco: "",
    valor:"",   
    cpf:"",
    discip: "",
    file: dados.url,
    hist:""

  })
  

  useEffect(() => {
    async function load() {

      

      const result = await api.get('/parametros', {
        params: { codigo: usuario.codigo },
      });

      const codoco_ = await api.get('/requerimento/codoco', {
        params: { codigo: usuario.codigo },
      });
      setCodoco(codoco_.data); 

      const discip = await api.get('/aluno/disciplinas', {
        params: { codigo: usuario.codigo,
                  escola: periodo.escola,
                  ano: periodo.ano,
                  seqano: periodo.seqano,
                  turma: usuario.turma }
      });
      setDisciplinas(discip.data);   

     
    }

    load();
  }, []);

  function inputChange(e){
    if (e.target.name === 'discip'){
     setSelecionados({...selecionados, discip: e.target.value});
    }
    if (e.target.name === 'file'){
      setSelecionados({...selecionados, file: e.target.value});
     }
    if (e.target.name === 'codoco'){
      

      if (e.target.value != "_"){
        var codsel = codoco.find(item => item.codoco === (e.target.value))
        setSelecionados({...selecionados, codoco: codsel.codoco, valor:codsel.valor, predis:parseInt(codsel.predis), prefile:parseInt(codsel.prefile)}) 
      }else{
        setSelecionados({...selecionados, codoco: "", valor:"", predis:0, prefile:0}) 

      }
      
      
      // setSelecionados({...selecionados, valor: codsel.valor});
    }
    if (e.target.name === 'historico'){
      setSelecionados({...selecionados, hist: e.target.value});
     }
    



     
  }
  
  const handlePreview = useCallback((e) => {
    e.preventDefault();
    if (e.target.files.length > 0)
    {
      
      const file_ = e.target.files?.[0];
      
      
      if (!file_) {
        setFile(null);  
        setDados({...dados, url: ''});
        setSelecionados({...selecionados, file: ""});    
      }
      setFile(file_);
      setDados({...dados, url: file_.name});
      setSelecionados({...selecionados, file: file_.name});
    }else{
      setFile(null);
      setDados({...dados, url: ''});
      setSelecionados({...selecionados, file: ""});
    }
    
  });



  async function handleFormSubmit(e) {
    e.preventDefault();

    try {
      setErros(null);
      
      



      const isValid = await schema.isValid(selecionados);
      

      if (!isValid){
        
        setErros('Preencha todos os campos obrigatórios.');
        alert.error("Preencha todos os campos obrigatórios.")
        
      }else{
      
        if (file)
          if (Math.round((file.size / 1024) / 1024, 2) > 20) {
            setErros([{ message: 'O tamanho do arquivo não pode ser maior que 20mb.' }]);
            return false;
          }

        const options = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            setUploadPercentage(percent);
          }
        }

        let postdata = new FormData();
        let fileUploaded = {
          status: 'success',
        };

        if (file)
        {
          postdata.append('file', file);

          postdata.append('codigo', escola.codigo);    
          const resp = await api.post('/file/upload2', postdata, options);
        
          setUploadPercentage(100);

          setTimeout(() => {
            setUploadPercentage(0)
          }, 1000);
          
          

          fileUploaded = resp.data;
        }

        if (fileUploaded.status === 'success')
        {
          const dados_ = {    
            codigo: usuario.codigo,
            escola: periodo.escola,
            matric: usuario.matric,
            cpf: selecionados.cpf,
            datoco: selecionados.datoco,
            codoco: selecionados.codoco,
            linhis: selecionados.hist,
            valor: selecionados.valor,            
            turma: usuario.turma, 
            coddis: selecionados.discip,             
            file: fileUploaded.uri ? fileUploaded.uri : dados.url,
          }
        
          const resulSave = await api.post('/requerimento', dados_);
          
        }
        
        alert.success('Requerimento cadastrado com sucesso!');

        $('#labelFile').text('Selecione o arquivo');
      }
      
     
    } catch (err) {   
      console.log(err);
    }
  }  



  
  return (
    <>
      <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />

      <Card className="card-main">
        <Card.Header className="text-center h3"> Solicitação de Requerimento </Card.Header>
        <Card.Body>
        <form className="text-left">
          <div className="form-row">         
            <div className="form-group col-md-3">
              <p className="alert alert-danger"> Data da ocorrência: <strong className="text-danger font-weight-bold">{dataoco}</strong></p>
            </div>            
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label className="text-danger">Tipo </label>
              <select className="form-control" name="codoco" onChange={inputChange}>
                <option value="_">------</option>
              {codoco.length > 0 && codoco.map((item, index) => (

                <option value={item.codoco} key={index}> {item.desoco}</option>
              ))}
              </select>
              <div className="obrigatorio font-weight-light font-size-1">
                *Caso não encontre o tipo do requerimento desejado, dirija-se à secretaria da instituição.
              </div>
            </div>
            <div className="form-group col-md-3">
              <label>Valor</label>
              <div className="alert alert-danger w-50">R$ {selecionados.valor}</div>
            </div>
          </div>

          {/* <div className="form-row" >
            <div className="form-group col-md-6" >
            <label>CPF </label>
              <CpfCnpj
                className="form-control"
                placeholder=""
                type="tel"
                value={cpfCnpj}
                onChange={(event, type) => {
                  setSelecionados({...selecionados, cpf: event.target.value})
                  setCpfCnpj(event.target.value);
                  setMask(type === "CPF");
                }}
              />
            </div>
          </div> */}
          { selecionados.predis === 1 &&
            <div className="form-row">
              <div className="form-group col-md-6"  >
                <label>Disciplina  <strong className="text-danger">  *Obrigatório para este tipo de requerimento</strong></label>
                <select className="form-control" name="discip" onChange={inputChange}>
                <option value="_">------</option>
                {disciplinas.length > 0 && disciplinas.map((item, index) => (
                  <option value={item.discip} key={index}> {item.desdis}</option>
                ))}
                </select>
              </div>
            </div>
          }
           {selecionados.prefile === 1 &&     
              <div className="form-row" >
                <div className="form-group col-md-9">
                    <label className="">Arquivo <strong className="text-danger">  *Obrigatório para este tipo de requerimento</strong>   </label>
                    <div className="custom-file">
                        <input type="file" ref={inputRef}   onChange={handlePreview} className="custom-file-input" name="file" disabled={uploadPercentage > 0} />
                        <label id="labelFile" className="custom-file-label" >{file ? file.name : 'Selecione o arquivo'}</label>
                    </div>
                </div>
              </div> 
          }
          
          <div className="form-row">
            <div className="form-group col-md-12">
              <label>Histórico</label>
              <textarea className="form-control" rows="4" name="historico" onChange={inputChange} ></textarea>
            </div>
          </div>

          <div className="form-row">
          <div className="form-group col">
              <a href="#!/consultarequerimento" className="btn btn-block btn-danger">Voltar</a>
            </div>
            <div className="form-group col">
              <button className="btn btn-block btn-dark" onClick={handleFormSubmit}>Cadastrar</button>
            </div>
            
            
          </div>
	      </form>
        </Card.Body>
      </Card>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(Cadastrorequerimento)
