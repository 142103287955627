import React, { useState, useEffect } from 'react';
import { Col, Card, Table, Form } from 'react-bootstrap';
import pt from 'date-fns/locale/pt-BR';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import {format} from 'date-fns';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';

import api from '../../../services/api';
import "react-datepicker/dist/react-datepicker.css";
import { Container } from './styles';

import {FaRegEdit, FaUser, FaUsers} from 'react-icons/fa';
import { useAlert } from 'react-alert';

function Agenda({ history, sistema, escola, usuario, periodo }) {
  
  const alert = useAlert();
  const [data,setData] = useState(new Date());
  const [turmas, setTurmas] = useState([]);
  const [listaTurmas, setListaTurmas] = useState([]);
  const [tipoTurma, setTipoTurma] = useState('');
  const [lancamentos, setLancamentos] = useState([]);
  const [lancamentosAgenda, setLancamentosAgenda] = useState([]);

  const CustomDatePciker = React.forwardRef((props, ref) => (
    <button type="button" className="form-control" onClick={props.onClick} >
      {props.value}
    </button>
  ));

  async function loadLancamentos(){
    // console.log(escola, usuario, periodo);
    const resultConteudo = await api.get('/conteudo/data/prof', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        data: format(data, 'yyyy-MM-dd'),
        prof: usuario.guerra,
      },
    });

    const resultAgenda = await api.get('agenda/data', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        data: format(data, 'dd/MM/yyyy'),
        prof: usuario.guerra,
      },
    });

    setLancamentos(resultConteudo.data);
    setLancamentosAgenda(resultAgenda.data);
  }

  // eslint-disable-next-line
  useEffect(() => {loadLancamentos()}, [data]);

  useEffect(() => {
    async function loadTurmas() {
      try {

        let url = '/grade/turmas/diario';

        if (usuario.tipo === 'ADMIN')
          url = '/grade/turmas/diario/admin';

        if (usuario.tipo === 'COORD')
          url = '/grade/turmas/diario/coord';

        const result = await api.get(url, {
          params: {
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,
            guerra: usuario.guerra,
          },
        });

        const turmas_ = result.data;

        setTurmas(turmas_);
        setListaTurmas(turmas_);
        
      } catch (err) {
        console.log(err);
      }
    }

    loadTurmas();

  }, [escola, periodo, usuario, data]);

  useEffect(() => {
    if(tipoTurma !== '')
    {
      const lista = turmas.filter(tur => {return tipoTurma == 2 ? (tur.tipo === tipoTurma || tur.tipo == null) : tur.tipo === tipoTurma});
      setListaTurmas(lista);
    }else{
      setListaTurmas(turmas);
    }

  }, [tipoTurma, turmas]);

  useEffect(() => {
    const lista = turmas.map(item => {
      item.lancamento = false;
      const result = lancamentos.find(lan => item.turma === lan.turma)
      if (result)
        item.lancamento = true;

      return item;
    });

    const listaAgenda = lista.map(item => {
      const result = lancamentosAgenda.find(lan => item.turma === lan.turma)
      if (result)
        item.lancamento = true;

      return item;
    });

    setListaTurmas(listaAgenda);
    
  }, [lancamentos, turmas, lancamentosAgenda]);

  async function setaData(date) {
    setData(date);
  }

  function lancar(item) {
    console.log(item);
    if (item?.codmod?.length > 0)
      history.push('/agendacadastroaluno', {turma: item, dataLancamento: data});
    else
      history.push('/agendacadastroturma', {turma: item, dataLancamento: data});
  }

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Agenda</li>
              </ol>
          </nav>
          <Form>
            <Form.Row>
              <Form.Group as={Col} md={2}>
                <Form.Label>Data</Form.Label>
                <div>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={data}
                    onChange={setaData}
                    customInput={<CustomDatePciker />}
                    locale={pt} />
                </div>
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label>Tipo de Turma:</Form.Label>
                <Form.Control as="select" defaultValue={tipoTurma} onChange={(e) => setTipoTurma(e.target.value)}>
                  <option value="">TODOS</option>
                  <option value="3">INDIVIDUAL</option>
                  <option value="1">TURMA</option>
                  <option value="2">TURMA/DISCIPLINA</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

          </Form>

          <hr />
          
          {sistema.isMobile ? (
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Turmas</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listaTurmas.map((turma, index) => (
                  <tr key={index}>
                    <td >
                        {turma.destur} <br />
                        ({turma.qtd} alunos)
                    </td>
                    <td className="text-center">
                      <FaRegEdit className="button-icon" onClick={() => lancar(turma)} size={20}/>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Turma</th>
                    <th style={{ textAlign: 'center', maxWidth: 40 }}>Qtd. Alunos</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {listaTurmas.map((turma, index) => (
                    <tr key={index} className={turma.lancamento ? 'tem-registro' : ''}>
                      <td >{turma.turma} - {turma.destur} </td>
                      <td style={{ textAlign: 'center' }}>{turma.qtd}</td>
                      <td style={{
                        width: 50,
                        textAlign: 'center',
                      }}>
                        {turma?.tipo > 2 ? (<FaUser size={12}/>) : (<FaUsers size={20}/>)}
                      </td>
                      <td style={{
                        width: 50,
                        textAlign: 'center',
                      }}>
                        <FaRegEdit className="button-icon" onClick={() => lancar(turma)} size={20}/>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(Agenda);