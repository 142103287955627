import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MenuFuncionario from '../../../componentes/MenuFuncinario';
import {FaRegEdit} from 'react-icons/fa';

import api from '../../../services/api';
import { Container, Footer, Item } from './styles';
import { useAlert } from 'react-alert';

function AgendaCadastroAluno({ history, escola, usuario, periodo, location}) {
    
    const alert = useAlert();
    
    // eslint-disable-next-line
    const [lista, setLista] = useState([]);
    // eslint-disable-next-line
    const [dataFormatada, setDataFormatada] = useState(format(location.state.dataLancamento, 'yyyy-MM-dd'));
    const [dataPick, setDataPick] = useState(location.state.dataLancamento);
    const [turma, ] = useState(location.state.turma);
    
    const CustomDatePciker = React.forwardRef((props, ref) => (
        <button type="button" className="form-control" onClick={props.onClick} >
            {props.value}
        </button>
    ));   

    useEffect(() => {
        async function load(){
            setLista([]);

            const resp = await api.get('/agenda/listaalunos/data', {
                params: {
                    codigo: usuario.codigo,
                    escola: periodo.escola,
                    ano: periodo.ano,
                    seqano: periodo.seqano,
                    turma: turma.turma,
                    data: format(location.state.dataLancamento, 'dd/MM/yyyy')
                }
            });
            
            setLista(resp.data);
        }
        load();
    }, [turma, periodo, usuario, dataFormatada, location.state]);

   
    async function changeDate(date){
        setDataPick(date);
        setDataFormatada(format(date, 'yyyy-MM-dd'));
    }

    function lancar(item){
        history.push('/agendacadastroalunolancamento', {aluno: item, dataLancamento: dataPick, turma})
    }

    return (
        <Container>
            <MenuFuncionario history={history} />
            <Card className="card-main">
                <Card.Body>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page"><Link to="/agenda">Agenda</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Cadastro por Aluno</li>
                        </ol>
                    </nav>
                    <form className="p-3">
                        <div className="row ">
                            <div className="col-md-12">
                                <label className="col-form-label bold col-md-2">Turma: </label>
                                <label >{turma.destur}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <label className="col-form-label bold col-md-2">Data:</label>
                                <DatePicker
                                    name="date"
                                    dateFormat="dd/MM/yyyy"
                                    selected={dataPick}
                                    onChange={changeDate}
                                    customInput={<CustomDatePciker />}
                                    locale={pt} />
                            </div>
                        </div>
                        <hr/>
                        {lista.map((item, index) => (
                            <Item key={index} className={item.reg > 0 ? 'tem-registro' : ''}>
                                <div className="agenda-nome">{item.nome}</div>
                                <FaRegEdit className="button-icon" onClick={() => lancar(item)} size={20}/>
                            </Item>
                        ))}

                        <Footer>                    
                            <button type="button" className="btn btn-dark" onClick={()=> history.push('/agenda')}>Voltar</button>
                        </Footer>
                    </form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default connect(state => ({
    sistema: state.sistema,
    escola: state.escola,
    usuario: state.usuario,
    periodo: state.periodo,
}))(AgendaCadastroAluno);