import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import Footer from '../../../componentes/Footer/index';
import api from '../../../services/api';
import {MdAttachFile, MdPictureAsPdf, MdFileUpload} from 'react-icons/md';
import {BsTrash} from 'react-icons/bs';
import MenuFuncinario from '../../../componentes/MenuFuncinario';
import ReactTooltip from 'react-tooltip';

function Requerimentos({ history, usuario, parametros, escola, periodo, sistema }) {
  const [dados, setDados] = useState({
    codigo: usuario.codigo,
    escola: periodo.escola,
    ano: periodo.ano,
    seqano: periodo.seqano,
    matric: usuario.matric,
    turma: usuario.turma
  });
  const [lista, setlista] = useState([]);
  const [listaGeral, setListaGeral] = useState([]);
  const [nreqweb, setNReqWeb] = useState('');
  const [filtro, setFiltro] = useState({
    nome: '',
    nreqweb: ''
  });
  
  async function load() {
    const dados = await api.get('/requerimentos', {params: { codigo: usuario.codigo,}});
    setlista(dados.data);
    setListaGeral(dados.data);
  }
  useEffect(() => {
    load();
  }, []);

  const inputRef = useRef(null);

  const handleOpenFileInput = (event, nreqweb) => {
    event.preventDefault();
    setNReqWeb(nreqweb);
    inputRef.current.click(event);
  };

  async function addImagem(e){
    
    if (e.target.files.length > 0)
    {
        let postdata = new FormData();
        postdata.append('file', e.target.files[0]);
        postdata.append('codigo', usuario.codigo);
        postdata.append('nreqweb', nreqweb);
        postdata.append('enviado_por', usuario.guerra);
        const resp = await api.post('/requerimento/upload', postdata);
        console.log(resp.data);
        
        load();
    }
  }

  async function removeImagem(item){
    const result = await api.post('/requerimento/deletefile', { 
      codigo: usuario.codigo, 
      nreqweb: item.nreqweb, 
      file: item.doc,
      log: 'Removido por ' + usuario.guerra
    });
    console.log(result.data);
    const requerimentos = lista.map(req => {
      if (req.nreqweb == item.nreqweb)
        req.doc = null;

      return req;
    });

    setlista(requerimentos);
  }

  useEffect(() => {
    const regs = listaGeral.filter(i => i.nome.includes(filtro.nome.toLocaleUpperCase()) && i.nreqweb.includes(filtro.nreqweb));
    setlista(regs);
  }, [filtro])

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <MenuFuncinario history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />

      <Card className="card-main">
        <Card.Header className="text-center h3">Requerimentos</Card.Header>
        <Card.Body>
          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}>

            <form>
              <div className="form-row">
                <div className="form-group col-ms-4 col-md-3 col-lg-2">
                  <label>Nº Ocorrência</label>
                  <input value={filtro.nreqweb} onChange={e => setFiltro({...filtro, nreqweb: e.target.value})} type="text" className="form-control"/>
                </div>
                <div className="form-group col-ms-8 col-md-4 col-lg-4">
                  <label>Aluno</label>
                  <input value={filtro.nome} onChange={e => setFiltro({...filtro, nome: e.target.value})}type="text" className="form-control"/>
                </div>
              </div>              
            </form> 
            
            	<table width="100%" className="table table-striped table-bordered">
                <thead>
                  <tr className="active">
                    <th className="text-center">Nº</th>
                    <th className="text-center">Aluno</th>
                    <th className="text-left">Ocorrência</th>
                    <th className="text-center">Data</th>
                    <th className="text-left">Histórico</th>
                    <th style={{width: 80, textAlign: 'center'}}>Anexo do Aluno</th>
                    <th style={{width: 80, textAlign: 'center'}}>Anexar Documento Retorno</th>
                  </tr>
                </thead>
                <tbody>
                  {lista.length > 0 && lista.map((item, index) => (

                    <tr key={index} >
                      <td className="text-center">{item.nreqweb}</td>
                      <td>{item.nome}</td>
                      <td>{item.ocorrencia}</td>
                      <td className="text-center">{item.datoco}</td>
                      <td>{item.linhis}</td>
                      <td className="text-center">
                        {item.file && <a href={item.file} target="_blank"><MdAttachFile size={24}/></a> } 
                      </td>
                      <td className="text-center">
                        {item.doc ? (
                          <>
                            <a href={item.doc} target="_blank" data-type="info" data-html={true} data-tip={`Enviado por <strong>${item.enviado_por}</strong>`}>
                              <MdPictureAsPdf size={24} />
                            </a>
                            <BsTrash onClick={(e) => removeImagem(item)} size={24} style={{marginLeft: 10, color: 'red', cursor: 'pointer'}}/>
                          </>
                        ) :(
                          <>
                            <input  ref={inputRef} type="file" onChange={addImagem} style={{display: 'none',}}/>
                            <MdFileUpload style={{color: '#228B22', cursor: 'pointer'}} size={24} onClick={(e) => handleOpenFileInput(e, item.nreqweb)}/>
                          </>
                        )} 
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            <br />

          </div>
        <br/>
        </Card.Body>
      </Card>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(Requerimentos)
