import styled from 'styled-components';

export const Container = styled.div`
  input[type="checkbox"] {
    margin-right: 4px;
  }

  .form-group {
      flex: 1;
      margin-left: 10px;
  }

  textarea {
    width: 100%!important;
  }

  button {
    margin-left: 10px;
  }
`;

export const Footer = styled.div`

    margin-top: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

`;

export const Item = styled.div`

    display: flex;
    flex: 1;
    padding: 5px;
    margin-bottom: 3px;
    align-items: center;
`;

export const Cabecalho = styled.div`
    display: flex;
    flex: 1;
    padding: 5px;
    font-weight: bold;
    background-color: rgba(255,240,245);
`;