import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore} from 'date-fns';

import * as yup from 'yup';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';
import api from '../../../services/api';
import { Container, TableNota, Botoes } from './styles';
import { useAlert } from 'react-alert';
import variaveis from '../../../services/variaveis';

function FrequenciaLancamentoMes({ history, sistema, escola, usuario, periodo }) {
  const alert = useAlert();

  const schema = yup.object({
    turma: yup.string().min(1).required(),
    disciplina: yup.string().min(1).required(),
    materia: yup.string(),
    data: yup.string().min(9).required(),
    aulas: yup.string().min(1).required(),
  });

  const date = startOfDay(new Date());
  const [datalimite, setDataLimite] = useState('');
  const [passouDataLimite, setPassouDataLimite] = useState(false);
  
  const [lista, setLista] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState(null);
  const [turmaDetalhe, setTurmaDetalhe] = useState(null);
  const [disciplinas, setDisciplinas] = useState([]);  
  const [disciplina, setDisciplina] = useState(null);  
  const [materias, setMaterias] = useState([]); 
  const [materia, setMateria] = useState(null); 

  const [dados, setDados] = useState({
    turma: '',
    disciplina: '',
    materia: '',
    mes: format(date, 'M'),
    aulas: 1,
  });

  // eslint-disable-next-line
  const [dataPick, setDataPick] = useState(startOfDay(date));
  
  const CustomDatePciker = React.forwardRef((props, ref) => (
      <button type="button" className="form-control" onClick={props.onClick} >
          {props.value}
      </button>
  ));  

  function getDataLimite(detalhe) {

    let datalimite = date;
    switch (date.getMonth()+1) {
      case 1:
          datalimite = detalhe.dat01;
        break;
    
      case 2:
          datalimite = detalhe.dat02;
        break;

      case 3:
          datalimite = detalhe.dat03;
        break;
      
      case 4:
          datalimite = detalhe.dat04;
        break;
      
      case 5:
          datalimite = detalhe.dat05;
        break;

      case 6:
          datalimite = detalhe.dat06;
        break;
    
      case 7:
          datalimite = detalhe.dat07;
        break;
        
      case 8:
          datalimite = detalhe.dat08;
        break;
      
      case 9:
          datalimite = detalhe.dat09;
        break;

      case 10:
          datalimite = detalhe.dat10;
        break;

      case 11:
          datalimite = detalhe.dat11;
        break;

      case 12:
          datalimite = detalhe.dat12;
        break;

      default:
        break;      
    }

    return datalimite ? startOfDay(new Date(datalimite + ' 00:00:00')) : null;
  }

  // carrega turmas
  useEffect(() => { 
    async function load(){
      let url = '/faltasmes/turmas/professor';

      if (usuario.tipo === 'ADMIN')
        url = '/faltasmes/turmas/admin';

      if (usuario.tipo === 'COORD')
        url = '/faltasmes/turmas/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });      
      setTurmas(result.data);
    }

    load();
  }, [usuario, escola, periodo])

  // carrega disciplinas
  useEffect(() => {     
    async function load(){
      let url = '/faltasmes/disciplinas/professor';

      if (usuario.tipo === 'ADMIN')
        url = '/faltasmes/disciplinas/todas';

      if (usuario.tipo === 'COORD')
        url = '/faltasmes/disciplinas/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: turma.turma,
        },
      }); 
      setDisciplinas(result.data);
    }

    if (turma)
      load();

  }, [usuario, escola, periodo, turma])

  // carrega lista de alunos
  useEffect(() => {
    setLista([]);
    async function load() {

      const detalhe = await api.get('/faltasmes/datalimite', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          materia: dados.materia,
          mes: dados.mes
        },
      });

      if (detalhe.data)
      {
        const datalimite =  getDataLimite(detalhe.data);
        if (datalimite)
        {
          const testeDataLimite = isBefore(datalimite, date);
          setPassouDataLimite(testeDataLimite);
          setDataLimite(format(datalimite, 'dd/MM/yyyy'));      
        }
        setTurmaDetalhe(detalhe.data);
      }
      const frequencia_ = await api.get('/faltasmes/listaalunos', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          materia: dados.materia,
          data: dados.data,
          mes: dados.mes
        },
      });
      setLista(frequencia_.data);
      if (frequencia_.data.length > 0)
      {
        setDados({...dados, aulas: frequencia_.data[0].qtdaulas});
      }
    }

    if (dados.turma && dados.disciplina)
      load();
    else
    {
      setLista([]);
      setTurmaDetalhe([]);
      setDataLimite('');
      setPassouDataLimite(false);
    }

  }, [dados.disciplina, dados.data, dados.turma, dados.materia, dados.mes]);


  function inputChange(e){
    if (e.target.name === 'turma'){
      const reg = turmas.find(t => t.turma === e.target.value)
      setTurma(reg);
      setDados({...dados, turma: e.target.value, disciplina: ''});
      setDisciplina(null);
    }

    if (e.target.name === 'disciplina')
      setDados({...dados, disciplina: e.target.value});
    
    if (e.target.name === 'mes')
      setDados({...dados, mes: e.target.value});
    
  }


  function handleInputFaltaLostFocus(e){
    let {id, value} = e.target;

    const lista_ = lista.map((item, index) => {
      if (index == id)
        item.faltas = Number(value) > 0 ? value : 0;

      return item;
    });
    
    setLista(lista_);
  }

  async function gravar(){
    if (!schema.isValid())
    {
      alert.warning('Preencha os campos obrigatórios');
    }else{

      let dados_ = dados;
      dados_.lista = lista;
      dados_.codigo= escola.codigo;
      dados_.escola= periodo.escola;
      dados_.ano= periodo.ano;
      dados_.seqano= periodo.seqano;
      dados_.guerra= usuario.guerra;

      const result = await api.post('/faltasmes/gravar', dados_);
      const log = await api.post('/faltasmes/gravar/log', dados_);
      if (result.data.success)
      {
        alert.success('Dados gravados com sucesso!');
      }
    }

  }

  function handleInputFalta(e){
    const {id, value} = e.target;
    
    const lista_ = lista.map((item, index) => {
      if (index == id)
        item.faltas = value;
      
      return item;
    });
    setLista(lista_);
  }

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Lançamento de Faltas / Mês</li>
              </ol>
          </nav>          
          
          <hr />          

          <form>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Turma</label>
                <select className="form-control" name="turma" defaultValue={dados.turma} onChange={inputChange} >
                  <option value="">...</option>
                  {turmas && turmas.map(item => 
                    <option key={item.turma} value={item.turma}>{item.destur}</option>
                  )}                
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-9">
                <label>Disciplina</label>
                <select className="form-control" value={dados.disciplina} name="disciplina" onChange={inputChange}>
                  <option value="">...</option>
                  {disciplinas && disciplinas.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              {materias.length > 0 && (
              <div className="form-group col-md-3">
                <label>Matéria</label>
                <select className="form-control" name="materia" onChange={inputChange}>
                  <option value="">...</option>
                  {materias.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              )}              
            </div>  

            <div className="form-row">
                <div className="form-group col-md-3">
                  <label>Mês</label>
                  <select className="form-control" value={dados.mes} name="mes" onChange={inputChange}>
                    {variaveis.meses.map(item => 
                      <option key={item.mes} value={item.mes}>{item.des}</option>
                    )}                
                  </select>
                </div>
            </div>          

            <hr />
            {passouDataLimite ? (
              <div className="alert alert-danger" role="alert">
                Prazo para lançamento das notas expirou em <strong>({datalimite})</strong>.
              </div>
            ): (
              datalimite && (
                <div className="alert alert-success" role="alert">
                  Prazo para lançamento das notas expira em <strong>({datalimite})</strong>.
                </div>
              )
            )}

            {sistema.isMobile ? (
              <TableNota className="table">
                <thead className="thead-dark">             
                  <tr>
                    <th className="column-nome header-sort">Nome</th>
                    <th className="column-faltas">Faltas/Mês</th>
                  </tr>
                </thead>
                <tbody>  
                  {lista.length > 0 ? (
                    lista.map((item, index) => 
                      <tr key={index} className="line-focus">
                        <td>{item.nome}</td>
                        <td className="column-faltas">
                          <input type="number" autoComplete="off" min={0} maxLength={2} id={index} value={item.faltas} onChange={handleInputFalta} onBlur={handleInputFaltaLostFocus} className="input-faltas-mes" disabled={passouDataLimite}/>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr key={1}>
                      <td className="linha-sem-dados" colSpan={4}>Nenhuma informação</td>
                    </tr>
                    )}                                   
                </tbody>
              </TableNota>
            ) : (
              <TableNota className="table">
                <thead className="thead-dark">             
                  <tr>
                    {/* <th className="column-ord header-sort">Ord.</th> */}
                    <th className="column-matric header-sort">Matrícula</th>
                    <th className="column-nome header-sort">Nome</th>
                    <th className="column-faltas">Faltas/Mês</th>
                  </tr>
                </thead>
                <tbody>  
                  {lista.length > 0 ? (
                    lista.map((item, index) => 
                      <tr key={index} className="line-focus">
                        <td>{item.matric}</td>
                        <td>{item.nome}</td>
                        <td className="column-faltas">
                          <input type="number" autoComplete="off" min={0} maxLength={2} id={index} value={item.faltas} onChange={handleInputFalta} onBlur={handleInputFaltaLostFocus} className="input-faltas-mes" disabled={passouDataLimite}/>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr key={1}>
                      <td className="linha-sem-dados" colSpan={4}>Nenhuma informação</td>
                    </tr>
                    )}                                   
                </tbody>
              </TableNota>
            )}
          </form>
          
          <hr />

          {(lista.length > 0 && !passouDataLimite)&& (
            <Botoes>    
              <button type="button" className="btn btn-success" onClick={gravar}>Gravar</button>            
            </Botoes>
          )}
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(FrequenciaLancamentoMes);