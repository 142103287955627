import styled from 'styled-components';

export const TableMobile = styled.table`
  th {
    font-size: 10px;
    padding: 1px;
  }

  td {
    font-size: 10px;
    padding: 1px;  
    font-weight: bold;
  }
`;
