import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useAlert } from 'react-alert';
import { Card } from 'react-bootstrap';
import pt from 'date-fns/locale/pt-BR';
import DatePicker from 'react-datepicker';
import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';
import { Link, useParams } from 'react-router-dom';
import format from 'date-fns/format';
import startOfDay from 'date-fns/esm/fp/startOfDay/index.js';
import { addDays } from 'date-fns/esm';

function Reserva({ history, usuario, parametros, escola, periodo }) {
  const params = useParams();
  const alert = useAlert();
  
  const CustomDatePciker = React.forwardRef((props, ref) => (
    <button type="button" className="form-control" onClick={props.onClick} >
      {props.value}
    </button>
  ));

  const [reserva, setReserva] = useState({
    codigo: escola.codigo,
    data: new Date(),
    dataf: format(new Date(), 'dd/MM/yyyy'),
    datamysql: format(new Date(), 'yyyy-MM-dd'),
    numobr: params.numobr,
    tipobr: params.tipobr,
    escola: periodo.escola,
    ano: periodo.ano,
    seqano: periodo.seqano,
    matric: usuario.matric,
  });

  const [dataatual, setDataAtual] = useState(startOfDay(new Date()));
  const [dataminima, setDataminima] = useState(new Date());
  const [acervo, setAcervo] = useState([]);
  const [reservas, setReservas] = useState([]);
  const [livro, setLivro] = useState(null);
  const [deixagravar, setDeixagravar] = useState(false);

  useEffect(() => {
    setReserva({
      ...reserva, 
      dataf: format(reserva.data, 'dd/MM/yyyy'),
      datamysql: format(reserva.data, 'yyyy-MM-dd'),
    })
  }, [reserva.data]);

  useEffect(() => {
    const dataminima = addDays(dataatual, parseInt(parametros.aluantr));

    async function load() {
      const livro_ = await api.get('/biblioteca/detalhe', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          numobr: params.numobr,
          tipobr: params.tipobr
        },
      });

      const acervo_ = await api.get('/biblioteca/acervo', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          numobr: params.numobr,
          tipobr: params.tipobr
        },
      });

      const reser = await api.get('/biblioteca/reserva', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          numobr: params.numobr,
          tipobr: params.tipobr,
        },
      });
  
      setReservas(reser.data);
      setAcervo(acervo_.data);
      setLivro(livro_.data);
      setDataminima(dataminima);
      setReserva({...reserva, livro: livro_.data, data: dataminima});
    };

    load();
  }, [])

  async function setaData(date) {
    setReserva({...reserva, data: date});
  }

  async function reservar(e){
    e.preventDefault();

    const reserva_ = await api.post('/biblioteca', reserva);
    

    alert.success("Reserva efetuada com sucesso!");
    history.replace('/minhasreservas');
  }

  return (
    <>
    <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />
    <Card className="card-main">
      <Card.Header className="text-center h3">Acervo da Biblioteca - Reserva</Card.Header>
      <Card.Body> 
        {livro && (
         <form className="form">

          <div className="form-row">
            <div className="form-group col-md-8">
              <label>LIVRO</label>
              <input type="text" className="form-control" defaultValue={livro.titulo} disabled={true}/>
            </div>
          </div>


          <div className="form-row">
            <div className="form-group col-md-12">
                <span style={{fontStyle: 'italic', color: 'red'}}>Data mínima de reserva permitido para este livro é {format(dataminima, 'dd/MM/yyyy')}</span>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-3">
              <label>RESERVAR PARA</label>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                minDate={dataminima}
                selected={reserva.data}
                onChange={setaData}
                customInput={<CustomDatePciker />}
                locale={pt} />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-12">
              <button className="btn btn-primary col-lg-2 col-md-3 col-sm-5 mr-2 mb-2" onClick={reservar} disabled={deixagravar}>Gravar Reserva</button>
              <a href="/biblioteca" className="btn col-md-2 col-md-3 col-sm-5 btn-danger mb-2">Voltar</a>
            </div>
          </div>

          <hr />

          <h4>Exemplares</h4>
          <table width="100%" className="table table-bordered" >
            <thead>
              <tr >
                <th width="12%" className="text-center">Id Tombo</th>
                <th width="8%" className="text-center">Vol.</th>
                <th width="4%" className="text-center">Ex.</th>
                <th width="11%" className="text-center">Sit.</th>
                <th width="11%" className="text-center">Só Consulta</th>
                <th width="12%" className="text-center">Previsão de Devolução</th>
              </tr>
            </thead>
            <tbody>
              {acervo.length > 0 ? acervo.map((acer, index) => (
                <tr key={index}>
                  <td align="center">{acer.idtombo}</td>
                  <td align="center">{acer.vol}</td>
                  <td align="center">{acer.ex}</td>
                  {acer.sit === 'D' && (
                    <td align="center">Dispon&iacute;vel</td>
                  )}
                  {acer.sit === 'DE' && (
                    <td align="center" >Emprestado</td>
                  )}
                  {acer.sit === 'B' && (
                    <td align="center">Baixado</td>									   
                  )}

                  {acer.socon === '0' ? (
                    <td align="center">NÃO</td>
                  ) : (
                    <td align="center">SIM</td>
                  )}

                  <td align="center">{acer.dataprevista}</td>
                </tr>
              )) : (
                <tr >
                  <td colSpan="9" className="text-center">Nenhum Exemplar encontrado.</td>
                </tr>
              )}
            </tbody>
          </table>
          <br/><br/>
          <h4>Reservas</h4>
          <table width="100%" className="table table-bordered" >
            <thead>
            <tr >
              <th align="right">Matrícula</th>
              <th align="center">Data Reservada</th>
            </tr>
            </thead>
            <tbody>     
            {reservas.length > 0 ? reservas.map((res, index) => (
              <tr key={index}>
                <td align="center">{res.matricula}</td>
                <td align="center">{res.datareserva}</td>
              </tr>
            )) : (
              <tr>
                <td colSpan="2" className="text-center">Nenhuma Reserva encontrada.</td>
              </tr>
            )}
            </tbody>
          </table>
        </form>
        )}
      </Card.Body>
    </Card>
    <Footer />

    </>
  );
}


export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros
}))(Reserva);