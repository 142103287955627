import React, { useState, useEffect} from 'react';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { Card } from 'react-bootstrap';

import MenuFuncionario from '../../../componentes/MenuFuncinario';

import api from '../../../services/api';
import { Container, Footer } from './styles';
import { Link } from 'react-router-dom';
import { useAlert } from 'react-alert';
import apiNotificacao from '../../../services/apiNotificacao';

function AgendaCadastroTurma({ history, escola, usuario, periodo, location}) {
  
    const alert = useAlert();
    
    // eslint-disable-next-line
    const [disciplinas, setDisciplinas] = useState([]);
    // eslint-disable-next-line
    const [dataFormatada, setDataFormatada] = useState(format(location.state.dataLancamento, 'yyyy-MM-dd'));
    const [dataPick, setDataPick] = useState(location.state.dataLancamento);
    const [turma, ] = useState(location.state.turma);
    
    const CustomDatePciker = React.forwardRef((props, ref) => (
        <button type="button" className="form-control" onClick={props.onClick} >
            {props.value}
        </button>
    ));   

    useEffect(() => {
        async function load(){
            setDisciplinas([]);

            let url = '/conteudoagenda/data';

            if (usuario.tipo === 'ADMIN')
            url = '/conteudo/admin/data';

            if (usuario.tipo === 'COORD')
            url = '/conteudo/coord/data';

            const resp = await api.get(url, {
                params: {
                    codigo: usuario.codigo,
                    escola: periodo.escola,
                    ano: periodo.ano,
                    seqano: periodo.seqano,
                    prof: usuario.guerra,
                    turma: turma.turma,
                    data: dataFormatada
                }
            });

            
            setDisciplinas(resp.data);
        }
        load();
    }, [turma, periodo, usuario, dataFormatada]);

   
    async function changeDate(date){
        setDataPick(date);
        setDataFormatada(format(date, 'yyyy-MM-dd'));
    }

    async function handleSubmit() {
        const postData = { 
            codigo: escola.codigo,
            ...turma, 
            disciplinas, 
            data: dataFormatada,
            ...periodo,
            guerra: usuario.guerra,
         };

        const res = await api.post('/conteudo/save', postData);
        const tokens = [];
        res.data.tokens.forEach(i => {
            tokens.push(i.token);
        })

        apiNotificacao.post('send/conteudo', {        
            list: tokens,
            title: 'Atenção',
            body: 'Novo conteúdo cadastrado.' 
        });
        
        alert.success('Dados salvos com sucesso!');
        history.push('/agenda');
    }

    function handleChangeObs(e){
        const result = disciplinas.map((dis, index) => {
            if (e.target.id === index.toString())
            {
                dis.obs = e.target.value;
            }

            return dis;
        });
        setDisciplinas(result);
    }

    function handleChangeQtd(e){
        const result = disciplinas.map((dis, index) => {
            if (e.target.id === index.toString())
            {
                dis.qtdaulas = e.target.value;
            }

            return dis;
        });
        setDisciplinas(result);
    }

    return (
        <Container>
            <MenuFuncionario history={history} />
            <Card className="card-main">
                <Card.Body>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item" aria-current="page"><Link to="/agenda">Agenda</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Cadastro por Turma</li>
                        </ol>
                    </nav>
                    <form className="p-3">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label >Turma: </label>
                                <label className="form-control">{turma.destur}</label>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label >Data:</label>
                                <div className="col-md-4 pl-0">
                                <DatePicker
                                    name="date"
                                    dateFormat="dd/MM/yyyy"
                                    selected={dataPick}
                                    onChange={changeDate}
                                    customInput={<CustomDatePciker />}                                                                        
                                    locale={pt} />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        {disciplinas.map((dis, index) => (
                            <div className="row borderBottom" key={index}>
                                <div className="col-md-10">
                                    <label className="col-form-label bold">{dis.desdis}</label>
                                    <textarea id={index} name="obs" defaultValue={dis.obs} className="form-control" rows={6} onChange={handleChangeObs}/>
                                </div>
                                <div className="col-md-2">
                                    <label className="col-form-label bold">Qtd Aulas:</label>
                                    <input id={index} name="qtdaulas" type="text" defaultValue={dis.qtdaulas} className="form-control" onChange={handleChangeQtd}/>
                                </div>
                            </div>
                        ))}

                        <Footer>                    
                            <button type="button" className="btn btn-success" onClick={() => handleSubmit()}>Gravar</button>
                            <button type="button" className="btn btn-dark" onClick={()=> history.push('/agenda')}>Voltar</button>
                        </Footer>
                    </form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default connect(state => ({
    sistema: state.sistema,
    escola: state.escola,
    usuario: state.usuario,
    periodo: state.periodo,
}))(AgendaCadastroTurma);