import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 70px!important;
  
  .item-exp {
    display: flex;
    align-items: center;

    span {
      margin-left: 4px;
    }
  }

  
`;
