import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Login from './pages/Login/index';
import HomeAluno from './pages/Aluno/Home';
import HomeFuncionario from './pages/Funcionario/Home';
import Periodos from './pages/Periodos';
import Agenda from './pages/Funcionario/Agenda/';
import AgendaCadastroTurma from './pages/Funcionario/AgendaCadastroTurma';
import AgendaCadastroAluno from './pages/Funcionario/AgendaCadastroAluno';
import AgendaCadastroAlunoLancamento from './pages/Funcionario/AgendaCadastroAlunoLancamento';
import AvaQualitativa from './pages/Funcionario/AvaQualitativa';
import NotasLancamento from "./pages/Funcionario/NotasLancamento";
import NotasLancamentoTec from "./pages/Funcionario/NotasLancamentoTec";
import TransferenciaNotas from "./pages/Funcionario/TransferenciaNotas";
import FrequenciaLancamentoDia from './pages/Funcionario/FrequenciaLancamentoDia';
import FrequenciaLancamentoMes from './pages/Funcionario/FrequenciaLancamentoMes';
import FrequenciaConsulta from './pages/Funcionario/FrequenciaConsulta';
import Arquivos from "./pages/Funcionario/Arquivos";
import ArquivosCadastro from "./pages/Funcionario/ArquivosCadastro";
import NotasConsulta from "./pages/Funcionario/NotasConsulta";
import ControleLancamentos from "./pages/Funcionario/ControleLancamentos";
import Relatorios from "./pages/Funcionario/Relatorios";
import AlterarSenha from "./pages/Funcionario/AlterarSenha";

import Boletim from "./pages/Aluno/Boletim";
import FichaFinanceira from "./pages/Aluno/FichaFinanceira";
import Conteudo from "./pages/Aluno/Conteudo";
import FaltasMes from "./pages/Aluno/FaltasMes";

import HomeAdmin from "./pages/Administrador/HomeAdmin";
import Sincronizacao from './pages/Administrador/Sincronizacao';

import Aulas from "./pages/Funcionario/Aulas";
import AulasCadastro from "./pages/Funcionario/AulasCadastro";

import Page404 from "./pages/Page404";
import FrequenciaLancamentoGlobal from "./pages/Funcionario/FrequenciaLancamentoGlobal";
import ConsultaAcervo from "./pages/Aluno/ConsultaAcervo";
import Reserva from "./pages/Aluno/Reserva";
import MinhasReservas from "./pages/Aluno/MinhasReservas";
import BoletimInstrumento from "./pages/Aluno/BoletimInstrumento";
import AvaliacaoQualitativa from "./pages/Aluno/AvaliacaoQualitativa";
import Emprestimos from "./pages/Aluno/Emprestimos";
import ConsultaRequerimento from "./pages/Aluno/ConsultaRequerimento";
import CadastroRequerimento from "./pages/Aluno/CadastroRequerimento";
import Mensagem from "./pages/Funcionario/Mensagem";
import Requerimentos from "./pages/Funcionario/Requerimentos";
import ArquivosAluno from "./pages/Aluno/ArquivosAluno";
import Mural from "./pages/Funcionario/Mural";
import MuralGerenciar from "./pages/Funcionario/MuralGerenciar";
import MensagemGerenciar from "./pages/Funcionario/MensagemGerenciar";
import Manutencao from "./pages/Manutencao";
import LiberaBoletimTurma from "./pages/Funcionario/LiberaBoletimTurma";
import ConsultaMensagemInapimplentes from "./pages/Funcionario/ConsultaMensagemInapimplentes";

const PrivateRoute = ({ component: Component, ...rest }) => {

  let escola = localStorage.getItem('maisescola.escola');
  escola = JSON.parse(escola);

  return (
    <Route {...rest} render={props => (
      localStorage.getItem('maisescola.usuario') ? (
        <Component {...props} />
      ) : (
          <Redirect to={`/${escola.cnpj}`} />
        )
    )} />
  )
}

const Routes = () => (
  <BrowserRouter>
    <Switch>
      {/* <PrivateRoute path="/" component={Manutencao} /> */}
      <PrivateRoute path="/homealuno" component={HomeAluno} />
      <PrivateRoute path="/homefuncionario" component={HomeFuncionario} />
      <PrivateRoute path="/periodos" component={Periodos} />
      <PrivateRoute path="/agenda" component={Agenda} />
      <PrivateRoute path="/agendacadastroturma" component={AgendaCadastroTurma} />
      <PrivateRoute path="/agendacadastroaluno" component={AgendaCadastroAluno} />
      <PrivateRoute path="/agendacadastroalunolancamento" component={AgendaCadastroAlunoLancamento} />
      
      <PrivateRoute path="/avaqua" component={AvaQualitativa} />
      <PrivateRoute path="/notaslancamento" component={NotasLancamento} />
      <PrivateRoute path="/notaslancamentotec" component={NotasLancamentoTec} />
      <PrivateRoute path="/transferencianotas" component={TransferenciaNotas} />
      <PrivateRoute path="/notasconsulta" component={NotasConsulta} />
      <PrivateRoute path="/controlelancamentos" component={ControleLancamentos} />
      <PrivateRoute path="/arquivosaluno" component={ArquivosAluno} />
      
      <PrivateRoute path="/frequenciadialancamento" component={FrequenciaLancamentoDia} />
      <PrivateRoute path="/frequenciameslancamento" component={FrequenciaLancamentoMes} />
      <PrivateRoute path="/frequenciaconsulta" component={FrequenciaConsulta} />
      <PrivateRoute path="/arquivos" component={Arquivos} />
      <PrivateRoute path="/arquivoscadastro" component={ArquivosCadastro} />
      <PrivateRoute path="/relatorios" component={Relatorios} />
      <PrivateRoute path="/alterarsenha" component={AlterarSenha} />
      <PrivateRoute path="/requerimentos" component={Requerimentos} />
      
      <PrivateRoute path="/aulas" component={Aulas} />
      <PrivateRoute path="/aulascadastro" component={AulasCadastro} />
      
      <PrivateRoute path="/avaliacaoqualitativa" component={AvaliacaoQualitativa} />
      <PrivateRoute path="/boletim" component={Boletim} />
      <PrivateRoute path="/instrumentos" component={BoletimInstrumento} />
      <PrivateRoute path="/faltasmes" component={FaltasMes} />
      <PrivateRoute path="/fichafinanceira" component={FichaFinanceira} />
      <PrivateRoute path="/conteudo" component={Conteudo} />
      <PrivateRoute path="/frequenciagloballancamento" component={FrequenciaLancamentoGlobal} />
      <PrivateRoute path="/biblioteca" component={ConsultaAcervo} />
      <PrivateRoute path="/emprestimos" component={Emprestimos} />
      <PrivateRoute path="/minhasreservas" component={MinhasReservas} />
      <PrivateRoute path="/reserva/:numobr/:tipobr" component={Reserva} />
      <PrivateRoute path="/consultarequerimento" component={ConsultaRequerimento} />
      <PrivateRoute path="/cadastrorequerimento" component={CadastroRequerimento} />
      
      <Route path="/inadimplentes/:codigo?/:escola?/:ano?/:seqano?" component={ConsultaMensagemInapimplentes} />
      <Route path="/mensagem/:codigo?/:escola?/:ano?/:seqano?" component={MensagemGerenciar} />
      <Route path="/mensagemenviar/:codigo?/:escola?/:ano?/:seqano?" component={Mensagem} />
      <Route path="/mural/:codigo?/:escola?/:ano?/:seqano?" component={MuralGerenciar} />
      <Route path="/muralenviar/:codigo?/:escola?/:ano?/:seqano?" component={Mural} />
      <Route path="/liberaboletim/:codigo?/:escola?/:ano?/:seqano?" component={LiberaBoletimTurma} />

      <Route path="/admin/:user?" component={HomeAdmin} />
      <Route path="/db/:user" component={Sincronizacao} />

      <Route path="/404" component={Page404} />
      <Route path="/:id?" component={Login} />

    </Switch>
  </BrowserRouter>
);

export default Routes;
