import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const LoadingIndicator = props => {
  return (
    <div id="loadingIncicator" style={{ display: 'none', }}>
      <div className="spinner-border text-light" style={{width: '4rem', height: '4rem',}} role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

ReactDOM.render(
  <>
    <App />
    <LoadingIndicator />
  </>,
  document.getElementById('root'));
