import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';
import {format, startOfDay, isBefore} from 'date-fns';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';
import api from '../../../services/api';
import { Container, Botoes } from './styles';

import $ from 'jquery';
import * as yup from 'yup';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useAlert } from 'react-alert';

function ArquivosCadastro({ history, escola, usuario, periodo }) {

  const alert = useAlert();
  const schema = yup.object().shape({
    escola: yup.string(),
    ano: yup.string(),
    seqano: yup.string(),
    turma: yup.string().required(), 
    discip: yup.string().required(), 
    materia: yup.string(), 
    tipo: yup.string().required(), 
    titulo: yup.string().required(), 
    detalhes: yup.string(), 
    extensao: yup.string(), 
    url: yup.string().required()
  });

  const inputRef = useRef(null);

  const date = startOfDay(new Date());
  const [data, setData] = useState(format(date, 'yyyy-MM-dd'));
  const [file, setFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [erros, setErros] = useState(null);

  const [lista, setLista] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState(null);
  const [turmaDetalhe, setTurmaDetalhe] = useState(null);
  const [disciplinas, setDisciplinas] = useState([]);  
  const [disciplina, setDisciplina] = useState(null);  
  const [materias, setMaterias] = useState([]); 
  const [materia, setMateria] = useState(null); 
  const [dados, setDados] = useState({
    turma: '',
    discip: '',
    materia: '',
    data: date,
    tipo: 'OU',
    file: null,
    titulo: '',
    detalhes: '',
    url: '',
  });

  // eslint-disable-next-line
  const [dataPick, setDataPick] = useState(startOfDay(date));
  
  const CustomDatePciker = React.forwardRef((props, ref) => (
      <button type="button" className="form-control" onClick={props.onClick} >
          {props.value}
      </button>
  ));  


  // carrega turmas
  useEffect(() => { 
    async function load(){
      let url = '/grade/turmas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/turmas/diario/admin';

      if (usuario.tipo === 'COORD')
        url = '/grade/turmas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });      
      setTurmas(result.data);
    }

    load();
  }, [usuario, escola, periodo])

  // carrega disciplinas
  useEffect(() => {     
    async function load(){
      let url = '/grade/disciplinas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/disciplinas/diario/todas';

      if (usuario.tipo === 'COORD')
        url = '/grade/disciplinas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: turma.turma,
        },
      }); 

      setDisciplinas(result.data);
    }

    if (turma)
      load();

  }, [usuario, escola, periodo, turma])

  function inputChange(e){

    if (e.target.name === 'turma'){
      const reg = turmas.find(t => t.turma === e.target.value)
      setTurma(reg);
      setDados({...dados, turma: e.target.value, discip: ''});
      setDisciplina(null);
    }

    if (e.target.name === 'discip')
      setDados({...dados, discip: e.target.value});
    
    if (e.target.name === 'aulas')
      setDados({...dados, aulas: e.target.value});

    if (e.target.name === 'tipo')
    {
      setFile(null);
      setDados({...dados, tipo: e.target.value, url: ''});
    }


    if (e.target.name === 'url')
      setDados({...dados, url: e.target.value});

    if (e.target.name === 'titulo')
      setDados({...dados, titulo: e.target.value});
    
    if (e.target.name === 'detalhes')
      setDados({...dados, detalhes: e.target.value});

  }

  function inputDateChange(e){
    setDataPick(e);
    setData(e);
  }

  async function handleFormSubmit(e) {
    e.preventDefault();

    try {
      setErros(null);     

      const isValid = await schema.isValid(dados);
      
      if (!isValid){
        setErros('Preencha todos os campos obrigatórios.');
      }else{
      
        if (file)
          if (Math.round((file.size / 1024) / 1024, 2) > 20) {
            setErros([{ message: 'O tamanho do arquivo não pode ser maior que 20mb.' }]);
            return false;
          }

        const options = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            setUploadPercentage(percent);
          }
        }

        let postdata = new FormData();
        let fileUploaded = {
          status: 'success',
        };

        if (file)
        {
          postdata.append('file', file);

          postdata.append('codigo', escola.codigo);    
          const resp = await api.post('/file/upload2', postdata, options);
        
          setUploadPercentage(100);

          setTimeout(() => {
            setUploadPercentage(0)
          }, 1000);
          

          fileUploaded = resp.data;
        }

        if (fileUploaded.status === 'success')
        {
          const dados_ = {    
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,  
            turma: dados.turma, 
            discip: dados.discip, 
            materia: dados.materia, 
            tipo: dados.tipo, 
            titulo: dados.titulo, 
            detalhes: dados.detalhes, 
            extensao: fileUploaded.ext ? fileUploaded.ext : '', 
            url: fileUploaded.uri ? fileUploaded.uri : dados.url,
          }
        
          const resulSave = await api.post('/file/save', dados_);
        }
        
        alert.success('Arquivo cadastrado com sucesso!');

        $('#labelFile').text('Selecione o arquivo');
      }
    } catch (err) {   
      console.log(err);
    }
  }

  const handlePreview = useCallback((e) => {
    e.preventDefault();
    if (e.target.files.length > 0)
    {

      const file_ = e.target.files?.[0];

      if (!file_) {
        setFile(null);  
        setDados({...dados, url: ''});    
      }
      setFile(file_);
      setDados({...dados, url: file_.name});
    }else{
      setFile(null);
      setDados({...dados, url: ''});
    }
    
  });

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Arquivos / Links - Cadastro</li>
              </ol>
          </nav>          
          
          <hr />       

          <div className="alert info-required">
            Campos em vermelho são obrigatórios.
          </div>

          <form>
            <div className="form-row">
              <div className="form-group col-md-9">
                <label className="required">Turma</label>
                <select className="form-control" name="turma" value={dados.turma} onChange={inputChange} >
                  <option value="">...</option>
                  {turmas.map(item => 
                    <option key={item.turma} value={item.turma}>{item.turma} - {item.destur}</option>
                  )}                
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-9">
                <label className="required">Disciplina</label>
                <select className="form-control" value={dados.discip} name="discip" onChange={inputChange}>
                  <option value="">...</option>
                  {disciplinas.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              {materias.length > 0 && (
              <div className="form-group col-md-3">
                <label>Matéria</label>
                <select className="form-control" value={dados.materia} name="materia" onChange={inputChange}>
                  <option value="">...</option>
                  {materias.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              )}              
            </div>  

            <div className="form-row">
                <div className="form-group col-md-3">
                  <label className="required">Data</label>
                  <DatePicker
                    name="data"
                    dateFormat="dd/MM/yyyy"
                    selected={dataPick}
                    onChange={inputDateChange}
                    customInput={<CustomDatePciker />}
                    locale={pt} />
                </div>    

                <div className="form-group col-md-4">
                    <label className="required">Tipo</label>
                    <select className="form-control" value={dados.tipo} name="tipo" onChange={inputChange}>
                        <option value="OU">Arquivo</option>
                        <option value="LK">Link</option>
                    </select>
                </div>            
            </div>     
            <div className="form-row">
                {dados.tipo === 'LK' ? (
                    <div className="form-group col-md-9">                    
                        <label className="required">Link</label>                    
                        <input type="text" onChange={inputChange} className="form-control" name="url" defaultValue={dados.url} autoComplete="false"/>
                    </div>  

                    
                ) : (
                    <div className="form-group col-md-9">
                        <label className="required">Arquivo</label>
                        <div className="custom-file">
                            <input type="file" ref={inputRef} onChange={handlePreview} className="custom-file-input" name="file" disabled={uploadPercentage > 0} />
                            <label id="labelFile" className="custom-file-label" >{file ? file.name : 'Selecione o arquivo'}</label>
                        </div>
                    </div>      
                )}
                               
            </div>

            <div className="form-row">
              <div className="form-group col-md-9">                    
                  <label className="required">Titulo</label>                    
                  <input type="text" onChange={inputChange} className="form-control" name="titulo" defaultValue={dados.titulo} autoComplete="false"/>
              </div>  
            </div>  

            <div className="form-row">
              <div className="form-group col-md-9">                    
                  <label>Detalhes</label>                    
                  <textarea onChange={inputChange} rows={4} className="form-control" name="detalhes" defaultValue={dados.detalhes} autoComplete="false"/>
              </div>  
            </div>  

            <div className="form-row">
                {uploadPercentage > 0 && (
                    <div className="form-group col-md-12" style={{ marginBottom: 10 }}>
                        <div className="w-100" style={{ marginBottom: 3, fontSize: 16, fontWeight: 'bold', textAlign: 'center' }}>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            {uploadPercentage < 100 ? 'Enviando arquivo' : 'Processando arquivo'}, aguarde...
                        </div>
                        <ProgressBar striped variant="danger" now={uploadPercentage} label={`${uploadPercentage}%`} />
                    </div>
                )}
            </div>
            
            {erros && (
              <div className="form-row">
                <div className="alert alert-danger" role="alert">   
                  {erros}               
                </div>
              </div>
            )}
            
            <hr />

            <Botoes>            
              <button type="button" className="btn btn-success" onClick={handleFormSubmit}>Gravar</button>            
              <a href="/arquivos" className="btn btn-dark btn-cancelar ">Voltar</a>
            </Botoes>        
          </form>
                    
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(ArquivosCadastro);