import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import { format, startOfDay, isBefore } from "date-fns";

import Menuusuario from "../../../componentes/MenuFuncinario";
import Footer from "../../../componentes/Footer/index";
import api from "../../../services/api";
import { Container, Botoes } from "./styles";

import * as yup from "yup";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useAlert } from "react-alert";

import {FaPlusCircle, FaTrash} from 'react-icons/fa';
import { toast } from "react-toastify";

function AulasCadastro({ history, escola, usuario, periodo }) {
  const alert = useAlert();
  const schema = yup.object().shape({
    escola: yup.string(),
    ano: yup.string(),
    seqano: yup.string(),
    turma: yup.string().required(),
    discip: yup.string().required(),
    materia: yup.string(),
    tipo: yup.string().required(),
    titulo: yup.string().required(),
    detalhes: yup.string(),
    extensao: yup.string(),
    url: yup.string().required(),
  });

  const inputRef = useRef(null);

  const date = startOfDay(new Date());
  const [dataPick, setDataPick] = useState(new Date());
  const [data, setData] = useState(format(date, "dd-MM-yyyy"));
  // const [file, setFile] = useState(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [erros, setErros] = useState(null);

  const [lista, setLista] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [aula, setAula] = useState(null);
  const [horaInicial, sethoraInicial] = useState(null);
  const [horaFinal, sethoraFinal] = useState(null);
  const [file, setFile] = useState(null);
  const [displayname, setDisplayName] = useState('');
  const [cadastro, setCadastro] = useState(format(new Date(), "dd-MM-yyyy"));
  const [dados, setDados] = useState({
    escola: periodo.escola,
    ano: periodo.ano,
    seqano: periodo.seqano,
    turma: "",
    discip: "",
    materia: "",
    data: new Date(),
    hora_inicio: '',
    hora_fim: '',
    qtd_aulas: '1',
    conteudo: "",
    prof: usuario.guerra,
    url: "",
  });

  useEffect(() => {
    async function load() {
      let url = "/grade";

      if (usuario.tipo === "ADMIN") url = "/grade/todas";

      if (usuario.tipo === "COORD") url = "/grade/coord";

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });

      if (result.data)
      {
        const disciplinas_ = result.data.map(item => {
          item.selected = false;
          return item;
        })
        setDisciplinas(disciplinas_);
      }
    }

    load();
  }, []);

  const handlePreview = useCallback((e) => {
    e.preventDefault();
    if (e.target.files.length > 0) {
      const file_ = e.target.files?.[0];
      if (!file_) {
        setDisplayName('');
        setFile(null);
      }
      setFile(file_);
      var filename = file_.name.replace(/\..+$/, '');
      setDisplayName(filename);
    } else {
      setFile(null);
      setDisplayName('');
    }
  });

  function inputChange(e) {
    setCadastro(new Date());

    if (e.target.name === "qtdaulas") {
      setDados({ ...dados, qtdaulas: e.target.value });
    }

    if (e.target.name === "conteudo") {
      setDados({ ...dados, conteudo: e.target.value });
    }
  }

  function inputDateChange(e) {
    setDataPick(e);
    setData(e);
  }

  function inputHorIniChange(e) {
    setDados({ ...dados, horaInicial: e.target.value });
  }

  function inputHorFinChange(e) {
    setDados({ ...dados, horaFinal: e.target.selected });
  }

   async function handleFormSubmit(e) {
        // if(dados.turma = ""){
    //   alert.error("selecione a turma")
    // }

    e.preventDefault();
    let postdata = new FormData();
    let files = [];
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setUploadPercentage(percent);
      },
    };

    const disciplinas_ = disciplinas.filter(item => item.selected);


    if (lista.length > 0) {
      lista.forEach(item => {
        postdata.append("file[]", item.file);
      })

      postdata.append("codigo", escola.codigo);
      const resp = await api.post("/file/multiupload", postdata, options);
      setUploadPercentage(100);

      setTimeout(() => {
        setUploadPercentage(0);
      }, 1000);


      if (resp.data)
      {
        try {
          files = lista.map(item => {
            let ret = {};
            const fileitem = resp.data.find(f => f.origin_name == item.file.name);
            if (fileitem){
              ret.uri = fileitem.uri;
              ret.ext = fileitem.ext;
              ret.titulo = item.file.displayname;
            }
            return ret;
          })
      
          
          const save = {
            codigo: escola.codigo,
            data: format(dataPick, 'yyyy-MM-dd'),
            qtd_aulas: dados.qtd_aulas,
            hora_inicio: dados.hora_inicio,
            hora_fim: dados.hora_fim,
            files,
            disciplinas: disciplinas_,
            conteudo: dados.conteudo,
            guerra: usuario.guerra,
          };
          const result = await api.post("/files/save", save);
        } catch (err) {
          console.log(err);
        }
      }
    }
      
    try {
      const dados_ = {
        codigo: escola.codigo,
        disciplinas: disciplinas_,
        data: format(dataPick, 'yyyy-MM-dd'),
        hora_inicio: horaInicial ? horaInicial.toLocaleTimeString() : '',
        hora_fim: horaFinal ? horaFinal.toLocaleTimeString() : '',
        aula: horaInicial ? 'S' : '',
        qtd_aulas: dados.qtd_aulas,
        conteudo: dados.conteudo,
        guerra: usuario.guerra,
      };
      const resulSave = await api.post("/aulas", dados_);
      alert.success("Aula cadastrada com sucesso!");
    } catch (err) {
      console.log(err);
    }
  }

  const addFile = e => {
    e.preventDefault();

    if (displayname.trim().length > 0){
      let file_ = file;
      file_.displayname = displayname
      setLista([...lista, {file: file_}]);
      setFile(null);
      setDisplayName('');
    }else{
      toast.warning('Informe uma descrição para o arquivo.');
    }
  }

  const removeFile = index => {
    const file_list = [...lista];
    file_list.splice(index, 1);
    setLista(file_list);
  }

  const checkAula = e => {
    const i = e.target.id;
    let displinas_ = [...disciplinas];
    displinas_.map((item,index)=> {
      if (index == i){
        item.selected = !item.selected;
      }
    });  
    setDisciplinas(displinas_);
  }


  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Aula - Cadastro
              </li>
            </ol>
          </nav>
          <hr />

          <div className="alert info-required">
            Campos em vermelho são obrigatórios.
          </div>

          <form>
            <div className="form-row">
              <div className="form-group col-md-12">
                <ul className="list-group" style={{
                      overflowY: 'scroll',
                      height: 320,
                      border: 'solid 1px #CCC',
                      padding: 4,
                }}>
                  {disciplinas.map((item, index) => (
                    <li key={index} className="list-group-item" style={{padding: 4,flex: 1, alignItems: 'center', display: 'flex'}}>                      
                      <input type="checkbox" checked={item.selected} onChange={checkAula} style={{marginRight: 4, }} id={index} />
                      <label style={{marginBottom: 0,}}>{item.destur} <b>({item.desdis})</b></label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-2">
                <label className="required">Data</label>
                <DatePicker
                  name="data"
                  dateFormat="dd/MM/yyyy"
                  selected={dataPick}
                  className="form-control"
                  onChange={inputDateChange}
                  locale={pt}
                />
              </div>
              
              <div className="form-group col-md-2">
                <label className="required">Qtd. de Aulas</label>
                <input
                  type="number"
                  onChange={inputChange}
                  maxLength="2"
                  className="form-control text-center"
                  name="qtdaulas"
                  defaultValue={dados.qtd_aulas}
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group form-check" style={{paddingLeft: 26}}>
                <input type="checkbox" className="form-check-input" onChange={e => setAula(e.target.checked)}/>
                <label className="form-check-label"><b>Agendar Aula (Google Meet)</b></label>
              </div>
            </div>
            {aula && (
              <div className="form-row">
                <div className="form-group col-md-2">
                  <label className="required">Inicio</label>
                  <DatePicker
                    name="horIni"
                    selected={horaInicial}
                    className="form-control"
                    onChange={(date) => {
                      sethoraInicial(date);
                      setDados({ ...dados, hora_inicio: format(date, 'HH:mm') });
                    }}
                    showTimeSelect
                    dropdownMode="select"
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Hora"
                    dateFormat="H:mm"
                    locale={pt}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label className="required">Fim</label>
                  <DatePicker
                    name="horFin"
                    selected={horaFinal}
                    onChange={(date) => {
                      sethoraFinal(date);
                      setDados({ ...dados, hora_fim: format(date, 'HH:mm')});
                    }}
                    showTimeSelect
                  className="form-control"
                  showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Hora"
                    dateFormat="H:mm"
                    locale={pt}
                  />
                </div>
              </div>
            )}
            <div className="form-row">
              <div className="form-group col-md-4">
                <label className="">Arquivo</label>
                <div className="custom-file">
                  <input
                    type="file"
                    ref={inputRef}
                    onChange={handlePreview}
                    className="custom-file-input"
                    name="file"
                    disabled={uploadPercentage > 0}
                  />
                  <label id="labelFile" className="custom-file-label">
                    {file ? file.name : "Selecione o arquivo"}
                  </label>
                </div>
              </div>
              <div className="form-group col-md-3">
                <label className="">Descrição</label>
                <input type="text" value={displayname} className="form-control" onChange={(e) => setDisplayName(e.target.value)}/>
              </div>
              <div className="form-group col-md-2 d-flex align-items-end">
                  <button className="btn btn-primary" onClick={addFile}><FaPlusCircle /></button>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-9">
                <ul className="list-group">
                  {lista && lista.map((item, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center list-group-item-info">
                      {item.file.displayname} ({item.file.name})
                      <FaTrash className="btn-icon" onClick={() => removeFile(index)} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="form-row">
              {uploadPercentage > 0 && (
                <div
                  className="form-group col-md-12"
                  style={{ marginBottom: 10 }}
                >
                  <div
                    className="w-100"
                    style={{
                      marginBottom: 3,
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {uploadPercentage < 100
                      ? "Enviando arquivo"
                      : "Processando arquivo"}
                    , aguarde...
                  </div>
                  <ProgressBar
                    striped
                    variant="danger"
                    now={uploadPercentage}
                    label={`${uploadPercentage}%`}
                  />
                </div>
              )}
            </div>

            {erros && (
              <div className="form-row">
                <div className="alert alert-danger" role="alert">
                  {erros}
                </div>
              </div>
            )}

            <div className="form-row">
              <div className="form-group col-md-9">
                <label>Conteúdo</label>
                <textarea
                  onChange={inputChange}
                  rows={4}
                  className="form-control"
                  name="conteudo"
                  defaultValue={dados.conteudo}
                  autoComplete="false"
                />
              </div>
            </div>
            <hr />

            <Botoes>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleFormSubmit}
              >
                Gravar
              </button>
              <a href="/aulas" className="btn btn-dark btn-cancelar ">
                Voltar
              </a>
            </Botoes>
          </form>
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect((state) => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(AulasCadastro);
