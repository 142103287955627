function isMobile() {
  if (window.screen.width < 480)
    return true;
}

export default {
  versao: '1.1.9',
  nome: 'MaisEscola',
  isMobile: isMobile(),
  baseURL: 'https://aplicativomaisescola.online/api/',
  // baseURL: 'http://192.168.100.72:8001/',
  urlRelatorios: 'https://aplicativomaisescola.online/relatorios/',
};
