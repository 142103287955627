import styled from 'styled-components';

export const Container = styled.div`
  
`;

export const Footer = styled.div`

    margin-top: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    
    button {
        margin-left: 10px;
    }
`;