import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';

import {format, startOfDay, isBefore} from 'date-fns';

import api from '../../../services/api';
import { Container, TableNota, Botoes, Formula } from './styles';

import CurrencyInput from 'react-currency-input';

// import {FaRegEdit, FaUser, FaUsers} from 'react-icons/fa';

import formula from '../../../services/formula';
import { useAlert } from 'react-alert'

function NotasLancamentoTec({ history, sistema, escola, usuario, periodo }) {
  
  const alert = useAlert();

  const date = startOfDay(new Date());
  const [datalimite, setDataLimite] = useState('');
  const [data, setData] = useState(format(date, 'yyyy-MM-dd'));
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turmaDetalhe, setTurmaDetalhe] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [notas, setNotas] = useState([]);
  const [dados, setDados] = useState({
    nava: '',
    turma: '',
    disciplina: '',
    materia: '',
    semnotas: false,
  });

  const [turma, setTurma] = useState(null);  
  const [disciplina, setDisciplina] = useState(null);  
  const [materia, setMateria] = useState(null);  
  const [ia1, setIa1] = useState(false);
  const [faltas, setFaltas] = useState(false);
  const [passouDataLimite, setPassouDataLimite] = useState(false);
  const [notaTransferida, setNotaTransferida] = useState(false);
  const [semnotas, setSemNotas] = useState(false);

  async function loadAvaliacoes(){ // avaliações

    const result = await api.get('avaliacoes/diarioweb', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
      },
    });
    setAvaliacoes(result.data);
  }
  // eslint-disable-next-line
  useEffect(() => {loadAvaliacoes()}, [periodo]);

  // carrega turmas
  useEffect(() => { 
    async function load(){
      let url = '/grade/turmas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/turmas/diario/admin';

      if (usuario.tipo === 'COORD')
        url = '/grade/turmas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });      
      setTurmas(result.data);
      // setListaTurmas(result.data);
    }

    load();
  }, [usuario, escola, periodo])

  // carrega disciplinas
  useEffect(() => {     
    async function load(){
      let url = '/grade/disciplinas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/disciplinas/diario/todas';

      if (usuario.tipo === 'COORD')
        url = '/grade/disciplinas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: turma.turma,
        },
      }); 
      setDisciplinas(result.data);
    }

    if (turma)
      load();

  }, [usuario, escola, periodo, turma])

  // carrega lista de alunos
  useEffect(() => {
    
    setIa1(false);
    setFaltas(false);

    async function load() {
      // SELECIONA os detalhes da ava/turma/discip (diario_grade)
      const result = await api.get('grade/turma/detalhe/diario', {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          materia: dados.materia,
          nava: dados.nava,
        },
      });

      if (result.data.datalimite){

        const datalimite =  new Date(result.data.datalimite + 'T00:00:00');
        const testeDataLimite = isBefore(datalimite, date);

        setPassouDataLimite(testeDataLimite);
        setDataLimite(format(datalimite, 'dd/MM/yyyy'));
        setTurmaDetalhe(result.data);
        

        const transferido = result.data.transferido === 'S';
        setNotaTransferida(transferido);

        // SELECIONA os alunos (diario_notas)
        const resultNotas = await api.get('/aluno/lista/diario', {
          params: {
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,
            turma: dados.turma,
            discip: dados.disciplina,
            materia: dados.materia,
            nava: dados.nava,
          },
        });

        const rest = resultNotas.data.map(item => {
          
          item.ia1 = (item.ia1 === '') ? null : item.ia1;
          item.ia2 = (item.ia2 === '') ? null : item.ia2;
          item.ia3 = (item.ia3 === '') ? null : item.ia3;
          item.ia4 = (item.ia4 === '') ? null : item.ia4;
          item.ia5 = (item.ia5 === '') ? null : item.ia5;
          item.ia6 = (item.ia6 === '') ? null : item.ia6;
          item.faltas = (item.faltas === '') ? null : item.faltas;
          return item;
        });
        
        
        setNotas(rest);
      }else{
        alert.show('Data Limite, não cadastrada.');
        setNotas([]);
        setTurmaDetalhe([]);
        setDataLimite('');
        setPassouDataLimite(false);
        setNotaTransferida(false);
      }
    }

    if (dados.nava && dados.turma && dados.disciplina)
      load();
    else
    {
      setNotas([]);
      setTurmaDetalhe([]);
      setDataLimite('');
      setPassouDataLimite(false);
      setNotaTransferida(false);
    }

  }, [dados]);

  function changeTurma(e) {    
    const reg = turmas.find(t => t.turma === e.target.value)
    setTurma(reg);
    setDados({...dados, turma: e.target.value, disciplina: ''});
    setDisciplina(null);
  }

  function handleChange(e){
    if (e.target.name === 'nava')
      setDados({...dados, nava: e.target.value});

    if (e.target.name === 'turma')
      setDados({...dados, turma: e.target.value});

    if (e.target.name === 'disciplina')
      setDados({...dados, disciplina: e.target.value});

    if (e.target.name === 'semnotas')
      setSemNotas(e.target.checked);
  }

  function handleChangeNota(e){
    const id = e.target.name;
    const ia = e.target.id;
    let value = e.target.value;    

    if (e.target.value === '')
    {
      value = null;
    }    

    const result = notas.map(item => {
      if (id === item.id)
      {

        switch (ia) {
          case 'ia1':
            item.ia1 = value;
            break;

          case 'falas':
            item.falas = value;
            break;
                                    
          default:
            break;
        }  
      }   
      
      return item;
    });
    
    setNotas(result);
  }

  function haldeCalculaNota(e){
    const id = e.target.name;

    const result = notas.map(item => {
      if (id === item.id){                
        return formula.calculaNota(item, turmaDetalhe);
      }else{
        return item
      }
    });

    setNotas(result);
  }

  async function handleSave(){
    try
    {
      const result = await api.post('/notas', {
        codigo: escola.codigo,
        notas
      });
      
      const resultdetalhe = await api.post('/notas/detalhe', {
        codigo: escola.codigo,
        detalhes: turmaDetalhe
      });

      let dados_ = dados;
      dados_.codigo= escola.codigo;
      dados_.escola= periodo.escola;
      dados_.ano= periodo.ano;
      dados_.seqano= periodo.seqano;
      dados_.guerra= usuario.guerra;

      const log = await api.post('/notas/gravar/log', dados_);
      alert.success('Dados gravados com sucesso!');
    }catch(error){
      alert.error(error);
    }
  }

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Lançamento de Notas</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label>Avaliação</label>
                <select className="form-control" name="nava" defaultValue={dados.nava} onChange={handleChange}>
                  <option value="">...</option>
                  {avaliacoes.map(item => 
                    <option key={item.id} value={item.nava}>{item.ava}</option>
                  )}                
                </select>
              </div>
              <div className="form-group col-md-8">
                <label>Turma</label>
                <select className="form-control" name="turma" defaultValue={dados.turma} onChange={changeTurma} >
                  <option value="">...</option>
                  {turmas.map(item => 
                    <option key={item.turma} value={item.turma}>{item.turma} - {item.destur}</option>
                  )}                
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-9">
                <label>Disciplina</label>
                <select className="form-control" value={dados.disciplina} name="disciplina" onChange={handleChange}>
                  <option value="">...</option>
                  {disciplinas.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              {materias.length > 0 && (
              <div className="form-group col-md-3">
                <label>Matéria</label>
                <select className="form-control" name="materia" onChange={handleChange}>
                  <option value="">...</option>
                  {materias.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              )}              
            </div>

            {/* 
            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="gridCheck" name="semnotas" onChange={handleChange} defaultValue={semnotas} />
                  <label className="form-check-label" htmlFor="gridCheck">
                    Mostrar somente alunos sem nota.
                  </label>
                </div>
              </div>
            </div> 
            */}

            <hr />
            {notaTransferida && (
              <div className="alert alert-danger" role="alert">
                Notas transferidas em <strong>({turmaDetalhe.data_ultimatransferencia && format(new Date(turmaDetalhe.data_ultimatransferencia), 'dd/MM/yyyy')})</strong>.
              </div>
            )}
            {passouDataLimite ? (
              <div className="alert alert-danger" role="alert">
                Prazo para lançamento das notas expirou em <strong>({datalimite})</strong>.
              </div>
            ): (
              datalimite && (
                <div className="alert alert-success" role="alert">
                  Prazo para lançamento das notas expira em <strong>({datalimite})</strong>.
                </div>
              )
            )}
          
            <TableNota className="table">
              <thead className="thead-dark">
                <tr>
                  <th className="column-ord header-sort">Ord.</th>
                  <th className="column-matric header-sort">Matrícula</th>
                  <th className="column-nome header-sort">Nome</th>
                  <th className="column-nota">Nota</th>
                  <th className="column-faltas">{turma && (turma.tiplan < 1 && 'Faltas')}</th>
                </tr>
              </thead>
              <tbody>
                {semnotas ? (
                  notas.map(nota => (
                    nota.nota === '' && (
                      <tr key={nota.id}>
                        <td className="column-ord">{nota.ord}</td>
                        <td className="column-matric">{nota.matric}</td>
                        <td>{nota.nome}</td>
                        <td className="column-ia">
                          <CurrencyInput disabled={passouDataLimite || notaTransferida} onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia1'} name={nota.id} className="input-ia" value={nota.ia1} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia" style={{display: 'none'}}>{nota.nota}</td>
                        <td className="column-ia">{turma && (turma.tiplan < 1 && <input disabled={!faltas} id={'faltas'} className="input-ia" type="text"/>)}</td>                    
                      </tr>           
                    )
                  ))
                ) : (
                  notas.map(nota => (
                    notas.length > 0 && (
                      <tr key={nota.id}>
                        <td className="column-ord">{nota.ord}</td>
                        <td className="column-matric">{nota.matric}</td>
                        <td>{nota.nome}</td>
                        <td className="column-ia">
                          <CurrencyInput disabled={passouDataLimite || notaTransferida} onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia1'} name={nota.id} className="input-ia" value={nota.ia1} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia" style={{display: 'none'}}>{nota.nota}</td>
                        <td className="column-ia">{turma && (turma.tiplan < 1 && <input disabled={!faltas} className="input-ia" type="text"/>)}</td>                    
                      </tr>     
                    )     
                  ))
                )} 

                {notas.length === 0 && (
                  <tr key={1}>
                    <td className="linha-sem-dados" colSpan={11}>Nenhuma informação</td>
                  </tr>
                )}
              </tbody>
            </TableNota>
          </form>
          
          <hr />

          <Botoes>    
            {turmaDetalhe.formula && (
            <div className="alert alert-info" role="alert">
              <strong>Fórmula:  </strong> {' ' + turmaDetalhe.formula}
            </div>
            )}
            
            {(notas.length > 0 && !notaTransferida && !passouDataLimite) && (
              <button type="button" className="btn btn-success" onClick={() => handleSave()}>Gravar</button>            
            )}
          </Botoes>
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(NotasLancamentoTec);