
const _periodo = localStorage.getItem('maisescola.periodo');

const INITIAL_STATE = _periodo ? JSON.parse(_periodo) : null;

export default function periodo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@PERIODO/SETA_PERIODO':
      localStorage.setItem('maisescola.periodo', JSON.stringify(action.periodo));
      return action.periodo;
    default:
      return state;
  };
};
