import React, { useState } from 'react';
import { connect } from 'react-redux';
import FooterAdmin from '../../../componentes/FooterAdmin/index';
import api from '../../../services/api';
import MenuAdmin from '../../../componentes/MenuAdmin';

import {Container} from './styles';
import { useRef } from 'react';
import { useParams, Redirect } from 'react-router-dom';

function Sincronizacao({history}) {
  const params = useParams();

  const textLog = useRef(null);
  const [log, setLog] = useState('');
  const [sql, setSql] = useState('');
  const [registro, setRegistro] = useState(0);
  const [bancos, setBancos] = useState([]);
  const [porcentagem, setPorcentagem] = useState(0);
  const [total, setTotal] = useState(0);

  useState(() => {

      
    if (!params.user)
      history.push('/');

    if (params.user !== 'admpath')
      history.push('/');

    async function load(){
      const result = await api.get('escolas', {params: {codigo: 12345}});

  
      setTotal(result.data.length);
      setBancos(result.data ? result.data : []);
    }

    load();
    
  }, [])

  async function getTodos() {
    
  }

  const processarSQL = async (e) => {
    e.preventDefault();
    let texto = '';

    await Promise.all(
      bancos.map(async (item, index) => {
        try{
          const response = await api.post('sql', { sql, codigo: item.codigo });
          if (response.data.error)
          {
            texto = texto + '<b class="text-danger">' + item.path + ' - ' + response.data.error  + '</b><br />';
          }else{
            texto = texto + '<span class="text-success">' + item.path + ' - ' + response.data.message + '</span><br />';
          }
        }catch(ex){
          texto = texto + '<b class="text-danger">' + item.codigo + ' - ' + ex.message + '<b><br />';
        }

        await setPorcentagem(index);
        await setLog(texto);
        textLog.current.scrollTop = textLog.current.scrollHeight;
      })      
    );
    
    
  }

  return (
    <>
     <MenuAdmin history={history} />
      <Container className="Container card m-3">
        <div  className="card-header text-center">
          Administração
        </div >  
        <div className="card-body">
          
          <br/>
         
          <div>
            <form>
              <div className="form-group">
                <label>Instrução SQL</label>
                <textarea className="form-control" rows="5" value={sql} onChange={e => setSql(e.target.value)}></textarea>
              </div>
              <button className="btn btn-danger btn-lg btn-block" onClick={processarSQL}>Processar</button>
            </form>
            
            <hr/>
            <div className="text-center">
            
            </div>
            <div className="progress">
              <div className="progress-bar progress-bar-animated" role="progressbar" style={{width: `${porcentagem}%`,}} aria-valuenow={porcentagem} aria-valuemin="0" aria-valuemax={total}>                
                {porcentagem}%
              </div>
            </div>
            <br/>
            <div id="textLog" ref={textLog} style={{ 
              float:"left", 
              clear: "both", 
              backgroundColor: '#000', 
              width: '100%',
              padding: 6,
              lineHeight: 1.5,
              fontFamily: 'monospace',
              height: 300,
              overflow: 'auto', }} dangerouslySetInnerHTML={{__html: log}}>                
            </div>
          </div>

        </div>
      </Container>
      <FooterAdmin />
    </>
  );
}


export default connect(state => ({
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
}))(Sincronizacao)