import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';

import {format, startOfDay, isBefore} from 'date-fns';

import api from '../../../services/api';
import { Container, TableTransferencia, Botoes, Formula } from './styles';

import CurrencyInput from 'react-currency-input';

import {FaThumbsUp, FaThumbsDown, FaCheck} from 'react-icons/fa';

import Pagination from '../../../componentes/Pagination';
import { useAlert } from 'react-alert';

function TransferenciaNotas({ history, sistema, escola, usuario, periodo }) {

  const alert = useAlert();
  const date = startOfDay(new Date());
  const [data, setData] = useState(format(date, 'yyyy-MM-dd'));
  const [lista, setLista] = useState([]);
  const [listaFiltro, setListaFiltro] = useState([]);
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [professores, setProfessores] = useState([]);
  const [guerra, setGuerra] = useState('');
  const [nava, setNava] = useState('');
  const [situacao, setSituacao] = useState('');
  // paginação
  const [listaPagina, setListaPagina] = useState([]);
  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [update, setUpdate] = useState(false);

  async function loadAvaliacoes(){ // avaliações

    const result = await api.get('avaliacoes/diarioweb', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
      },
    });
    setAvaliacoes(result.data);

    let url = '/grade/professores/coord';
    if (usuario.tipo === 'ADMIN')
      url = '/grade/professores/admin';

    const profs = await api.get(url, {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        guerra: usuario.guerra,
      },
    });

    setProfessores(profs.data);
    
    if (usuario.tipo !== 'ADMIN')
      setGuerra(usuario.guerra);

  }
  // eslint-disable-next-line
  useEffect(() => {loadAvaliacoes()}, [periodo]);

  useEffect(() => {
    
    setLista([]);

    async function load(){ // avaliações

      let url = '/notas/transferencia/professor';

      if (usuario.tipo === 'COORD')
        url = '/notas/transferencia/coord';
      else if (usuario.tipo === 'ADMIN')
        url = '/notas/transferencia/admin';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: guerra,
          nava,
          tipo: usuario.tipo
        },
      });
      
      setLista(result.data);
      setCurrentPage(1);
    }
    
    if (nava)
      load()
    

  }, [nava, update, guerra, usuario]);

  function paginate(event, selectedPage) {
    event.preventDefault();
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    
    const indexOfLast = currentPage * perPage;
    const indexOfFirst = indexOfLast - perPage;
    
    let result = lista;
    switch (situacao) {
      case 'NL':
        result = lista.filter(item => item.data_ultimagravacao);  
        break;
    
      case 'TRA':
        result = lista.filter(item => item.transferido === 'S');  
        break;

      case 'NLNTRA':
        result = lista.filter(item => (item.data_ultimagravacao && item.transferido === 'N'));  
        break;

      case 'TRANIMP':
        result = lista.filter(item => (item.importado === 'N' && item.transferido === 'S'));  
        break;
    
      case 'IMP':
        result = lista.filter(item => item.importado === 'S');  
        break;
      
      case 'SL':
        result = lista.filter(item => !item.data_ultimagravacao);  
        break;

      default:
        break;
    }

    setListaFiltro(result);
    if(result.length > 0)
      result = result.slice(indexOfFirst, indexOfLast);

    setListaPagina(result);

  }, [lista, currentPage, situacao]);

  async function handleSave(){

    const dados = listaPagina.filter(item => item.sel);
    
    const result = await api.post('/notas/transferencia/update', {
      codigo: escola.codigo,
      dados
    });

    const transf = await api.post('/notas/transferencia/log', {
      codigo: escola.codigo,
      dados
    });

    alert.success('Notas transferidas com sucesso');
    setUpdate(!update);

  }

  function handleChangeCheck(e){
    const result = listaPagina.map(item => {
      if (item.id === e.target.name)
        item.sel = e.target.checked;

      return item;
    })
  }

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body className="p-1">
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Transferência de Notas</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label>Avaliação</label>
                <select className="form-control" defaultValue={nava} onChange={e => setNava(e.target.value)}>
                  <option value="">...</option>
                  {avaliacoes.map(item => 
                    <option key={item.id} value={item.nava}>{item.ava}</option>
                  )}                
                </select>
              </div>   
              <div className="form-group col-md-3">
                <label>Situação</label>
                <select className="form-control" defaultValue={situacao} onChange={e => setSituacao(e.target.value)}>
                  <option value="">Todas</option>
                  <option value="NL">Nota Lançada</option>
                  <option value="NLNTRA">Nota Lançada / Não Transferida</option>                                 
                  <option value="TRA">Transferida</option>
                  <option value="TRANIMP">Transferida / Não Importada</option>                                 
                  <option value="IMP">Importada</option>
                  <option value="SL">Sem Lançamento</option>                                
                </select>
              </div>   
              <div className="form-group col-md-7">
                <label>Professor(a)</label>
                <select className="form-control" disabled={usuario.tipo === 'PROF'} defaultValue={guerra} onChange={(e) => setGuerra(e.target.value)}>
                  <option value="">Todos</option>
                  {professores.map(item => 
                    <option key={item.prof} value={item.prof}>{item.prof}</option>
                  )}  
                </select>
              </div>

            </div>
            <hr />            
            <div className="row tabela-cores">
              <div className="col p-1 mb-2 bg-danger text-white text-center">Sem Lançamento</div>
              <div className="col p-1 mb-2 bg-primary text-white text-center">Notas Lançadas</div>
              <div className="col p-1 mb-2 bg-warning text-center">Transferida</div>
              <div className="col p-1 mb-2 bg-success text-white text-center">Importada</div>
            </div>
            {sistema.isMobile ? (
              <TableTransferencia className="table table-bordered">                
                <tbody>
                  {listaPagina && (                    
                    listaPagina.map(item => (                      
                      <tr key={item.id} className={item.importado === 'S' ? 'bg-success text-white' : (item.transferido === 'S' ? 'bg-warning' : (item.data_ultimagravacao ? 'bg-primary text-white' : 'bg-danger text-white'))}>
                        <td>                          
                          <b>{item.turma}</b> - {item.destur}<br />                    
                          <b>{item.discip}</b>  - {item.desdis} {item.materia && ' / ' + item.materia}
                          <br />
                          <br />
                          <div className={'row m-0'}>
                            <div className="col">
                              <b>Ult. Grav.</b> <br />
                              <span >
                                {item.data_ultimagravacao && format(new Date(item.data_ultimagravacao + 'T00:00:00'), 'dd/MM/yyyy')}
                              </span>
                            </div>
                            <div className="col">
                              <b>Ult. Transf.</b> <br />
                              <span >
                                {item.data_ultimatransferencia && format(new Date(item.data_ultimatransferencia + 'T00:00:00'), 'dd/MM/yyyy')}
                                {item.transferido === 'S' && '\nTransferido'}
                              </span>
                            </div>
                            <div className="col">
                              <b>Ult. Impor.</b> <br />
                              <span>
                                {item.data_ultimaimportacao && format(new Date(item.data_ultimaimportacao + 'T00:00:00'), 'dd/MM/yyyy')}
                                {item.importado === 'S' && '\nImportado'}
                              </span>
                            </div>
                          </div>
                        </td> 
                        <td>
                          <input defaultChecked={item.sel} type="checkbox" disabled={item.transferido === 'S' || !item.data_ultimagravacao} name={item.id} onChange={handleChangeCheck}/>
                        </td>                   
                      </tr>               
                    ))
                  )}

                  {listaPagina && (
                    listaPagina.length === 0 && (
                      <tr key={1}>
                        <td className="linha-sem-dados" colSpan={9}>Nenhuma informação</td>
                      </tr>
                    )
                  )} 
                </tbody>
              </TableTransferencia>
            ) : (
              <TableTransferencia className="table table-hover">
                <thead className="thead-dark">                               
                  <tr>
                    <th className="column">Turma / Disciplina / Matéria / Prof.</th>
                    <th className="column">Ult. Gravação</th>
                    <th className="column">Transf.</th>
                    <th className="column">Ult. Transf.</th>
                    <th className="column">Imp.</th>
                    <th className="column">Ult. Imp.</th>
                    <th className="column">Sel</th>
                  </tr>
                </thead>
                <tbody>
                  {listaPagina && (
                    listaPagina.map(item => (
                      <tr key={item.id}>
                        <td className="column-turma">
                          <b>{item.turma}</b> - {item.destur}<br />                    
                          <b>{item.discip}</b> - {item.desdis} {item.materia && ' / ' + item.materia}<br />  
                          {item.prof}
                        </td>                    
                        <td className="column">{item.data_ultimagravacao && format(new Date(item.data_ultimagravacao + 'T00:00:00'), 'dd/MM/yyyy')}</td>
                        <td className="column">{item.transferido === 'S' ? <FaCheck size={20} color="#008000" /> : <FaThumbsDown size={20} color="#B22222" />}</td>
                        <td className="column">{item.data_ultimatransferencia && format(new Date(item.data_ultimatransferencia + 'T00:00:00'), 'dd/MM/yyyy')}</td>
                        <td className="column">{item.importado === 'S' ? <FaCheck size={20} color="#008000" /> : <FaThumbsDown size={20} color="#B22222" />}</td>
                        <td className="column">{item.data_ultimaimportacao && format(new Date(item.data_ultimaimportacao + 'T00:00:00'), 'dd/MM/yyyy')}</td>
                        <td className="column"><input defaultChecked={item.sel} type="checkbox" disabled={item.transferido === 'S' || !item.data_ultimagravacao} name={item.id} onChange={handleChangeCheck}/></td>                    
                      </tr>               
                    ))
                  )}

                  {listaPagina && (
                    listaPagina.length === 0 && (
                      <tr key={1}>
                        <td className="linha-sem-dados" colSpan={9}>Nenhuma informação</td>
                      </tr>
                    )
                  )} 
                </tbody>
              </TableTransferencia>
            )}
            <Pagination perPage={perPage} total={listaFiltro.length} paginate={paginate}/>
          </form>
          
          <hr />
          
          <Botoes>               
            <button type="button" className="btn btn-success" onClick={() => handleSave()}>Gravar</button>            
          </Botoes>
          
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(TransferenciaNotas);