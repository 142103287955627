import React, { useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

function MenuAdmin({ history, escola, sistema}) {

  function sair() {
    history.push('/' + escola.cnpj);
  }

  useEffect(() => {
  },[])

  return (
    <Navbar bg="light" expand="lg">
      <Navbar.Brand href="#home">MaisEscola <small style={{fontSize: 10}}>({sistema.versao})</small></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/admin"> Administrator</Nav.Link>
          <Nav.Link href="/db"> Data Bases</Nav.Link>
          {/* <NavDropdown title="Exportar" id="basic-nav-dropdown">
            <NavDropdown.Item href="/#"> Alunos</NavDropdown.Item>
            <NavDropdown.Item href="/#"> Boletim</NavDropdown.Item>
            <NavDropdown.Item href="/#"> Ficha Financeira</NavDropdown.Item>
            <NavDropdown.Item href="/#"> Frequência/Dia</NavDropdown.Item>
            <NavDropdown.Item href="/#"> Faltas</NavDropdown.Item>
            <NavDropdown.Item href="/#"> Ocorrências</NavDropdown.Item>
            <NavDropdown.Item href="/#"> Histórico</NavDropdown.Item>
          </NavDropdown> */}
          {/* <Nav.Link href="/configuracoes"> Configurações</Nav.Link> */}
        </Nav>
        <Form inline>
          <Button variant="outline-danger" onClick={sair}>Sair</Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  escola: state.escola,
  sistema: state.sistema,
}))(MenuAdmin);