import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';
import {TableMobile} from './styles';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';
import {MdAttachFile, MdPictureAsPdf} from 'react-icons/md';
import {RiChatNewLine} from 'react-icons/ri';
import {Link} from 'react-router-dom';

function BoletimInstrumento({ history, usuario, parametros, escola, periodo, sistema }) {
  const [dados, setDados] = useState({
    codigo: usuario.codigo,
    escola: periodo.escola,
    ano: periodo.ano,
    seqano: periodo.seqano,
    matric: usuario.matric,
    turma: usuario.turma
  });
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [modavaqua, setModavaqua] = useState([]);
  const [modavaquaobs, setModavaquaobs] = useState([]);
  const [modavaquares, setModavaquares] = useState([]);
  const [lista, setlista] = useState([]);

  useEffect(() => {
    async function load() {
      const dados = await api.get('/requerimento/lista', {
        params: { codigo: usuario.codigo,
                  matric: usuario.matric},
      });
      setlista(dados.data);
    }

    load();
  }, []);

  
  return (
    <>
      <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />

      <Card className="card-main">
        <Card.Header className="text-center h3">Requerimentos</Card.Header>
        <Card.Body>
          
          
          <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            
            	<table width="100%" className="table table-striped table-bordered">
                <thead>
                  <tr className="active">
                    <th className="text-center">Nº</th>
                    <th className="text-left">Ocorrência</th>
                    <th className="text-center">Data</th>
                    <th className="text-left">Histórico</th>
                    <th style={{width: 80, textAlign: 'center'}}>Anexo do Aluno</th>
                    <th style={{width: 80, textAlign: 'center'}}>Documento Retorno</th>
                  </tr>
                </thead>
                <tbody>
                  {lista.length > 0 && lista.map((item, index) => (

                  <tr key={index} >
                    <td className="text-center">{item.nreqweb}</td>
                    <td className="text-left">{item.ocorrencia}</td>
                    <td className="text-center">{item.datoco}</td>
                    <td className="text-left">{item.linhis}</td>
                    <td className="text-center">
                      {item.file != "" && <a href={item.file} target="_blank"><MdAttachFile size={24}/></a>   } 
                    </td>
                    <td className="text-center">
                      {item.doc && <a href={item.doc} target="_blank"><MdPictureAsPdf size={24}/></a>   } 
                    </td>
                  </tr>
                  ))                   
                  }

                </tbody>
              </table>
            <br />

          </div>
          <div className="text-right">
            <Link to="/cadastrorequerimento" className="btn btn-dark"> <RiChatNewLine/>  Novo Requerimento</Link>
          </div>
        <br/>
        </Card.Body>
      </Card>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(BoletimInstrumento)
