import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import $, { data } from 'jquery';
import './style.css';
import { useAlert } from 'react-alert';
import ReactDatePicker from 'react-datepicker';
import { format, isAfter, startOfDay, subDays } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import {BsImage, BsTrash} from 'react-icons/bs';
import {FaSearchPlus} from 'react-icons/fa'
import {GrDocumentPdf} from 'react-icons/gr'
import Confirm from '../../../componentes/Confirm';

function MensagemGerenciar({history}) {
  const {codigo, escola, ano, seqano} = useParams();
  
  const [mensagens, setMensagens] = useState([]); // alterar para true
  const [registro, setRegistro] = useState(null); // alterar para true
  const [registroDel, setRegistroDel] = useState(null); // alterar para true

  const [funcionarioSel, setFuncionarioSel] = useState(''); // alterar para true
  const [funcionarios, setFuncionarios] = useState([]); // alterar para true
  const [turmas, setTurmas] = useState([]);
  const [turmaSel, setTurmaSel] = useState(''); // alterar para true
  const [alunos, setAlunos] = useState([]);
  const [alunoSel, setAlunoSel] = useState(''); // alterar para true

  const [date, setDate] = useState(startOfDay(new Date()));
  const [date2, setDate2] = useState(startOfDay(new Date()));
  const [datePick, setDatePick] = useState(startOfDay(new Date()));
  const [datePick2, setDatePick2] = useState(startOfDay(new Date()));

  const alert = useAlert();

  document.title = 'Gerenciar Mensagens';
  
  async function iniLoad(){
    try{
      const funcionarios_ = await api.get('/funcionario/lista', {
        params: { codigo },
      });
      setFuncionarios(funcionarios_.data);

      const restTurmas = await api.get('/turmasgeral', {
        params: {
          codigo: codigo,
          escola: escola,
          ano: ano,
          seqano: seqano,
        }
      });
      setTurmas(restTurmas.data);

      const restAlunos = await api.get('aluno/lista/todos', {
        params: {
          codigo: codigo,
          escola: escola,
          ano: ano,
          seqano: seqano,
        },
      });
      setAlunos(restAlunos.data);

      const result = await api.get('/chat/consulta', {
        params: {
          codigo: codigo,
          funcionario: funcionarioSel,
          turma: turmaSel,
          aluno: alunoSel,
          escola: escola,
          ano: ano,
          seqano: seqano,
          data: date.toISOString().slice(0, 10),
          texto: '',
        },
      });
      console.log(result.data);
      setMensagens(result.data);
    }catch(error)
    {
      console.log(error)
    }
  }

  useEffect(() => {
    iniLoad();
  }, []);
    
  // eslint-disable-next-line
  const [dataPick, setDataPick] = useState(startOfDay(date));

  const CustomDatePciker = React.forwardRef((props, ref) => (
      <button type="button" className="form-control form-control-sm" onClick={props.onClick} >
          {props.value}
      </button>
  ));  

  function changeDate(e){
    setDatePick(e);
    setDate(e);
  }
  
  function changeDate2(e){
    setDatePick2(e);
    setDate2(e);
  }

  useEffect(() => {
    if (registro)
      $('#detalhes').modal('show')
    else
      $('#detalhes').modal('hide')
  }, [registro])
    
  const setDelete = (del) => {
    const update = mensagens.map(x => {
      if (x.id == del.id)
        x.deletar = 'S'

      return x;
    })

    setMensagens(update);
  }

  const setDeleteTodos = (e) => {
    console.log(e.target.checked);
    const update = mensagens.map(x => {
      x.deletar = e.target.checked ? 'S' : 'N';
      return x;
    })

    setMensagens(update);
  }

  const remove = async (e) => {
    e.preventDefault();
    const _lista = mensagens.filter(item => { return item.deletar === 'S' });

    if (_lista.length > 0)
    {
      const restDel = await api.post('chat/delete', {
        codigo: codigo,
        matric: '9999999',
        lista: _lista,
      });
      alert.success('Registros excluídos com sucesso!')
      iniLoad();
    }
  }

  async function consulta(e) {    
    e.preventDefault();

    const result = await api.get('/chat/consulta', {
      params: {
        codigo: codigo,
        funcionario: funcionarioSel,
        turma: turmaSel,
        aluno: alunoSel,
        escola: escola,
        ano: ano,
        seqano: seqano,
        data: date.toISOString().slice(0, 10),
        texto: '',
      },
    });
    console.log(result.data);
    setMensagens(result.data);
  }

  return (
    <div className="card" style={{borderRadius: 0,}}>
      <div className="card-body" style={{padding: 0,}}>
        <form className='form-mensagem'>
          <div className="form-row">
            <div className="form-group col-5">
              <label >Funcionário</label>
              <select onChange={e => setFuncionarioSel(e.target.value)} className="form-control form-control-sm" value={funcionarioSel}>
                <option value="">Todos</option>
                {funcionarios.map((f, index) => (
                  <option key={index} value={f.matric}>{f.guerra}</option>
                ))}
              </select>
            </div>

            <div className="form-group col-2">
              <label >Data Inicial</label>
              <ReactDatePicker
                name="data"
                dateFormat="dd/MM/yyyy"
                selected={datePick}
                onChange={changeDate}
                popperPlacement="right-start"
                customInput={<CustomDatePciker />}
                required
                locale={pt} />             
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-5">
              <label >Turma</label>
              <select onChange={(e) => setTurmaSel(e.target.value)} className="form-control form-control-sm" value={turmaSel}>
                <option value="">Todas</option>
                {turmas.map((x, index) => (
                  <option key={index} value={x.turma}>{x.turma} - {x.destur}</option>
                ))}
              </select>
            </div>
            <div className="form-group col-5">
              <label >Aluno</label>
              <select onChange={e => setAlunoSel(e.target.value)} className="form-control form-control-sm" value={alunoSel}>
                <option value="">Todos</option>
                {turmaSel.length > 0 ? (
                alunos.filter(a => a.turma == turmaSel).map((x, index) => (
                  <option key={index} value={x.matric}>{x.nome}</option>
                ))
                ) : (
                  alunos.sort((a, b)=> {
                    if (a.turma < b.turma)
                      return -1;

                    if (a.turma > b.turma)
                      return 1;
                  
                    return 0;
                  }).map((x, index) => (
                    <option key={index} value={x.matric}>{x.turma} - {x.nome}</option>
                  ))
                )}
              </select>
            </div>
            <div className="form-group col-2">
              <button className="btn btn-info btn-sm" style={{marginTop: 14, padding: '0.3em'}} onClick={consulta}>
                Consultar
              </button>
            </div>
          </div>
          
          <div style={{height: 326, maxHeight: 326, overflowY: 'scroll', border: 'solid 1px', borderColor: 'lightgrey'}}>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Turma / Aluno</th>
                  <th>Mensagem</th>
                  <th className='text-center' style={{width: 40}}></th>
                  <th className='text-center' style={{width: 110}}>Data/Hora Envio</th>
                  <th style={{width: 60, padding: 10}}>
                    Marcar Todos
                    <div className="form-check">
                      <input className="form-check-input position-static" onClick={setDeleteTodos} type="checkbox" />
                    </div>
                  </th>
                </tr>
              </thead>
              {mensagens.length > 0 ? (
                <tbody>
                  {mensagens?.map((i, index) => 
                    <tr key={index}>
                      <td>{i.turma} - {i.nome}</td>
                      <td><div style={{
                        maxWidth: 300,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>{i.texto}</div></td>
                      <td style={{width: 40, textAlign: 'center'}}>
                        {i?.file_uri.toUpperCase().indexOf('.PDF') > -1 ? (
                          <GrDocumentPdf size={20} />
                        ) : i?.file_uri ? (
                          <BsImage size={20} />
                        ) : ('')}
                      </td>
                      <td style={{width: 110,}}>{i.data_env + ' ' + i.hora_env}</td>
                      <td style={{width: 60}}>
                        <div style={{textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-around', borderTop: 0}}>
                          <div className="form-check">
                            <input className="form-check-input position-static" type="checkbox" onChange={() => setDelete(i)} checked={i.deletar == 'S'} />
                          </div>
                          <FaSearchPlus color='blue' size={20} style={{cursor: 'pointer'}} onClick={() => setRegistro(i)}/>
                        </div>
                      </td>
                    </tr>
                  )}                
                </tbody>
              ):(
                <tbody>
                  <tr>
                    <td colSpan={5} className="text-center p-5">Nenhum registro encontrado</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className="form-row" style={{paddingTop: 6, paddingBottom: 6, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 56}}>
            <button className="btn btn-outline-danger btn-sm" onClick={remove}>              
              Remover itens selecionados <BsTrash size={20} style={{cursor: 'pointer'}} />
            </button>
          </div>

          <div className="form-row" style={{paddingTop: 5}}>
            <div className="form-group col-12" style={{display: 'flex', justifyContent: 'space-between'}}>              
              <button className="btn btn-primary btn-sm" onClick={() => history.replace(`/mensagemenviar/${codigo}/${escola}/${ano}/${seqano}`)}>
                Nova Mensagem
              </button>
              <button className="btn btn-danger btn-sm" onClick={() => window.close()}>
                Fechar
              </button>
            </div>
          </div>
        </form>

        <div className="modal fade" id="detalhes" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{margin: 0, maxWidth: '100vw', width: '100vw', maxHeight: '100vh', height: '100vh'}}>
            <div className="modal-content" style={{margin: 0, maxWidth: '100vw', width: '100vw', maxHeight: '100vh', height: '100vh'}}>              
              <div className="modal-body">
                <div style={{padding: 4, textAlign: 'center'}}>
                    {registro?.file_uri.toUpperCase().indexOf('.PDF') > -1 ? (
                      <iframe src={`${registro?.file_uri}#toolbar=0`} width="460" height="300"></iframe>
                    ):(
                      <img src={registro?.file_uri} className="img-fluid"  alt={registro?.file_uri} style={{maxHeight: 300, maxWidth: 300}}/>
                    )}
                  </div>
                  
                  <p style={{padding: 4}}>{registro?.texto}</p>
                </div>
              <div className="modal-footer" style={{padding: 2,}}>
                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal" onClick={()=> setRegistro(null)}>Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <Confirm texto={'Deseja exlcuir o registro?'} funcao={remove} />
      </div>
    </div>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(MensagemGerenciar)