import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Navbar, Nav, NavDropdown, Form, Button } from 'react-bootstrap';
import './menu.css';
import api from '../services/api';
var md5 = require('md5');

function MenuFuncionario({ history, usuario, escola, sistema }) {
  const [parametros, setParametros] = useState([]); // alterar para true
  const hash = md5(`${usuario.cpf}${parametros.bibvtoken}` )

  function submit() {
    const form = document.getElementById("myForm");
    form.submit();    
  }

  useEffect(() => {
    async function load(){
      const result = await api.get('/parametros', {
        params: { codigo: escola.codigo },
      });
      setParametros(result.data);
    }
    load();

  }, []);

  function sair() {    
    localStorage.removeItem('maisescola.usuario');
    history.push('/' + escola.cnpj);
  }

  return (
    <Navbar bg="light" expand="md">
      <Navbar.Brand href="/homefuncionario">MaisEscola <small style={{fontSize: 10}}>({sistema.versao})</small></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {(!usuario.setor || usuario?.setor != 'REQUERIMENTO') && (
            <>
              <Nav.Link href="/homefuncionario">Home</Nav.Link>
              <Nav.Link href="/agenda">Agenda</Nav.Link>
              <NavDropdown title="Notas" id="basic-nav-dropdown">
                {parametros.tiplannota ? (
                  <NavDropdown.Item href="/notaslancamentotec">Lançamento</NavDropdown.Item>
                ) : (
                  <NavDropdown.Item href="/notaslancamento">Lançamento</NavDropdown.Item>
                )}
                <NavDropdown.Item href="/avaqua">Ava. Qualitativa</NavDropdown.Item>
                <NavDropdown.Item href="/transferencianotas">Transferência</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Faltas" id="basic-nav-dropdown">
                <NavDropdown.Item href="/frequenciagloballancamento">Lançamento Global</NavDropdown.Item>
                <NavDropdown.Item href="/frequenciadialancamento">Lançamento Diário</NavDropdown.Item>
                <NavDropdown.Item href="/frequenciameslancamento">Lançamento Mensal</NavDropdown.Item>
                <NavDropdown.Item href="/frequenciaconsulta">Consulta</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/aulas">Aulas</Nav.Link>

              {usuario.tipo !== 'PROF' && (
                <NavDropdown title="Coordenador(a)" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/controlelancamentos">Controle de Lançamento</NavDropdown.Item>
                  <NavDropdown.Item href="/notasconsulta">Consulta de Notas</NavDropdown.Item>
                </NavDropdown>
              )}
                    
              <Nav.Link href="/arquivos">Arquivos</Nav.Link>          
              <Nav.Link href="/relatorios">Relatórios</Nav.Link>
              {parametros.bibvlink && (
                <form action={parametros.bibvlink} target="_blank" method="post" id="myForm">
                  <input name="login" type="hidden" value={usuario.cpf}/>
                  <input name="token" type="hidden" id="token"value={hash}/>                         
                  <Nav.Link onClick={submit}> Biblioteca Virtual</Nav.Link>                           
                </form>            
              )}
          </>
          )}
          <Nav.Link href="/requerimentos">Requerimentos</Nav.Link>
          <Nav.Link href="/periodos">Períodos</Nav.Link>
          <Nav.Link href="/alterarsenha">Alterar Senha</Nav.Link>
        </Nav>        
        <Form inline>
          <Button variant="outline-danger" onClick={sair}>Sair</Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  escola: state.escola,
  sistema: state.sistema,
}))(MenuFuncionario);
