import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Card, Modal, Button } from 'react-bootstrap';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';
import Pagination from '../../../componentes/Pagination';

function Conteudo({ history, usuario, parametros, escola, periodo }) {
  
  const [detalhes, setDetalhes] = useState(false);
  const [show, setShow] = useState(false);
  function closeModal() {
    setShow(false);
    setDetalhes(false);
  }

  // paginação
  const [lista, setLista] = useState([]);
  const [listaPagina, setListaPagina] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);

  function paginate(event, selectedPage) {
    event.preventDefault();
    setCurrentPage(selectedPage);
  };

  useEffect(() => {    
    const indexOfLast = currentPage * perPage;
    const indexOfFirst = indexOfLast - perPage;
    let result = [];

    if(lista.length > 0)
      result = lista.slice(indexOfFirst, indexOfLast);

    setListaPagina(result);

  }, [lista, currentPage]);
  // paginação

  useEffect(() => {
    async function load() {
      const result = await api.get('/parametros', {
        params: { codigo: escola.codigo },
      });

      const result_conteudo = await api.get('/conteudo_lista', {
        params: { 
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: usuario.turma,
          turefis: usuario.bairro_respon
        },
      });

      if (result_conteudo.data)
      setLista(result_conteudo.data);

    }

    load();
  }, []);

  function VerDetalhes(item){
    
    setShow(true);
    setDetalhes(item);
  }

  return (
    <>
      <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />
      <Card className="card-main">
        <Card.Header className="text-center h3">Conteúdo</Card.Header>
        <Card.Body>          
          <table width="100%" className="table table-bordered">
            <thead>
              <tr>
                <th className="text-center">Data</th>
                <th className="text-center">Disciplina</th>                
                <th className="text-center">Assunto</th>                
                <th className="text-center"></th>                
              </tr>
            </thead>
            <tbody>
            {listaPagina.length > 0 && listaPagina.map(item => 
              <tr key={item.id}>
                <td>{item.dataf}</td>
                <td>{item.desdis}</td>
                <td>{item.assunto}</td>
                <td className='text-center p-1'>
                  {item?.url.length > 0 && (
                    <a href={item.url} target="_blank" style={{marginRight: 6}}>
                      <i className="fa fa-download fa-lg" aria-hidden="true"></i>
                    </a>
                  )}
                  {item?.obs.length > 0 && (
                    <a href="" onClick={(e) => {e.preventDefault(); VerDetalhes(item);}}>
                      <i className="fa fa-search-plus fa-lg" aria-hidden="true"></i>
                    </a>
                  )}
                </td>
              </tr>  
            )}          
            {listaPagina.length === 0 && (
              <tr>
                <td colSpan={4}>Nenhum registro encontrado!</td>
              </tr>
            )}      
            </tbody>                  
          </table>
          <Pagination perPage={perPage} total={lista.length} paginate={paginate}/>
        </Card.Body>
      </Card>

      <Modal show={show} dialogClassName="modal-aviso" onHide={closeModal}>
        <Modal.Header >
          <Modal.Title>{detalhes.desdis}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {detalhes && (
          <p style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: detalhes.obs.replace(/\n/g , "<br \>")}}>            
          </p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>Fechar</Button>
        </Modal.Footer>
      </Modal>
      
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros
}))(Conteudo)
