import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import $, { data } from 'jquery';
import './style.css';
import { useAlert } from 'react-alert';
import ReactDatePicker from 'react-datepicker';
import { format, isAfter, startOfDay, subDays } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import {BsImage, BsTrash} from 'react-icons/bs';
import {FaSearchPlus} from 'react-icons/fa'
import {GrDocumentPdf} from 'react-icons/gr'
import Confirm from '../../../componentes/Confirm';

function ConsultaMensagemInapimplentes({history}) {
  const {codigo, escola, ano, seqano} = useParams();
  
  const [mensagens, setMensagens] = useState([]); // alterar para true
  const [registro, setRegistro] = useState(null); // alterar para true
  const [registroDel, setRegistroDel] = useState(null); // alterar para true

  const [date, setDate] = useState(startOfDay(new Date()));
  const [date2, setDate2] = useState(startOfDay(new Date()));
  const [datePick, setDatePick] = useState(startOfDay(new Date()));
  const [datePick2, setDatePick2] = useState(startOfDay(new Date()));

  const alert = useAlert();

  document.title = 'Consulta Mensagens Inadimplentes';
  
  async function iniLoad(){
    try{

      const result = await api.get('/notificacoes/list', {
        params: {
          codigo: codigo,
          data: date.toISOString().slice(0, 10),
          ano,
          seqano
        },
      });
      console.log(result.data);
      setMensagens(result.data);
    }catch(error)
    {
      console.log(error)
    }
  }

  useEffect(() => {
    iniLoad();
  }, []);
    
  // eslint-disable-next-line
  const [dataPick, setDataPick] = useState(startOfDay(date));

  const CustomDatePciker = React.forwardRef((props, ref) => (
      <button type="button" className="form-control form-control-sm" onClick={props.onClick} >
          {props.value}
      </button>
  ));  

  function changeDate(e){
    setDatePick(e);
    setDate(e);
  }

  useEffect(() => {
    if (registro)
      $('#detalhes').modal('show')
    else
      $('#detalhes').modal('hide')
  }, [registro])
    
  const setDelete = (del) => {
    const update = mensagens.map(x => {
      if (x.id == del.id)
        x.deletar = 'S'

      return x;
    })

    setMensagens(update);
  }

  const setDeleteTodos = (e) => {
    const update = mensagens.map(x => {
      x.deletar = e.target.checked ? 'S' : 'N';
      return x;
    })

    setMensagens(update);
  }

  const remove = async (e) => {
    e.preventDefault();
    const _lista = mensagens.filter(item => { return item?.deletar === 'S' });

    if (_lista.length > 0)
    {
      const restDel = await api.post('/notificacoes/delete/ids', {
        codigo: codigo,
        matric: '9999999',
        lista: _lista,
      });
      alert.success('Registros excluídos com sucesso!')
      iniLoad();
    }
  }

  async function consulta(e) {    
    e.preventDefault();

    const result = await api.get('/notificacoes/list', {
      params: {
        codigo: codigo,
        data: date.toISOString().slice(0, 10),
        ano,
        seqano
      },
    });
    console.log(result.data);
    setMensagens(result.data);
  }

  return (
    <div className="card" style={{borderRadius: 0,}}>
      <div className="card-body" style={{padding: 0,}}>
        <form className='form-mensagem'>
          <div className="form-row">
           
            <div className="form-group col-2">
              <label >Data Envio</label>
              <ReactDatePicker
                name="data"
                dateFormat="dd/MM/yyyy"
                selected={datePick}
                onChange={changeDate}
                popperPlacement="right-start"
                customInput={<CustomDatePciker />}
                required
                locale={pt} />             
            </div>
            <div className="form-group col-4">
              <button className="btn btn-info btn-sm" style={{marginTop: 14, padding: '0.3em'}} onClick={consulta}>
                Consultar
              </button>
            </div>
          </div>
          
          <div style={{height: 326, maxHeight: 326, overflowY: 'scroll', border: 'solid 1px', borderColor: 'lightgrey'}}>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Aluno</th>
                  <th>Mensagem</th>
                  <th className='text-center' style={{width: 110}}>Data/Hora Envio</th>
                  <th style={{width: 60, padding: 10, textAlign: 'center'}}>
                    Marcar Todos
                    <div className="form-check">
                      <input className="form-check-input position-static" onClick={setDeleteTodos} type="checkbox" />
                    </div>
                  </th>
                </tr>
              </thead>
              {mensagens?.length > 0 ? (
                <tbody>
                  {mensagens?.map((i, index) => 
                    <tr key={index}>
                      <td>{i.nome}</td>
                      <td><div style={{
                        maxWidth: 300,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>{i.mensagem}</div></td>                      
                      <td style={{width: 110,}}>{i.dataf + ' ' + i.hora}</td>
                      <td style={{width: 60}}>
                        <div style={{textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'space-around', borderTop: 0}}>
                          <div className="form-check">
                            <input className="form-check-input position-static" type="checkbox" onChange={() => setDelete(i)} checked={i.deletar == 'S'} />
                          </div>
                          {/* <FaSearchPlus color='blue' size={20} style={{cursor: 'pointer'}} onClick={() => setRegistro(i)}/> */}
                        </div>
                      </td>
                    </tr>
                  )}                
                </tbody>
              ):(
                <tbody>
                  <tr>
                    <td colSpan={5} className="text-center p-5">Nenhum registro encontrado</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className="form-row" style={{paddingTop: 6, paddingBottom: 6, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: 36}}>
            <button className="btn btn-outline-danger btn-sm" onClick={remove}>              
              Remover itens selecionados <BsTrash size={20} style={{cursor: 'pointer'}} />
            </button>
          </div>

          <div className="form-row" style={{paddingTop: 5}}>
            <div className="form-group col-12" style={{display: 'flex', justifyContent: 'end'}}>                            
              <button className="btn btn-danger btn-sm" onClick={() => window.close()}>
                Fechar
              </button>
            </div>
          </div>
        </form>

        <div className="modal fade" id="detalhes" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{margin: 0, maxWidth: '100vw', width: '100vw', maxHeight: '100vh', height: '100vh'}}>
            <div className="modal-content" style={{margin: 0, maxWidth: '100vw', width: '100vw', maxHeight: '100vh', height: '100vh'}}>              
              <div className="modal-body">
                <div style={{padding: 4, textAlign: 'center'}}>
                    {registro?.file_uri.toUpperCase().indexOf('.PDF') > -1 ? (
                      <iframe src={`${registro?.file_uri}#toolbar=0`} width="460" height="300"></iframe>
                    ):(
                      <img src={registro?.file_uri} className="img-fluid"  alt={registro?.file_uri} style={{maxHeight: 300, maxWidth: 300}}/>
                    )}
                  </div>
                  
                  <p style={{padding: 4}}>{registro?.texto}</p>
                </div>
              <div className="modal-footer" style={{padding: 6,}}>
                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal" onClick={()=> setRegistro(null)}>Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <Confirm texto={'Deseja exlcuir o registro?'} funcao={remove} />
      </div>
    </div>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(ConsultaMensagemInapimplentes)