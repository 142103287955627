import React, { useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
var md5 = require('md5');

function MenuAluno({ history, usuario, parametros, escola, sistema}) {
  const hash = md5(`${usuario.cpf_resp}${parametros.bibvtoken}` )
  

  function submit() {
    const form = document.getElementById("myForm");
    form.submit();    
  }

  
  function sair() {    
    localStorage.removeItem('maisescola.usuario');

    history.push('/' + escola.cnpj);
  }

  useEffect(() => {
    console.log(usuario);
  },[])

  return (
    <Navbar bg="light" expand="lg" >
      <Navbar.Brand href="#home" style={{fontSize: 30,}}>MaisEscola <small style={{fontSize: 10}}>({sistema.versao})</small></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/homealuno"> Home</Nav.Link>
          <NavDropdown title="Notas" id="basic-nav-dropdown">
            {parametros.m_bol == 'S' && (
              <NavDropdown.Item href="/boletim"> Boletim</NavDropdown.Item>
            )}
            {parametros.m_ia && (
              <NavDropdown.Item href="/instrumentos"> Instrumentos</NavDropdown.Item>
            )}
          
          {usuario.modava !== "" && (<NavDropdown.Item href="/avaliacaoqualitativa"> Avaliação Qualitativa</NavDropdown.Item>)}
          
          </NavDropdown>
          {parametros.m_fal === 'S' && (
            <Nav.Link href="/faltasmes"> Faltas</Nav.Link>
          )}
          {/* <Nav.Link href="/frequencia"> Frequência</Nav.Link> */}
          {parametros.m_fin === 'S' && (
            (usuario.matric != '2000028' && usuario.codigo != '20035') ? (
              <></>
            ) : (
<Nav.Link href="/fichafinanceira"> 2ª Via de Boletos</Nav.Link>
              )
          )}

          {parametros.m_age == 'S' && (
            <Nav.Link href="/conteudo"> Conteúdo</Nav.Link>
          )}

          {( parametros.linkremat && escola.codigo === '20036' && usuario.sit == "MATRICULADO" && (usuario.turma === 'F1AM1' || usuario.turma === 'F1AM2' || usuario.turma === 'F1AM3' || usuario.turma === 'F1AT1')) ? (
            <Nav.Link href={`http://maisescolaweb.com.br/matriculacemp/Default.aspx?matric=${usuario.matric.substring(0,2)}9${usuario.matric.substring(3)}`} target="_blank"> Rematrícula</Nav.Link>
          ):(
            parametros.linkremat && (
              <Nav.Link href={parametros.linkremat ? `${parametros.linkremat}${usuario.matric}` : ''} target="_blank"> Rematrícula</Nav.Link>
            )
          )}
            {/* {(escola.codigo !== '20036' && parametros.linkremat) && (
              <Nav.Link href={parametros.linkremat ? `${parametros.linkremat}${usuario.matric}` : ''} target="_blank"> Rematrícula</Nav.Link>
            )} */}

          <Nav.Link href="/arquivosaluno"> Arquivos</Nav.Link>
          
          {parametros.m_bib === 'S' && (
          <NavDropdown title="Biblioteca" id="basic-nav-dropdown">
            <NavDropdown.Item href="/biblioteca"> Consulta Acervo</NavDropdown.Item>
            <NavDropdown.Item href="/minhasreservas"> Minhas Reservas</NavDropdown.Item>
            <NavDropdown.Item href="/emprestimos"> Renovação</NavDropdown.Item>
          </NavDropdown>
          )}

          {parametros.ead != '' && (
            <Nav.Link href={parametros.ead} target="_blank"> Plataforma Online</Nav.Link>
          )}
          {parametros.bibvlink && (
            <form action={parametros.bibvlink} target="_blank" method="post" id="myForm">
              <input name="login" type="hidden" value={usuario.cpf_resp}/>
              <input name="token" type="hidden" id="token"value={hash}/>                         
              <Nav.Link onClick={submit}> Biblioteca Virtual</Nav.Link>                           
            </form>            
          )}

          {parametros.m_req == 'S' && (
            <Nav.Link href="/consultarequerimento"> Requerimentos</Nav.Link>
          )}
          <Nav.Link href="/periodos"> Período</Nav.Link>
        </Nav>
        <Form inline>
          <Button variant="outline-danger" onClick={sair}>Sair</Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  escola: state.escola,
  sistema: state.sistema,
}))(MenuAluno);
