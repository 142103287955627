import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';
import { TableMobile } from './styles';

import Footer from '../../../componentes/Footer/index';
import MenuAluno from '../../../componentes/MenuAluno';
import api from '../../../services/api';

function FichaFinanceira({ history, usuario, parametros, escola, periodo, sistema }) {

  const [ficha, setFicha] = useState(null);

  useEffect(() => {
    if (usuario.matric == '2000028' && usuario.codigo == '20035')
    {
      history.push('/homealuno');
    }

    async function load() {

      const result_ficha = await api.get('/fichafinanceira', {
        params: { 
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          matric: usuario.matric
        },
      });

      if (result_ficha.data)
        setFicha(result_ficha.data);
        atualizaParcelas(result_ficha.data);

    }

    if (localStorage.getItem('alunodebito') && parametros.bloaludeb)
    {
      
    }
    else{
      load();
    }

  }, []);


  const atualizaParcelas = (ficha) => {
  
    if (ficha && usuario.codigo == '20035') // CEMP
    {
      const parcelasCEMP = ['19', '20', '21'];
      console.log('ficha', ficha);
      const mat_par = ficha.filter(i => parcelasCEMP.includes(i.parcel) && i.datpag != null)
      if (mat_par.length > 0)
      {
        const novaListaParcelas = ficha.filter(i => i.parcel != '1')
        setFicha(novaListaParcelas);
      }
    }else if (ficha && usuario.codigo == '20036') // PETELECO
    {
      const parcelasPETELECO = ['24', '25', '26'];
      const mat_par = ficha.filter(i => parcelasPETELECO.includes(i.parcel) && i.datpag != null)
      if (mat_par.length > 0)
      {
        const novaListaParcelas = ficha.filter(i => i.parcel != '1')
        setFicha(novaListaParcelas);
      }
    }
  }

  return (
    <>
      <MenuAluno history={history} usuario={usuario} parametros={parametros} escola={escola} periodo={periodo} />
      <Card className="card-main">
      {(localStorage.getItem('alunodebito') && parametros.bloaludeb) ? (
          <Card.Body>
            <div className='alert alert-danger'>
              <h4>Atenção!</h4>
              <p>
              Entre em contato com a secretaria da Instituição.
              </p>
            </div>
          </Card.Body>
        ) : (
          <>
            <Card.Header className="text-center h3">2ª via de Boletos</Card.Header>
            <Card.Body> 
            {sistema.isMobile ? (
              <TableMobile width="100%" className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th className="text-center">Par</th>
                    <th className="text-center">Vcto</th>
                    <th className="text-center">Valor</th>
                    {/* <th className="text-center hidden">Desc.</th> */}
                    {/* <th className="text-center hidden">Dedução</th> */}
                    <th className="text-center">Valor Adiant.</th>
                    <th className="text-center">Data Adiant.</th>
                    <th className="text-center">Data Pagto</th>
                    <th className="text-center">Valor Pago</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                {ficha && ficha.map(item => 
                  <tr key={item.id}>
                    <td>{item.parcel}</td>
                    <td>{item.datven}</td>
                    <td>{item.valmen}</td>
                    {/* <td>R$ {item.valdes}</td> */}
                    {/* <td>R$ {item.deducao}</td> */}
                    <td>
                      {item.datadi && (
                        <span >{item.datadi}</span>
                      )}
                    </td>
                    <td>
                      {item.datadi && (
                        <span >{item.valadi}</span>
                      )}
                    </td>

                    <td>
                      {item.datpag && (
                        <span >{item.datpag}</span>
                      )}
                    </td>
                    <td>
                      {item.datpag && (
                      <span>{item.valpag}</span>
                      )}
                    </td>              
                    <td>
                      {(item.codbco !== '9000' && !item.datpag) && (
                        <a target="_blank" href={`https://pathinformatica.com.br/boletoapp/?escola=${item.escola}&ano=${item.ano}&seqano=${item.seqano}&matric=${item.matric}&parcel=${item.parcel}&codigo=${escola.codigo}&codbco=${item.codbco}&print=1`}>
                          <i className="fa fa-print fa-lg" aria-hidden="true"></i>
                        </a>
                      )}
                    </td>
                    
                    {(item.datpag && item.totaldias > 0) && (
                      <td>
                        <i className="fa fa-dollar-sign fa-lg atrasado" aria-hidden="true"></i>
                      </td>
                    )}
                    
                    {(item.datpag && item.totaldias > 0) && (
                      <td>
                        <i className="fa fa-dollar-sign fa-lg pago" aria-hidden="true"></i>
                      </td>
                    )}

                  </tr>  
                )}          
                {!ficha && (
                  <tr>
                    <td colSpan={4}>Nenhum registro encontrado!</td>
                  </tr>
                )}      
                </tbody>                  
              </TableMobile>
            ) : (
              <table width="100%" className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th className="text-center">Par</th>
                    <th className="text-center">Vcto</th>
                    <th className="text-center">Valor</th>
                    {/* <th className="text-center hidden">Desc.</th> */}
                    {/* <th className="text-center hidden">Dedução</th> */}
                    <th className="text-center">Valor Adi.</th>
                    <th className="text-center hidden">Data Adi.</th>
                    <th className="text-center">Dat. Pag.</th>
                    <th className="text-center">Val. Pag.</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                {ficha && ficha.map(item => 
                  <tr key={item.id}>
                    <td>{item.parcel}</td>
                    <td>{item.datven}</td>
                    <td>{item.valmen}</td>
                    {/* <td>R$ {item.valdes}</td> */}
                    {/* <td>R$ {item.deducao}</td> */}
                    <td>
                      {item.datadi && (
                        <span >{item.datadi}</span>
                      )}
                    </td>
                    <td>
                      {item.datadi && (
                        <span >{item.valadi}</span>
                      )}
                    </td>

                    <td>
                      {item.datpag && (
                        <span >{item.datpag}</span>
                      )}
                    </td>
                    <td>
                      {item.datpag && (
                      <span>{item.valpag}</span>
                      )}
                    </td>              
                    <td>
                      {(item.codbco !== '9000' && !item.datpag) && (
                        <a target="_blank" href={`https://pathinformatica.com.br/boletoapp/?escola=${item.escola}&ano=${item.ano}&seqano=${item.seqano}&matric=${item.matric}&parcel=${item.parcel}&codigo=${escola.codigo}&codbco=${item.codbco}&print=1`}>
                          <i className="fa fa-print fa-lg" aria-hidden="true"></i>
                        </a>
                      )}
                    </td>
                    
                    {(item.datpag && item.totaldias > 0) && (
                      <td>
                        <i className="fa fa-dollar-sign fa-lg atrasado" aria-hidden="true"></i>
                      </td>
                    )}
                    
                    {(item.datpag && item.totaldias > 0) && (
                      <td>
                        <i className="fa fa-dollar-sign fa-lg pago" aria-hidden="true"></i>
                      </td>
                    )}

                  </tr>  
                )}          
                {!ficha && (
                  <tr>
                    <td colSpan={4}>Nenhum registro encontrado!</td>
                  </tr>
                )}      
                </tbody>                  
              </table>
            )}
              
            </Card.Body>
          </>
        )}
      </Card>
      <Footer />
    </>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(FichaFinanceira)
