import React, { useState, useEffect, useRef, useCallback } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import { format, startOfDay, isBefore, isAfter, endOfDay } from "date-fns";

import Menuusuario from "../../../componentes/MenuFuncinario";
import Footer from "../../../componentes/Footer/index";
import api from "../../../services/api";
import { Container, TableNota} from "./styles";

import { useAlert } from "react-alert";
import { FaVideo, FaFileSignature, FaPaperclip, FaTrash, FaInfoCircle } from "react-icons/fa";

function Aulas({ history, escola, usuario, periodo }) {
  const alert = useAlert();
  const date = startOfDay(new Date());
  const [file, setFile] = useState(null);
  const [dataIni, setDataIni] = useState(date);
  const [dataPickIni, setDataPickIni] = useState(date);
  const [dataFin, setDataFin] = useState(date);
  const [dataPickFin, setDataPickFin] = useState(date);
  const [erros, setErros] = useState(null);

  const [lista, setLista] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [turma, setTurma] = useState(null);
  const [turmaDetalhe, setTurmaDetalhe] = useState(null);
  const [disciplinas, setDisciplinas] = useState([]);
  const [disciplina, setDisciplina] = useState(null);
  const [materias, setMaterias] = useState([]);
  const [materia, setMateria] = useState(null);
  const [files, setFiles] = useState([]);
  const [registro, setRegistro] = useState(null);

  const [dados, setDados] = useState({
    turma: "",
    disciplina: "",
    materia: "",
    dataini: format(dataIni, "yyyy-MM-dd"),
    datafin: format(dataFin, "yyyy-MM-dd"),
    tipo: "",
    file: null,
    titulo: "",
    detalhes: "",
  });

  // eslint-disable-next-line
  const [dataPick, setDataPick] = useState(startOfDay(date));

  const CustomDatePciker = React.forwardRef((props, ref) => (
    <button type="button" className="form-control" onClick={props.onClick}>
      {props.value}
    </button>
  ));

  // carrega turmas
  useEffect(() => {
    async function load() {
      let url = "/grade/turmas/diario";

      if (usuario.tipo === "ADMIN") url = "/grade/turmas/diario/admin";

      if (usuario.tipo === "COORD") url = "/grade/turmas/diario/coord";

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });
      setTurmas(result.data);

      const lista_ = await api.get("/aulas", {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          dataini: dados.dataini,
          datafin: dados.datafin,
        },
      });
      setLista(lista_.data);
    }

    load();

    

  }, [usuario, escola, periodo]);

  // carrega disciplinas
  useEffect(() => {
    async function load() {
      let url = "/grade/disciplinas/diario";

      if (usuario.tipo === "ADMIN") url = "/grade/disciplinas/diario/todas";

      if (usuario.tipo === "COORD") url = "/grade/disciplinas/diario/coord";

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: turma.turma,
        },
      });

      setDisciplinas(result.data);
    }

    if (turma) load();
  }, [usuario, escola, periodo, turma]);

  // carrega lista
  async function pesquisar(e) {
    e.preventDefault();
    if (dados.dataini && dados.datafin) {
      const lista_ = await api.get("/aulas", {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          dataini: dados.dataini,
          datafin: dados.datafin,
        },
      });
      setLista(lista_.data);
    } else {
      alert.error("Informe os campos obrigatórios.");
      setLista([]);
    }
  }

  function inputChange(e) {
    if (e.target.name === "nava") setDados({ ...dados, nava: e.target.value });

    if (e.target.name === "turma") {
      const reg = turmas.find((t) => t.turma === e.target.value);
      setTurma(reg);
      setDados({ ...dados, turma: e.target.value, disciplina: "" });
      setDisciplina(null);
    }

    if (e.target.name === "disciplina")
      setDados({ ...dados, disciplina: e.target.value });

    if (e.target.name === "aulas")
      setDados({ ...dados, aulas: e.target.value });

    if (e.target.name === "tipo") setDados({ ...dados, tipo: e.target.value });
  }

  function inputChangeDataFin(e) {
    setDataPickFin(e);
    setDataFin(e);
    setDados({ ...dados, datafin: format(e, "yyyy-MM-dd") });
  }

  function inputChangeDataIni(e) {
    setDataPickIni(e);
    setDataIni(e);
    setDados({ ...dados, dataini: format(e, "yyyy-MM-dd") });
  }

  async function deletar(e, item) {
    e.preventDefault();
    const delete_ = await api.post("/aulas/delete", {
      codigo: escola.codigo,
      id: item.id,
    });

    if (dados.dataini && dados.datafin) {
      const lista_ = await api.get("/aulas", {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          turma: dados.turma,
          discip: dados.disciplina,
          dataini: dados.dataini,
          datafin: dados.datafin,
        },
      });
      setLista(lista_.data);
    } else {
      alert.error("Informe os campos obrigatórios.");
      setLista([]);
    }
  }

  const abrirNovaAba = (url) => {
    window.open(url);
  };

  async function viewConteudo(e, item) {
    e.preventDefault();
    setRegistro(item);
    const files_ = await api.get("/file/lista", {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        turma: item.turma,
        discip: item.discip,
        dataini: item.data,
        datafin: item.data,
      },
    });

    setFiles(files_.data ? files_.data : []);
  }

  return (
    <Container>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Aulas - Consulta
              </li>
            </ol>
          </nav>

          <hr />
          <form>
            <div className="form-row">
              <div className="form-group col-md-9">
                <label>Turma</label>
                <select
                  className="form-control"
                  name="turma"
                  defaultValue={dados.turma}
                  onChange={inputChange}
                >
                  <option value="">...</option>
                  {turmas.map((item) => (
                    <option key={item.turma} value={item.turma}>
                      {item.destur}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-9 ">
                <label>Disciplina</label>
                <select
                  className="form-control"
                  value={dados.disciplina}
                  name="disciplina"
                  onChange={inputChange}
                >
                  <option value="">...</option>
                  {disciplinas.map((item) => (
                    <option key={item.discip} value={item.discip}>
                      {item.discip} - {item.desdis}
                    </option>
                  ))}
                </select>
              </div>
              {materias.length > 0 && (
                <div className="form-group col-md-3">
                  <label>Matéria</label>
                  <select
                    className="form-control"
                    value={dados.materia}
                    name="materia"
                    onChange={inputChange}
                  >
                    <option value="">...</option>
                    {materias.map((item) => (
                      <option key={item.discip} value={item.discip}>
                        {item.discip} - {item.desdis}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            <div className="form-row">
              <div className="form-group col-md-3">
                <label className="required">Data Inicial</label>
                <DatePicker
                  name="data"
                  dateFormat="dd/MM/yyyy"
                  selected={dataIni}
                  onChange={inputChangeDataIni}
                  customInput={<CustomDatePciker />}
                  locale={pt}
                />
              </div>

              <div className="form-group col-md-3">
                <label className="required">Data Final</label>
                <DatePicker
                  name="data"
                  dateFormat="dd/MM/yyyy"
                  selected={dataFin}
                  onChange={inputChangeDataFin}
                  customInput={<CustomDatePciker />}
                  locale={pt}
                />
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-3">
                <button className="btn btn-dark col-md-12" onClick={pesquisar}>
                  Pesquisar
                </button>
              </div>
              <div className="form-group col-md-6"></div>
              <div className="form-group col-md-3">
                <a href="/aulascadastro" className="btn btn-success col-md-12">
                  Cadastrar Nova
                </a>
              </div>
            </div>

            <hr />

            <TableNota className="table">
              <thead className="thead-dark">
                <tr>
                  <th className="column-turma">Turma</th>
                  <th className="column-discip">Disciplina</th>
                  <th className="column-data">Data</th>
                  <th className="column-data">Horário</th>
                  <th className="column-bt"></th>
                </tr>
              </thead>
              <tbody>
                {lista ? (
                  lista.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.turma} - {item.destur}
                      </td>
                      <td>
                        {item.discip} - {item.desdis}
                      </td>
                      <td>
                        {format(new Date(item.data + "T00:00:00" ),"dd/MM/yyyy")}
                      </td>
                      <td>
                        {item.hora_inicio && (item.hora_inicio + ' às ' + item.hora_fim)}
                      </td>

                      <td className="d-flex justify-content-around">
                        {isAfter(
                          new Date(item.data + "T" + item.hora_inicio),
                          new Date()
                        ) ? (
                          <>
                            <a title="Aula (Google Meet)" href="#" onClick={() => abrirNovaAba(item.linktur)}><FaVideo /></a>
                          </>
                        ): (
                          <a title="Aula (Google Meet)" href="#" onClick={() => abrirNovaAba(item.linktur)}>
                            <FaVideo  style={{color: '#CCC', cursor: 'not-allowed'}}/>
                          </a>
                        )}                          
                          {/* <a title="Arquivos" href="#" onClick={() => abrirNovaAba(item.linktur)}><FaPaperclip /></a> */}
                          <a title="Detalhes" data-toggle="modal" data-target="#myModal" href="#" onClick={(e) => viewConteudo(e, item)}><FaInfoCircle className="ml-2"/></a>
                          <a title="Excluir" href="#" onClick={(e) => deletar(e, item)}><FaTrash className="ml-2"/></a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr key={1}>
                    <td className="linha-sem-dados" colSpan={6}>
                      Nenhuma informação
                    </td>
                  </tr>
                )}
              </tbody>
            </TableNota>
          </form>
        </Card.Body>
      </Card>

      <div className="modal fade" id="myModal" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          {registro && (
            <div className="modal-content">
              <div className="modal-body">
                <strong>{registro.turma} - {registro.destur}</strong><br />
                Aula do dia - {format(new Date(registro.data + 'T00:00:00'), 'dd/MM/yyyy')}<br /><br />

                {registro.obs && (
                  <>
                    <strong>Conteúdo</strong>
                    <p>
                      {registro.obs}
                    </p>
                  </>
                )}

                <strong>Arquivos</strong>
                <p>
                  {files.map(item => (
                    <a key={item.id} href={item.url} target="_blank">{item.titulo}</a>
                  ))}
                </p>
              </div> 
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Fechar</button>
              </div>
            </div>
          )}           
        </div>
      </div>

      <Footer />
    </Container>
  );
}

export default connect((state) => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(Aulas);
