import React from 'react';
import { connect } from 'react-redux';

import { Container, Logo } from './styles';

function FooterAdmin({ escola, sistema }) {
  return (
    <Container>
      <div className="footer-logo-admin">
        {escola && (
          <Logo src={`https://maisescola.netlify.app/logos/${escola.codigo}.png?t=${new Date().getTime()}`} alt={escola.codigo} />
        )}
        <a style={{
          padding: 3,
        }} href="https://maisescola.app/privacidade.html" target="_blank" >Políticas de Privacidade.</a>
        <small style={{
          padding: 3,
        }} >Versão: {sistema.versao}</small>
        
      </div>
    </Container>
  );
}

export default connect(state => ({
  sistema: state.sistema,
}))(FooterAdmin);
