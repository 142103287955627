import React from 'react';
import { connect } from 'react-redux';

import { Container, Logo } from './styles';

function Footer({ escola, sistema, usuario, periodo }) {
  return (
    <Container>
      <div className="footer-logo">
        <Logo src={`https://maisescola.netlify.app/logos/${escola.codigo}.png?t=${new Date().getTime()}`} alt={escola.codigo} />
      </div>
      {usuario && (
        <>
          <span style={{ fontSize: 16, fontWeight: 'bold' }}>{periodo.ano + ' / ' + periodo.seqano}</span>
          <span>{usuario.matric} - {usuario.nome} </span>
        </>
      )}
      <div className="text-small">
        <a href="https://maisescola.app/privacidade.html" target="_blank" >Políticas de Privacidade.</a>
      </div>
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo
}))(Footer);
