import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import api from '../../../services/api';

function LiberaBoletimTurma({ history }) {
  const {codigo, escola, ano, seqano} = useParams();

  const [turmas, setTurmas] = useState(null); // alterar para true

  async function load(){

    // carregando as turmas
    const dados = await api.get('/turma/controle_liberacao', {
      params: {
        codigo: codigo,
        escola: escola,
        ano: ano,
        seqano: seqano,          
      },
    });

    const list = dados.data?.map(i => {
      i.boletim_liberado = i.boletim == 1 ? true : false;
      i.instrumento_liberado = i.instrumento == 1 ? true : false;
      return i;
    })
    setTurmas(list);

    
  }

  useEffect(() => {
    load();
  }, []);

  const handleBoletim = async (item) => {
    item.boletim_liberado = !item.boletim_liberado;
    item.boletim = !item.boletim_liberado ? 0 : 1;
    item.codigo = codigo;
    await api.post('/boletim/liberar', item);           
    load();
  }

  const handleBoletimLiberarTodos = async (e) => {    
    e.preventDefault();
    let lista = turmas.map((item) => {
        item.boletim = true;
        return item;
    });

    await api.post('/boletim/liberar_todos', {codigo, lista});           
    load();
  }

  const handleBoletimBloquearTodos = async (e) => {    
    e.preventDefault();
    let lista = turmas.map((item) => {
        item.boletim = false;
        return item;
    });

    await api.post('/boletim/liberar_todos', {codigo, lista});           
    load();
  }

  const handleInstrumento = async (item) => {
    item.instrumento_liberado = !item.instrumento_liberado;        
    item.instrumento = !item.instrumento_liberado ? 0 : 1;
    item.codigo = codigo;
    await api.post('/boletim/liberar', item); 
    load();
  }

  const handleInstrumentoLiberarTodos = async (e) => {    
    e.preventDefault();
    let lista = turmas.map((item) => {
        item.instrumento = true;
        return item;
    });

    await api.post('/boletim/liberar_todos', {codigo, lista});           
    load();
  }

  const handleInstrumentoBloquearTodos = async(e) => {    
    e.preventDefault();
    let lista = turmas.map((item) => {
        item.instrumento = false;
        return item;
    });

    await api.post('/boletim/liberar_todos', {codigo, lista});           
    load();
  }

  return (
    <div className="card" style={{borderRadius: 0,}}>
      <div className="card-body" style={{padding: 0,}}>
        <form className='form-mensagem'>
          <div className="form-row">
            <div className="form-group col-12">
              <div style={{height: 400, maxHeight: 400, overflowY: 'scroll', border: 'solid 1px', borderColor: 'lightgrey'}}>
                <table className="table">
                  <thead>
                  <tr>
                    <th>Turma</th>
                    <th className="text-center">Boletim</th>
                    <th className="text-center">Instrumento</th>
                  </tr>
                  </thead>
                  <tbody>
                    {turmas ? (
                      turmas.map((item, index) => 
                        <tr key={item.turma}>
                            <td className="text-left">{`${item.turma} - ${item.destur}`}</td>
                            <td style={{width: 120}}>
                              <div className="switchToggle">
                                <input type="checkbox" checked={item.boletim_liberado} id={`${index}_bol`} onChange={() => handleBoletim(item)}/>
                                <label htmlFor={`${index}_bol`}>Toggle</label>
                              </div>
                            </td>
                            <td style={{width: 120}}>                                
                              <div className="switchToggle">
                                <input type="checkbox" checked={item.instrumento_liberado} id={`${index}_ins`} onChange={() => handleInstrumento(item)}/>
                                <label htmlFor={`${index}_ins`}>Toggle</label>
                              </div>
                            </td>                            
                        </tr>
                      )
                    ): (
                      <tr>
                        <td className="text-center" colSpan={2}>Nenhum registro encontrado.</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12">
              <table className="table" style={{marginBottom: 2}}>
                <tbody>
                  <tr>
                    <td></td>
                    <td className="text-center alert alert-warning pl-5 pr-5">
                      Possibilita bloquear e liberar o acesso do aluno ao Boletim/Instrumento no Aplicativo(Mobile) e na Área do Aluno(Web).<br />
                      É indicado o bloqueio quando estiver sendo feito o lançamento das notas.
                    </td>
                    <td className="text-center"></td>
                    <td style={{width: 120, textAlign: 'center'}}> 
                      <button type="button" className="btn btn-success btn-sm" style={{width: 120}} onClick={handleBoletimLiberarTodos}>
                        Liberar Todos
                      </button>
                      <button type="button" className="btn btn-danger btn-sm" style={{marginTop: 3,width: 120}} onClick={handleBoletimBloquearTodos}>
                        Bloquear Todos
                      </button>
                    </td>
                    <td style={{width: 120, textAlign: 'center'}}>
                      <button type="button" className="btn btn-success btn-sm" style={{width: 120}} onClick={handleInstrumentoLiberarTodos}>
                        Liberar Todos
                      </button>
                      <button type="button" className="btn btn-danger btn-sm" style={{marginTop: 3,width: 120}} onClick={handleInstrumentoBloquearTodos}>
                        Bloquear Todos
                      </button>
                    </td>
                    <td style={{width: 10}}></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </form>
      </div>
    </div>      
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(LiberaBoletimTurma)