import styled from 'styled-components';

export const Container = styled.div`

    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    background: #FFF!important;
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;

    .error-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        background: #FFF!important;
        transform: translate(-50%, -50%);
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 24px;
        border-radius: 6px;
    }

    .error-wrapper .title {
        font-size: 32px;
        font-weight: 700;
        color: #000;
    }

    .error-wrapper .info {
        font-size: 18px;
    }

    label {
        margin-top: 10px;
        color: #55aa29;
        margin-top: 20px;
        font-size: 28px;

    }

    input {
        height: 40px;
        font-size: 16px;
        padding: 6px;
        border: 1px solid #000;
        border-radius: 3px;
        display: block;
        width: 220px;

    }

    input::placeholder {
        color: #A9A9A9;
    }

    .home-btn, 
    .home-btn:focus, 
    .home-btn:hover, 
    .home-btn:visited {
        text-decoration: none;
        font-size: 14px;
        color: #55aa29;
        padding: 10px 47px;
        border: 1px solid #55aa29;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -o-border-radius: 3px;
        border-radius: 3px;
        display: block;
        margin: 10px 0;
        width: 220px;
        background-color: transparent;
        outline: 0;
    }

    .man-icon {
        background: url('https://www.hotstar.com/assets/b5e748831c911ca02f9c07afc11f2ae5.svg') center center no-repeat;
        display: inline-block;
        height: 120px;
        width: 138px;
        margin-bottom: 16px;
    }
`;
