import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { connect } from 'react-redux';

import Menuusuario from '../../../componentes/MenuFuncinario';
import Footer from '../../../componentes/Footer/index';

import {format, startOfDay, isBefore} from 'date-fns';

import api from '../../../services/api';
import { Container, TableNota, Botoes, Formula } from './styles';

import CurrencyInput from 'react-currency-input';

import formula from '../../../services/formula';
import { useAlert } from 'react-alert'
import $ from 'jquery';
import { FaSortNumericDown, FaSortAlphaDown } from "react-icons/fa";

function NotasLancamento({ history, sistema, escola, usuario, periodo }) {
  
  const alert = useAlert();

  const date = startOfDay(new Date());
  const [datalimite, setDataLimite] = useState('');
  const [data, setData] = useState(format(date, 'yyyy-MM-dd'));
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [avaliacao, setAvaliacao] = useState('');
  const [turmas, setTurmas] = useState([]);
  const [turmaDetalhe, setTurmaDetalhe] = useState([]);
  const [disciplinas, setDisciplinas] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [notas, setNotas] = useState([]);
  const [dados, setDados] = useState({
    nava: '',
    turma: '',
    disciplina: '',
    materia: '',
    semnotas: false,
  });
  const [isCoord] = useState(usuario?.tipo === 'COORD');

  const [turma, setTurma] = useState(null);  
  const [disciplina, setDisciplina] = useState(null);  
  const [materia, setMateria] = useState('S');  
  const [ia1, setIa1] = useState(false);
  const [ia2, setIa2] = useState(false);
  const [ia3, setIa3] = useState(false);
  const [ia4, setIa4] = useState(false);
  const [ia5, setIa5] = useState(false);
  const [ia6, setIa6] = useState(false);
  const [faltas, setFaltas] = useState(false);
  const [passouDataLimite, setPassouDataLimite] = useState(false);
  const [notaTransferida, setNotaTransferida] = useState(false);
  const [semnotas, setSemNotas] = useState(false);

  async function loadAvaliacoes(){ // avaliações

    const result = await api.get('avaliacoes/diarioweb', {
      params: {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
      },
    });
    setAvaliacoes(result.data);
  }
  // eslint-disable-next-line
  useEffect(() => {
    loadAvaliacoes()
  }, [periodo]);

  // carrega turmas
  useEffect(() => { 
    async function load(){
      let url = '/grade/turmas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/turmas/diario/admin';

      if (usuario.tipo === 'COORD')
        url = '/grade/turmas/diario/coord';

      const result = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
        },
      });      
      setTurmas(result.data);
      // setListaTurmas(result.data);
    }

    load();
  }, [usuario, escola, periodo])

  // carrega disciplinas
  useEffect(() => {     
    setTurmaDetalhe([]);
    setDisciplina(null);
    setDisciplinas([]);
    
    async function load(){
      const params = {
        codigo: escola.codigo,
        escola: periodo.escola,
        ano: periodo.ano,
        seqano: periodo.seqano,
        guerra: usuario.guerra,
        turma: turma.turma,
      };
      
      let url = '/grade/disciplinas/diario';

      if (usuario.tipo === 'ADMIN')
        url = '/grade/disciplinas/diario/todas';

      if (usuario.tipo === 'COORD')
        url = '/grade/disciplinas/diario/coord';

      const result = await api.get(url, {
        params,
      }); 
      setDisciplinas(result.data);
    }

    if (turma)
      load();

  }, [usuario, escola, periodo, turma])

  async function carregaMaterias(){
    setTurmaDetalhe([]);
    try{
      let url = '/grade/materias/admin';
      if (usuario.tipo === 'PROF')
        url = '/grade/materias';

      const resultMateria = await api.get(url, {
        params: {
          codigo: escola.codigo,
          escola: periodo.escola,
          ano: periodo.ano,
          seqano: periodo.seqano,
          guerra: usuario.guerra,
          turma: dados.turma,
          discip: dados.disciplina,
          nava: dados.nava,
        },
      }); 
      setMaterias(resultMateria.data);
      setMateria(resultMateria.data.length > 0 ? 'S' : 'N');
    }catch(err){
      alert.show(err.message);
      setMaterias([]);
    }
  }

  // carrega lista de alunos
  useEffect(() => {
    setIa1(false);
    setIa2(false);
    setIa3(false);
    setIa4(false);
    setIa5(false);
    setIa6(false);
    setFaltas(false);

    async function load() {
      try{
        await carregaMaterias();

        // SELECIONA os detalhes da ava/turma/discip (diario_grade)
        const result = await api.get('grade/turma/detalhe/diario', {
          params: {
            codigo: escola.codigo,
            escola: periodo.escola,
            ano: periodo.ano,
            seqano: periodo.seqano,
            turma: dados.turma,
            discip: dados.disciplina,
            materia: dados.materia,
            nava: dados.nava,
          },
        });
        
        
        if (result.data.datalimite){

          const datalimite =  new Date(result.data.datalimite + 'T00:00:00');
          const testeDataLimite = isBefore(datalimite, date);

          setPassouDataLimite(testeDataLimite);
          setDataLimite(format(datalimite, 'dd/MM/yyyy'));
          setTurmaDetalhe(result.data);
          
          const transferido = result.data.transferido === 'S';
          setNotaTransferida(transferido);

          // SELECIONA os alunos (diario_notas)
          const resultNotas = await api.get('/aluno/lista/diario', {
            params: {
              codigo: escola.codigo,
              escola: periodo.escola,
              ano: periodo.ano,
              seqano: periodo.seqano,
              turma: dados.turma,
              discip: dados.disciplina,
              materia: dados.materia,
              nava: dados.nava,
            },
          });

          const rest = resultNotas.data.map(item => {
            
            item.ia1 = (item.ia1 === '') ? null : item.ia1;
            item.ia2 = (item.ia2 === '') ? null : item.ia2;
            item.ia3 = (item.ia3 === '') ? null : item.ia3;
            item.ia4 = (item.ia4 === '') ? null : item.ia4;
            item.ia5 = (item.ia5 === '') ? null : item.ia5;
            item.ia6 = (item.ia6 === '') ? null : item.ia6;
            item.faltas = (item.faltas === '') ? null : item.faltas;
            return item;
          });

          setNotas(rest);
        }else{
          alert.show('Data Limite, não cadastrada.');
          setNotas([]);
          setTurmaDetalhe([]);
          setDataLimite('');
          setPassouDataLimite(false);
          setNotaTransferida(false);
        }
      }catch(err){
        alert.show(err.message);
        setNotas([]);
        setTurmaDetalhe([]);
        setDataLimite('');
        setPassouDataLimite(false);
        setNotaTransferida(false);
      }
    }


    if (dados.turma.length > 0 && dados.disciplina.length > 0 && validaMateria())
    {
      load();
    }else
    {
      setNotas([]);
      setTurmaDetalhe([]);
      setDataLimite('');
      setPassouDataLimite(false);
      setNotaTransferida(false);
    }

  }, [dados]);

  const validaMateria = () => {
    if (materias.length > 0)
      if (dados.materia.length > 0)
        return true;
      else
        return false;
    else{
      return true;
    }
  }

  function changeTurma(e) {    
    const reg = turmas.find(t => t.turma === e.target.value)    
    setMaterias([]);
    setMateria('N');
    setTurma(reg);
    setDados({...dados, turma: e.target.value});
    
  }

  function handleDisciplina(e) {   
    setMaterias([]);
    setMateria('N');
    setDados({...dados, materia: '', disciplina: e.target.value});
  }

  function handleChange(e){
    if (e.target.name === 'nava')
      setDados({...dados, nava: e.target.value});

    if (e.target.name === 'turma')
      setDados({...dados, turma: e.target.value});

    if (e.target.name === 'disciplina')
      setDados({...dados, disciplina: e.target.value, materia: null});

    if (e.target.name === 'materia')
      setDados({...dados, materia: e.target.value});

    if (e.target.name === 'semnotas')
      setSemNotas(e.target.checked);
  }

  function handleChangeNota(e){  
    if (!isNaN(e.target.value))  
    {
      const id = e.target.name;
      const ia = e.target.id;
      let value = e.target.value;   

      if (e.target.value === '')
      {
        value = null;
      }    

      const result = notas.map(item => {
        if (id === item.id)
        {

          switch (ia) {
            case 'ia1':
              item.ia1 = value;
              break;

            case 'ia2':
              item.ia2 = value;
              break;

            case 'ia3':
              item.ia3 = value;
              break;

            case 'ia4':
              item.ia4 = value;
              break;

            case 'ia5':
              item.ia5 = value;
              break;
            
            case 'ia6':
              item.ia6 = value;
              break;
            
            case 'faltas':
              item.faltas = value;
              break;
                                      
            default:
              break;
          }  
        }   
        
        return item;
      });
      
      setNotas(result);
    }
  }

  function haldeCalculaNota(e){
    const id = e.target.name;

    const result = notas.map(item => {
      if (id === item.id){    
        return formula.calculaNota(item, turmaDetalhe);
      }else{
        return item
      }
    });

    setNotas(result);
  }

  async function handleSave(){
    try
    {
      const result = await api.post('/notas', {
        codigo: escola.codigo,
        notas
      });
      
      const resultdetalhe = await api.post('/notas/detalhe', {
        codigo: escola.codigo,
        detalhes: turmaDetalhe
      });

      let dados_ = dados;
      dados_.codigo= escola.codigo;
      dados_.escola= periodo.escola;
      dados_.ano= periodo.ano;
      dados_.seqano= periodo.seqano;
      dados_.guerra= usuario.guerra;
      

      const log = await api.post('/notas/gravar/log', dados_);
      
      dados_.notas = notas;
      const logDetalhe = await api.post('/notas/log/detalhe', dados_);
      alert.success('Dados gravados com sucesso!');
    }catch(error){
      alert.error('Não foi possível gravar as notas.\nTente novamente!');
    }
  }

  const handleAvaliacao = async (e) => {
    setDados({
      ...dados, 
      nava: e.target.value,
      turma: '',
      disciplina: '',
      materia: '',
    });    
  }

  function handleKeyDown(e){
    if (e.key == 'Tab')
      return true;

    if (e.key == 'Backspace')
      return true;

    if (e.key == 'Delete')
      return true;

    if ('1234567890.'.indexOf(e.key) < 0)
      e.preventDefault();
  }

  const setOrder = (ordem) => {
    return () => {
      const result = notas.sort((a, b) => {
        if (a[ordem] < b[ordem]) return -1;
        if (a[ordem] > b[ordem]) return 1;
        return 0;
      });

      setNotas(result);
    }
  }

  return (
    <Container style={{paddingBottom: 1}}>
      <Menuusuario history={history} />
      <Card className="card-main">
        <Card.Body>
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">Lançamento de Notas</li>
              </ol>
          </nav>          
          
          <hr />          
          <form>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label>Avaliação</label>
                <select className="form-control" name="nava" defaultValue={dados.nava} onChange={handleAvaliacao}>
                  <option value="">...</option>
                  {avaliacoes.map(item => 
                    <option key={item.id} value={item.nava}>{item.ava}</option>
                  )}                
                </select>
              </div>
              <div className="form-group col-md-8">
                <label>Turma</label>
                <select className="form-control" name="turma" defaultValue={dados.turma} onChange={changeTurma} >
                  <option value="">...</option>
                  {turmas.map(item => 
                    <option key={item.turma} value={item.turma}>{item.turma} - {item.destur}</option>
                  )}                
                </select>
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group col-md-8">
                <label>Disciplina</label>
                <select className="form-control" value={dados.disciplina} name="disciplina" onChange={handleDisciplina}>
                  <option value="">...</option>
                  {disciplinas.map(item => 
                    <option key={item.discip} value={item.discip}>{item.discip} - {item.desdis}</option>
                  )}                
                </select>
              </div>
              {materias.length > 0 && (
              <div className="form-group col-md-4">
                <label>Matéria</label>
                <select className="form-control" value={dados.materia} name="materia" onChange={handleChange}>
                  <option key={'key'} value="">...</option>
                  {materias.map((item, index) => 
                    <option key={index} value={item.materia}>{item.materia}</option>
                  )}                
                </select>
              </div>
              )}              
            </div>

            {/* 
            <div className="form-row">
              <div className="form-group col-md-12">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="gridCheck" name="semnotas" onChange={handleChange} defaultValue={semnotas} />
                  <label className="form-check-label" htmlFor="gridCheck">
                    Mostrar somente alunos sem nota.
                  </label>
                </div>
              </div>
            </div> 
            */}

            <hr />
            {notaTransferida && (
              <div className="alert alert-danger" role="alert">
                Notas transferidas em <strong>({turmaDetalhe.data_ultimatransferencia && format(new Date(turmaDetalhe.data_ultimatransferencia + 'T00:00:00'), 'dd/MM/yyyy')})</strong>.
              </div>
            )}
            {passouDataLimite ? (
              <div className="alert alert-danger" role="alert">
                Prazo para lançamento das notas expirou em <strong>({datalimite})</strong>.
              </div>
            ): (
              datalimite && (
                <div className="alert alert-success" role="alert">
                  Prazo para lançamento das notas expira em <strong>({datalimite})</strong>.
                </div>
              )
            )}
            {sistema.isMobile ? (
              <TableNota className="table">
                <thead className="thead-dark">
                  <tr>
                    <th className="row">
                      <div className="col column-ia-mob">
                        <input name="cab1" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab1: e.target.value})} className="input-ia-header-mob" type="text" value={turmaDetalhe.cab1 ||''}/></div>
                      <div className="col column-ia-mob"><input name="cab2" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab2: e.target.value})} className="input-ia-header-mob" type="text" value={turmaDetalhe.cab2 ||''}/></div>
                      <div className="col column-ia-mob"><input name="cab3" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab3: e.target.value})} className="input-ia-header-mob" type="text" value={turmaDetalhe.cab3 ||''}/></div>
                      <div className="col column-ia-mob"><input name="cab4" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab4: e.target.value})} className="input-ia-header-mob" type="text" value={turmaDetalhe.cab4 ||''}/></div>
                      <div className="col column-ia-mob"><input name="cab5" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab5: e.target.value})} className="input-ia-header-mob" type="text" value={turmaDetalhe.cab5 ||''}/></div>
                      <div className="col column-ia-mob"><input name="cab6" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab6: e.target.value})} className="input-ia-header-mob" type="text" value={turmaDetalhe.cab6 ||''}/></div>
                      <div className="col column-nota-mob">Nota</div>
                      {turma && (turma.tiplan <= 1) && (
                        <div className="col column-faltas-mob">Faltas</div>
                      )}
                    </th>
                  </tr>
                </thead>               
                <tbody>
                  {notas.map((nota, index) => 
                    notas.length > 0 && (
                      <tr className="onfocus" key={index}>
                        <td >
                          <div className="row">
                            <div className="col text-center">
                              {nota.nome}
                            </div>
                          </div>
                          <div key={nota.id + 'x'} className="row">
                            <div className="col column-ia-mob">
                              <input type="text" disabled={isCoord} maxLength={4} onKeyDown={handleKeyDown} onChange={handleChangeNota} onBlur={haldeCalculaNota} id={'ia1'} name={nota.id} className="input-ia formatnota" value={nota.ia1}/>
                            </div>
                            <div className="col column-ia-mob">
                              <input type="text" disabled={isCoord} maxLength={4} onKeyDown={handleKeyDown} onChange={handleChangeNota} onBlur={haldeCalculaNota} id={'ia2'} name={nota.id} className="input-ia" value={nota.ia2}/>
                            </div>
                            <div className="col column-ia-mob">
                              <input type="text" disabled={isCoord} maxLength={4} onKeyDown={handleKeyDown} onChange={handleChangeNota} onBlur={haldeCalculaNota} id={'ia3'} name={nota.id} className="input-ia" value={nota.ia3}/>
                            </div>
                            <div className="col column-ia-mob">
                              <input type="text" disabled={isCoord} maxLength={4} onKeyDown={handleKeyDown} onChange={handleChangeNota} onBlur={haldeCalculaNota} id={'ia4'} name={nota.id} className="input-ia" value={nota.ia4}/>
                            </div>
                            <div className="col column-ia-mob">
                              <input type="text" disabled={isCoord} maxLength={4} onKeyDown={handleKeyDown} onChange={handleChangeNota} onBlur={haldeCalculaNota} id={'ia5'} name={nota.id} className="input-ia" value={nota.ia5}/>
                            </div>
                            <div className="col column-ia-mob">
                              <input type="text" disabled={isCoord} maxLength={4} onKeyDown={handleKeyDown} onChange={handleChangeNota} onBlur={haldeCalculaNota} id={'ia6'} name={nota.id} className="input-ia" value={nota.ia6}/>
                            </div>
                            <div className="col column-ia-mob">
                              {/* <span className='input-ia notafinal'>{nota.nota}</span> */}
                              <input disabled={true} className="input-ia notafinal" type="text" value={nota.nota} />
                            </div>
                            {turma && (turma.tiplan <= 1) && (
                              <div className="col column-ia-mob"><input  disabled={isCoord}  id="faltas" name={nota.id} onChange={handleChangeNota} className="input-ia" type="text"/></div>
                            )}
                          </div> 
                        </td> 
                      </tr>
                    ) 
                  )}
                </tbody>
              </TableNota>
            ) : (
              <TableNota className="table">
              <thead className="thead-dark">
                <tr>
                  <th colSpan={3}></th>
                  <th className="column-ia">
                    <input type="checkbox" disabled={passouDataLimite || notaTransferida || isCoord} onChange={(e) => setIa1(e.target.checked)} checked={ia1}/>
                  </th>
                  <th className="column-ia"><input type="checkbox" disabled={passouDataLimite || notaTransferida || isCoord} onChange={(e) => setIa2(e.target.checked)} checked={ia2}/></th>
                  <th className="column-ia"><input type="checkbox" disabled={passouDataLimite || notaTransferida || isCoord} onChange={(e) => setIa3(e.target.checked)} checked={ia3}/></th>
                  <th className="column-ia"><input type="checkbox" disabled={passouDataLimite || notaTransferida || isCoord} onChange={(e) => setIa4(e.target.checked)} checked={ia4}/></th>
                  <th className="column-ia"><input type="checkbox" disabled={passouDataLimite || notaTransferida || isCoord} onChange={(e) => setIa5(e.target.checked)} checked={ia5}/></th>
                  <th className="column-ia"><input type="checkbox" disabled={passouDataLimite || notaTransferida || isCoord} onChange={(e) => setIa6(e.target.checked)} checked={ia6}/></th>
                  <th></th>
                  <th className="column-ia">
                    {turma && ((turma.tiplan <= 1) && <input disabled={passouDataLimite || isCoord} type="checkbox" onChange={(e) => setFaltas(e.target.checked)} defaultChecked={faltas}/>)}
                  </th>
                </tr>                
                <tr>
                  <th className="column-ord header-sort" onClick={setOrder('ordem')}>Ord. <FaSortNumericDown /></th>
                  <th className="column-matric header-sort">Matrícula</th>
                  <th className="column-nome header-sort" onClick={setOrder('nome')}>Nome <FaSortAlphaDown /></th>
                  <th className="column-ia"><input disabled={!ia1} name="cab1" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab1: e.target.value})} className="input-ia-header" type="text" value={turmaDetalhe.cab1 ||''}/></th>
                  <th className="column-ia"><input disabled={!ia2} name="cab2" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab2: e.target.value})} className="input-ia-header" type="text" value={turmaDetalhe.cab2 ||''}/></th>
                  <th className="column-ia"><input disabled={!ia3} name="cab3" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab3: e.target.value})} className="input-ia-header" type="text" value={turmaDetalhe.cab3 ||''}/></th>
                  <th className="column-ia"><input disabled={!ia4} name="cab4" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab4: e.target.value})} className="input-ia-header" type="text" value={turmaDetalhe.cab4 ||''}/></th>
                  <th className="column-ia"><input disabled={!ia5} name="cab5" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab5: e.target.value})} className="input-ia-header" type="text" value={turmaDetalhe.cab5 ||''}/></th>
                  <th className="column-ia"><input disabled={!ia6} name="cab6" onChange={(e) => setTurmaDetalhe({...turmaDetalhe, cab6: e.target.value})} className="input-ia-header" type="text" value={turmaDetalhe.cab6 ||''}/></th>
                  <th className="column-nota">Nota</th>
                  <th className="column-faltas">
                    {turma && (turma.tiplan <= 1) && 'Faltas'}
                  </th>
                </tr>
              </thead>
              <tbody>
                {semnotas ? (
                  notas.map(nota => (
                    nota.nota === '' && (
                      <tr key={nota.id}>
                        <td className="column-ord">{nota.ord}</td>
                        <td className="column-matric">{nota.matric}</td>
                        <td>{nota.nome}</td>
                        <td className="column-ia">
                          <CurrencyInput onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia1'} name={nota.id} className="input-ia" value={nota.ia1} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>                       
                        <td className="column-ia">
                          <CurrencyInput onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia2'} name={nota.id} className="input-ia" value={nota.ia2} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia3'} name={nota.id} className="input-ia" value={nota.ia3} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia4'} name={nota.id} className="input-ia" value={nota.ia4} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia5'} name={nota.id} className="input-ia" value={nota.ia5} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia6'} name={nota.id} className="input-ia" value={nota.ia6} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">{nota.nota}</td>
                        <td className="column-ia">{turma && (turma.tiplan <= 1 && <input onChange={handleChangeNota} disabled={!faltas} name={nota.id}  id={'faltas'} className="input-ia" type="text"/>)}</td>                    
                      </tr>           
                    )
                  ))
                ) : (
                  notas.map(nota => (
                    notas.length > 0 && (
                      <tr key={nota.id}>
                        <td className="column-ord">{nota.ordem}</td>
                        <td className="column-matric">{nota.matric}</td>
                        <td>{nota.nome}</td>
                        <td className="column-ia">
                          <CurrencyInput disabled={!ia1} onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia1'} name={nota.id} className="input-ia" value={nota.ia1} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled={!ia2} onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia2'} name={nota.id} className="input-ia" value={nota.ia2} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled={!ia3} onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia3'} name={nota.id} className="input-ia" value={nota.ia3} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled={!ia4} onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia4'} name={nota.id} className="input-ia" value={nota.ia4} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled={!ia5} onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia5'} name={nota.id} className="input-ia" value={nota.ia5} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">
                          <CurrencyInput disabled={!ia6} onChangeEvent={handleChangeNota} onBlur={haldeCalculaNota} selectAllOnFocus={true} id={'ia6'} name={nota.id} className="input-ia" value={nota.ia6} thousandSeparator="," precision="1" allowEmpty={true}/>
                        </td>
                        <td className="column-ia">{nota.nota}</td>
                        <td className="column-ia">{turma && (turma.tiplan <= 1 && <input disabled={!faltas} onChange={handleChangeNota} name={nota.id} id="faltas" value={nota.faltas} className="input-ia" type="text"/>)}</td>                    
                      </tr>     
                    )     
                  ))
                )} 

                {notas.length === 0 ? (
                  <tr key={1}>
                    <td className="linha-sem-dados" colSpan={11}>Nenhuma informação</td>
                  </tr>
                ) : (
                  <tr key={1}>
                    <td colSpan={11}><strong>{notas.length} Registros</strong></td>
                  </tr>
                )}
                
              </tbody>
              </TableNota>
            )}
          </form>
          
          <hr />

          <Botoes>    
            {turmaDetalhe.formula && (
            <div className="alert alert-info" role="alert">
              <strong>Fórmula:  </strong> {' ' + turmaDetalhe.formula}
            </div>
            )}
            
            {(notas.length > 0 && !notaTransferida && !passouDataLimite && !isCoord) && (
              <button type="button" className="btn btn-success" onClick={() => handleSave()}>Gravar</button>            
            )}
          </Botoes>
        </Card.Body>
      </Card>

      <Footer />
    </Container>
  );
}

export default connect(state => ({
  escola: state.escola,
  sistema: state.sistema,
  usuario: state.usuario,
  periodo: state.periodo,
}))(NotasLancamento);