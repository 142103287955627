import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import $, { data } from 'jquery';
import './style.css';
import { useAlert } from 'react-alert';
import ReactDatePicker from 'react-datepicker';
import { format, isAfter, startOfDay, subDays } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import {BsImage, BsTrash} from 'react-icons/bs';
import {FaSearchPlus} from 'react-icons/fa'
import {GrDocumentPdf} from 'react-icons/gr'
import Confirm from '../../../componentes/Confirm';

function MuralGerenciar({history}) {
  const [show, setShow] = useState(false); // alterar para true
  const [file, setFile] = useState(null); 
  const [mural, setMural] = useState(null);
  const [muralDelete, setMuralDelete] = useState(null);
  
  const [titulo, setTitulo] = useState(''); // alterar para true
  const [mensagens, setMensagens] = useState([]); // alterar para true
  const [mensagensFiltro, setMensagensFiltro] = useState([]); // alterar para true
  const [funcionarioSel, setFuncionarioSel] = useState(null); // alterar para true
  const [categoria, setCategoria] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [funcionarios, setFuncionarios] = useState([]); // alterar para true
  const {codigo, escola, ano, seqano} = useParams();
  const [date, setDate] = useState(startOfDay(new Date()));
  const [date2, setDate2] = useState(startOfDay(new Date()));
  const [datePick, setDatePick] = useState(startOfDay(new Date()));
  const [datePick2, setDatePick2] = useState(startOfDay(new Date()));
  const [respostasTotal, setRespostasTotal] = useState([]);
  const [lista, setLista] = useState([]);
  const [turmas, setTurmas] = useState([]);
  const [respostas, setRespostas] = useState([]);

  const alert = useAlert();

  document.title = 'Gerenciar Mural';
  
  async function iniLoad(){
    try{

      const cats = await api.get('/mural/categorias', {
        params: { codigo },
      });
      setCategorias(cats.data);
      console.log(cats.data);

      const funcionarios_ = await api.get('/funcionario/lista', {
        params: { codigo },
      });
      setFuncionarios(funcionarios_.data);

      const lista_ = await api.get('/notificacoes/mural/lista', {
        params: {
          codigo,
          date, 
          date2,
          guerra: funcionarioSel?.guerra,
          categoria,
          titulo
        },
      });
      
      setMensagens(lista_.data);
    }catch(error)
    {
      console.log(error)
    }
  }

  useEffect(() => {
    iniLoad();
  }, []);
  
  const funcionarioChange = async (e) => {
    console.log(e.target.value);
    setFuncionarioSel(funcionarios.find(f => f.matric == e.target.value));
  }

  const submit = async(e) => {
    e.preventDefault();

    if (!funcionarioSel)
    {
      alert.error('Selecione um funcionário');
      return false;
   }
    
   const result = await api.get('/notificacoes/mural/lista', {
      params: {
        codigo
      },
    });
    setMensagens(result.data || []);
  }

  // eslint-disable-next-line
  const [dataPick, setDataPick] = useState(startOfDay(date));

  const CustomDatePciker = React.forwardRef((props, ref) => (
      <button type="button" className="form-control form-control-sm" onClick={props.onClick} >
          {props.value}
      </button>
  ));  

  function changeDate(e){
    setDatePick(e);
    setDate(e);
  }
  
  function changeDate2(e){
    setDatePick2(e);
    setDate2(e);
  }

  useEffect(() => {

    async function load() {
      const respostas_ = await api.get('notificacoes/mural/respostas', {
        params: {
          codigo: codigo,
        },
      });
      const res = respostas_.data.filter(res => res.codmod == mural.modresnot);

      const resultLista = await api.get('/notificacoes/mural/resp/lista', {
        params: {
          codigo: codigo,
          id: mural.id
        },
      });

      const result = await api.get('/notificacoes/mural/detalhe', {
        params: {
          codigo: codigo,
          id: mural.id,
        },
      });

      const resultturmas = await api.get('/notificacoes/mural/detalhe/turmas', {
        params: {
          codigo: codigo,
          id: mural.id,
        },
      });

      setTurmas(resultturmas.data);
      setLista(result.data);
      setRespostasTotal(resultLista.data);
      setRespostas([{cod: '', des: 'TODOS'}, {cod: '-', des: 'NÃO RESPONDEU'}, ...res] || []);
      $('#detalhes').modal('show')
    }

    if (mural)
    {
      load();     
    }
    else
      $('#detalhes').modal('hide')
  }, [mural])
    
  const openConfirm = (del) => {
    setMuralDelete(del);
    $('#confirmModal').modal('show');

  }

  const remove = async () => {
    if (muralDelete)
    {
      const resultLista = await api.delete('/notificacoes/mural', {
        params: {
          codigo: codigo,
          id: muralDelete.id
        },
      });
      alert.success('Registro excluído com sucesso!')
      iniLoad();
    }
  }

  async function consulta(e) {    
    e.preventDefault();

    const lista_ = await api.get('/notificacoes/mural/lista', {
      params: {
        codigo,
        date, 
        date2,
        guerra: funcionarioSel?.guerra,
        categoria,
        titulo
      },
    });
    
    setMensagens(lista_.data);

  }

  return (
    <div className="card" style={{borderRadius: 0,}}>
      <div className="card-body" style={{padding: 0,}}>
        <form className='form-mensagem' onSubmit={submit}>
          <div className="form-row">
            <div className="form-group col-5" >
              <label >Categoria</label>
              <select onChange={e => setCategoria(e.target.value)} className="form-control form-control-sm" value={categoria}>
                <option value=""></option>
                {categorias.map(x => (
                  <option value={x.codigo}>{x.descricao}</option>
                ))}
              </select>
            </div>            
            
            <div className="form-group col-5">
              <label >Funcionário</label>
              <select onChange={funcionarioChange} className="form-control form-control-sm" value={funcionarioSel ? funcionarioSel.matric : ''}>
                <option value=""></option>
                {funcionarios.map((f, index) => (
                  <option key={index} value={f.matric}>{f.guerra}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-2">
              <label >Data Inicial</label>
              <ReactDatePicker
                name="data"
                dateFormat="dd/MM/yyyy"
                selected={datePick}
                onChange={changeDate}
                popperPlacement="right-start"
                customInput={<CustomDatePciker />}
                required
                locale={pt} />             
            </div>
            <div className="form-group col-2">
              <label >Data Final</label>
              <ReactDatePicker
                name="data"
                dateFormat="dd/MM/yyyy"
                selected={datePick2}
                onChange={changeDate2}
                popperPlacement="right-start"
                customInput={<CustomDatePciker />}
                required
                locale={pt} />             
            </div>
            <div className="form-group col-6">
              <label>Título</label>
              <input className="form-control form-control-sm " value={titulo} onChange={e => setTitulo(e.target.value)} />
            </div>
            <div className="form-group col-2">
              <button className="btn btn-info btn-sm" style={{marginTop: 14, padding: '0.3em'}} onClick={consulta}>
                Consultar
              </button>
            </div>
          </div>
          
          <div style={{height: 368, maxHeight: 368, overflowY: 'scroll', border: 'solid 1px', borderColor: 'lightgrey'}}>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Ttulo</th>
                  <th className='text-center'>Arquivo</th>
                  <th className='text-center'>Data Envio</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {mensagens?.map((i, index) => 
                  <tr key={index}>
                    <td>{i.titulo}</td>
                    <td style={{width: 70, textAlign: 'center'}}>
                      {i?.imagem.toUpperCase().indexOf('.PDF') > -1 ? (
                        <GrDocumentPdf size={20} />
                      ) : i?.imagem ? (
                        <BsImage size={20} />
                      ) : ('')}
                    </td>
                    <td style={{width: 110,}}>{i.dataf + ' ' + i.hora}</td>
                    <td style={{width: 80, textAlign: 'center'}}>
                      <BsTrash color='red' size={20} style={{cursor: 'pointer', marginRight: 8,}} onClick={() => openConfirm(i)}/>
                      <FaSearchPlus color='blue' size={20} style={{cursor: 'pointer'}} onClick={() => setMural(i)}/>
                    </td>
                  </tr>
                )}                
              </tbody>
            </table>
          </div>

          <div className="form-row" style={{paddingTop: 5}}>
            <div className="form-group col-12" style={{display: 'flex', justifyContent: 'space-between'}}>              
              <button className="btn btn-primary btn-sm" onClick={() => history.replace(`/muralenviar/${codigo}/${escola}/${ano}/${seqano}`)}>
                Novo Mural
              </button>
              <button className="btn btn-danger btn-sm" onClick={() => window.close()}>
                Fechar
              </button>
            </div>
          </div>
        </form>

        <div className="modal fade" id="detalhes" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" style={{margin: 0, maxWidth: '100vw', width: '100vw', maxHeight: '100vh', height: '100vh'}}>
            <div className="modal-content" style={{margin: 0, maxWidth: '100vw', width: '100vw', maxHeight: '100vh', height: '100vh'}}>              
              <div className="modal-body">
                <div style={{fontWeight: 'bold', padding: 4, textAlign: 'center'}}>{mural?.titulo}</div>
                <div style={{padding: 4, textAlign: 'center'}}>
                  {mural?.imagem.toUpperCase().indexOf('.PDF') > -1 && (
                    <iframe src={`${mural?.imagem}#toolbar=0`} width="460" height="300"></iframe>                  
                  )}

                  {(mural?.imagem.lenght > 0 && mural?.imagem.toUpperCase().indexOf('.PDF') < 0) && (
                      <img src={mural?.imagem} className="img-fluid"  alt={mural?.titulo} style={{maxHeight: 300, maxWidth: 300}}/>
                  )}
                </div>
                
                <p style={{padding: 4}}>{mural?.mensagem}</p>

                {mural?.modresnot != '' && (
                  <div className="container">
                    <div className="row">
                      <div className="col-sm text-center p-1 b-1">
                        Respondidos: {Number(mural?.total) - Number(respostasTotal.find(tot => !tot.resposta)?.qtd || '0')} 
                      </div>
                      <div className="col-sm text-center p-1 b-1">
                        Não Respondidos: { Number(respostasTotal.find(tot => !tot.resposta)?.qtd) || 0 }
                      </div>
                      <div className="col-sm text-center p-1 b-1">
                        Total: {mural?.total}
                      </div>
                    </div>
                  </div>
                  )}
                  
                  <div className="container">
                    {turmas.map((t, index) => 
                      <ul className="list-group" key={index}>
                        <li className="list-group-item active" aria-current="true" style={{padding: 2, backgroundColor: '#696969', borderColor: '#696969'}}>{t.turma} - {t.turma_des}</li>
                        {lista.filter(a => a.turma == t.turma)?.map((a, index) => 
                          <li className="list-group-item" style={{padding: 2}} key={index}>
                            {mural?.modresnot != '' ? 
                              a.nome + ' - '
                             : 
                              a.nome
                            }

                            {a.resp ? <span style={{fontWeight: 'bold'}}>{a.resp}</span> : <span style={{color: 'red'}}>Não respondeu</span>}
                          </li>
                        )}
                      </ul>
                    )}
                  </div>
              </div>
              <div className="modal-footer" style={{padding: 2,}}>
                <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal" onClick={()=> setMural(null)}>Fechar</button>
              </div>
            </div>
          </div>
        </div>

        <Confirm texto={'Deseja excluir o registro?'} funcao={remove} />
      </div>
    </div>
  );
}

export default connect(state => ({
  usuario: state.usuario,
  sistema: state.sistema,
  escola: state.escola,
  periodo: state.periodo,
  parametros: state.parametros,
}))(MuralGerenciar)