// import produce from 'immer';

const _parametros = localStorage.getItem('maisescola.parametros');

const INITIAL_STATE = _parametros ? JSON.parse(_parametros) : null;

export default function parametros(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@PARAMETROS/SAVE':
      localStorage.setItem('maisescola.parametros', JSON.stringify(action.parametros));
      return action.parametros;
    default:
      return state;
  };
}
